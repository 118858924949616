<template>
  <v-card class="mx-auto px-6 py-8" max-width="85%" flat>
    <v-card>
      <v-card-title class="bg-amber-lighten-1">
        <h2 class="me-4 font-weight-light">
          Информация по Базе Данных
        </h2>
      </v-card-title>
      <v-card-text>
        Потребляемый объем Базы Данных PostgreSQL
      </v-card-text>
    </v-card>
    <br>
    <v-card flat class="mx-auto px-6 py-1">
      <v-container>
        <v-row>
          <v-col cols="auto">
            <v-card-text>
              <b>Объем Базы Данных:</b>
            </v-card-text>
          </v-col>
          <v-col cols="auto">
            <v-card-text>
              <b>{{ this.dbStatFullTxt }}</b>
            </v-card-text>
          </v-col>
        </v-row>
      
        <v-text-field
          v-model="search"
          label="Поиск"
          single-line
          hide-details
        ></v-text-field>
        
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Схема</th>
                <th class="text-left">Таблица</th>
                <th class="text-left">Общий Размер</th>
                <th class="text-left">Размер Данных</th>
                <th class="text-left">Размер Индексов</th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="item in paginatedItems" 
                :key="item.table"
                style="cursor: pointer;"
              >
                <td>{{ item.schema }}</td>
                <td>{{ item.table }}</td>
                <td>{{ item.total_size }}</td>
                <td>{{ item.data_size }}</td>
                <td>{{ item.indexes_size }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            @input="updatePage"
          ></v-pagination>
        </div>
      </v-container>
      
    </v-card>
  </v-card>
</template>

<script>

import store from '../store/store.js';

export default {
data() {
  return {
    dbStatItems: [],
    dbStatId: '',
    dbStatFull: [],
    dbStatFullTxt: '',
    search: '',
    page: 1,
    itemsPerPage: 25,
  }
},
computed: {
  filteredItems() {
    return this.dbStatItems.filter(item => {
      return Object.values(item).some(
        value => value.toString().toLowerCase().includes(this.search.toLowerCase())
      );
    });
  },
  pageCount() {
    return Math.ceil(this.filteredItems.length / this.itemsPerPage)
  },
  paginatedItems() {
    const start = (this.page - 1) * this.itemsPerPage
    const end = start + this.itemsPerPage
    return this.filteredItems.slice(start, end)
  },
},
methods: {
  async loadDbStatistics() {
    //console.log(this.availableTables)
    const settingdbStat = {
      setApi: store.state.CurrentApiKey,
      setProjectUUID: store.state.CronProjectUUID.trim(),
      setProjectNAME: store.state.GlobalProjectName.trim()
    };

    this.$store.dispatch('loaddbStatistics', settingdbStat)
      .then(() => {
        //console.log(this.$store.getters.dbStatistics)
        this.dbStatItems = this.$store.getters.dbStatistics;
        //console.log(this.dbStatItems)
      })
      .catch((error) => {
        console.log(error);
        console.log('No App Configuration!');
      });
  },
  async loadDbStatisticsFull() {
    //console.log(this.availableTables)
    const settingdbStat = {
      setApi: store.state.CurrentApiKey,
      setProjectUUID: store.state.CronProjectUUID.trim(),
      setProjectNAME: store.state.GlobalProjectName.trim()
    };

    this.$store.dispatch('loaddbStatisticsFull', settingdbStat)
      .then(() => {
        //console.log(this.$store.getters.dbStatistics)
        this.dbStatFull = this.$store.getters.dbStatisticsFull;
        this.dbStatFullTxt = this.dbStatFull[0].size
        //console.log(this.dbStatFull)
      })
      .catch((error) => {
        console.log(error);
        console.log('No App Configuration!');
      });
  },
  updatePage(page) {
    this.page = page
  },
},
mounted() {
  this.loadDbStatistics();
  this.loadDbStatisticsFull();
},

}
</script>