import axios from 'axios'
//import router from '../router/router.js'
import store from './store.js'
import router from '../router/router.js'
//import * as sha512 from 'js-sha512'

export default {
    state: {
        webHooks: []
    },
    mutations: {
        loadwebHooks (state, payload) {
            state.webHooks = payload
        }
    },
    actions: {
        async loadwebHooks ({commit}, { setApi, setProjectUUID }) {
            //console.log('strCronProjectUUID');
            commit('clearError')
            commit('setLoading2', true)

            await axios({
                method: 'POST',
                headers: {
                    'api_key': setApi,
                    'strCronProjectUUID': setProjectUUID,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getWebHooksFromDB',
                data: []

            })
            .then(async (response) => {
                
                //console.log(response.data)

                let webHookList = response.data;
                
                commit('loadwebHooks', webHookList)
                commit('setLoading2', false)
            })
            .catch((response) => {
                //console.log(response)
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('loadwebHooks', [])
                commit('setLoading2', false)
                commit('setError', response)
                if(err.response.status == '488')
                {
                    router.push('/')
                }
                
                
            });

        },
        async addwebHooks ({commit}, { setApi, uuid, strTableName, strApiKey, strProjectUUID, strGlobalProjectName, strComment }) {
            //console.log('strCronProjectUUID');
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)
               
                
                let dataTr = 
                    [ { 
                        "uuid":uuid.trim(), 
                        "strTableName":strTableName.trim(),
                        "strApiKey":strApiKey.trim(),
                        "strProjectUUID":strProjectUUID.trim(),
                        "strGlobalProjectName":strGlobalProjectName.trim(),
                        "strComment":strComment.trim()
                    } ]
                
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': setApi,
                        'Content-Type': 'application/json'
                    },
                    url: store.state.urlCJ + 'webHooksAddNewDB',
                    data: dataTr

                })
                .then(async (response) => {
                    //console.log(response)
                    
                    commit('setLoading2', false)
                    resolve(response);
                })
                .catch((response) => {
                    console.log(response.message)

                    commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                });
            })
        },
        async updatewebHooks ({commit}, { setApi, uuid, strApiKey, strTableName, strComment }) {
            //console.log('strCronProjectUUID');
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)
               
                
                let dataTr = 
                    [ { 
                        "uuid":uuid.trim(),
                        "strApiKey":strApiKey.trim(),
                        "strTableName":strTableName.trim(),
                        "strComment":strComment.trim()
                    } ]
                
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': setApi,
                        'Content-Type': 'application/json'
                    },
                    url: store.state.urlCJ + 'webHooksEditInDB',
                    data: dataTr

                })
                .then(async (response) => {
                    //console.log(response)
                    
                    commit('setLoading2', false)
                    resolve(response);
                })
                .catch((response) => {
                    console.log(response.message)

                    commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                });
            })
        },
        async deletewebHooks ({commit}, { setApi, uuid }) {
            //console.log('strCronProjectUUID');
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)
               
                
                let dataTr = 
                    [ { 
                        "uuid":uuid.trim()
                    } ]
                
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': setApi,
                        'Content-Type': 'application/json'
                    },
                    url: store.state.urlCJ + 'webHooksDeleteInDB',
                    data: dataTr

                })
                .then(async (response) => {
                    //console.log(response)
                    
                    commit('setLoading2', false)
                    resolve(response);
                })
                .catch((response) => {
                    console.log(response.message)

                    commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                });
            })
        },
        
    },
    
    getters: {
        webHooks (state) {
            return state.webHooks
        }
    }

    
}

