import axios from 'axios'
import date from 'date-and-time';
//import router from '../router/router.js'
import jobProjects from './jobProjects_help'
import store from './store.js'
const { Buffer } = require("buffer");
import router from '../router/router.js'

export default {
    state: {
        jobProjects: [],
        jobProjectsById: [],
        jobProjectsSheduled: [],
    },
    mutations: {
        loadjobProjects (state, payload) {
            state.jobProjects = payload
        },
        loadjobProjectsById (state, payload) {
            state.jobProjectsById = payload
        },
        loadjobProjectsSheduled (state, payload) {
            state.jobProjectsSheduled = payload
        },
    },
    actions: {
        async loadjobProjects ({commit}, { strCronProjectUUID }) {
            //console.log('strCronProjectUUID');
            commit('clearError')
            commit('setLoading2', true)

            //const dtStart = date.format(dtStart, 'YYYY-MM-DD');
            //const dtEnd = date.format(dtEnd, 'YYYY-MM-DD');
            const jobProjectsArr = []

            
            let data = []
            
            await axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.CurrentApiKey,
                    'CronProjUUID': strCronProjectUUID,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getJobProjects',
                data: data

            })
            .then(async (response) => {
                
                //console.log(response.data)

                const pgSQLData = response.data;

                Object.keys(pgSQLData).forEach(key => {
                    const s = pgSQLData[key]

                    let DDZ = new Date(s.dateStart);
                    let dtStart = '';
                    if(s.dateStart !== undefined && s.strJobName.trim().substring(0,6) == 'Заказы'){
                        dtStart = date.format(DDZ, 'YYYY-MM-DD');
                    }
                    else{
                        dtStart = '';
                    }

                    //console.log(s)
                    jobProjectsArr.push(
                        new jobProjects(
                            s.UUID.trim(), 
                            s.strSchoolName.trim(),
                            s.strJobTime.trim(),
                            s.strJobRepeat.trim(),
                            s.strJobName.trim(),
                            s.intJobActive == 1 ? "ДА" : "НЕТ",
                            s.sheetId,
                            s.spreadsheetId,
                            s.rangeAdd,
                            dtStart,
                            s.waitTime, 
                            s.schoolURL, 
                            s.schoolApiKey, 
                            s.schoolColumns, 
                            s.schoolColumnsOut, 
                            s.jobNameAgenda, 
                            s.clearScheet, 
                            s.useFilterRules, 
                            s.filterRules,
                            s.strJobUserComment,
                            s.strServiceAccount,
                            s.strProjectUUID,
                            s.intLitePro
                        ) 
                    )
                        
                })
                //console.log(jobProjectsArr)

                
                commit('loadjobProjects', jobProjectsArr)
                commit('setLoading2', false)
            })
            .catch((response) => {
                //console.log(response)
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('loadjobProjects', [])
                commit('setLoading2', false)
                commit('setError', response)
                if(err.response.status == '488')
                {
                    router.push('/')
                }
                
                
            });

        },
        

        async loadjobProjectsById ({commit}, { strProjectUUID }) {
            //console.log('1');
            commit('clearError')
            commit('setLoading2', true)

            //const dtStart = date.format(dtStart, 'YYYY-MM-DD');
            //const dtEnd = date.format(dtEnd, 'YYYY-MM-DD');
            const jobProjectsArr = []

            //console.log(strProjectUUID)
            
            let data = []
            
            await axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.CurrentApiKey,
                    'jobUUID': strProjectUUID,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getJobProjectsById',
                data: data

            })
            .then(async (response) => {
                
                //console.log(response.data)

                const pgSQLData = JSON.parse(Buffer.from(response.data, 'base64').toString('utf-8'));
                //const pgSQLData = response.data;

                //console.log(pgSQLData)

                Object.keys(pgSQLData).forEach(key => {
                    const s = pgSQLData[key]
                    //console.log(s)
                    //console.log(s.strGmailAccount)
                    jobProjectsArr.push(
                        new jobProjects(
                            s.UUID.trim(), 
                            s.strSchoolName.trim(),
                            s.strJobTime.trim(),
                            s.strJobRepeat.trim(),
                            s.strJobName.trim(),
                            s.intJobActive == 1 ? "ДА" : "НЕТ",
                            s.sheetId,
                            s.spreadsheetId,
                            s.rangeAdd,
                            s.dateStart,
                            s.waitTime,
                            s.schoolURL,
                            s.schoolApiKey,
                            s.schoolColumns,
                            s.schoolColumnsOut,
                            s.jobNameAgenda,
                            s.clearScheet,
                            s.useFilterRules,
                            s.filterRules,
                            s.strJobUserComment,
                            s.strServiceAccount,
                            s.strProjectUUID,
                            s.intLitePro,
                            s.strGmailAccount
                        ) 
                    )
                        
                })
                //console.log(jobProjectsArr)
                
                commit('loadjobProjectsById', jobProjectsArr)
                commit('setLoading2', false)
            })
            .catch((response) => {
                //console.log(response)
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('jobProjectsById', [])
                commit('setLoading2', false)
                commit('setError', response)

                if(err.response.status == '488')
                {
                    router.push('/')
                }
                
            });

        },

        async loadjobProjectsSheduled ({commit}) {
            //console.log('1');
            commit('clearError')
            commit('setLoading2', true)

            //const dtStart = date.format(dtStart, 'YYYY-MM-DD');
            //const dtEnd = date.format(dtEnd, 'YYYY-MM-DD');
            const jobProjectsArr = []

            //console.log('1')
            
            let data = []
            
            await axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.CurrentApiKey,
                    'strCronProjectUUID': store.state.CronProjectUUID.trim(),
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getJobProjectsSheduled',
                data: data

            })
            .then(async (response) => {
                
                //console.log(response.data)

                const pgSQLData = JSON.parse(Buffer.from(response.data, 'base64').toString('utf-8'));
                //const pgSQLData = response.data;

                //console.log(pgSQLData[0])

                Object.keys(pgSQLData).forEach(key => {
                    const s = pgSQLData[key]
                    //console.log(s)
                    //console.log(s.lastRunAt)
                    let DDZ = new Date(s.nextRunAt);
                    let dtStart = '';
                    if(s.lastRunAt == undefined){
                        dtStart = date.format(DDZ, 'YYYY-MM-DD HH:mm:ss');
                    }
                    else{
                        dtStart = 'Выполнена';
                    }
                    
                    //console.log(DDZ)
                    jobProjectsArr.push(
                        new jobProjects(
                            s.data.insertedId.trim(), 
                            s.data.schoolURL.trim(),
                            dtStart,
                            s.data.JobType,
                            s.name.trim()
                        ) 
                    )
                        
                })
                //console.log(jobProjectsArr)
                
                commit('loadjobProjectsSheduled', jobProjectsArr)
                commit('setLoading2', false)
            })
            .catch((response) => {
                //console.log(response)
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('jobProjectsSheduled', [])
                commit('setLoading2', false)
                commit('setError', response)

                if(err.response.status == '488')
                {
                    router.push('/')
                }
                
            });

        },
        
    },
    
    getters: {
        jobProjects (state) {
            return state.jobProjects
        },
        jobProjectsById (state) {
            return state.jobProjectsById
        },
        jobProjectsSheduled (state) {
            return state.jobProjectsSheduled
        },
    }
}