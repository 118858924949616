export default class jobProjects {
    constructor (
      UUID, 
      strSchoolName,
      strJobTime,
      strJobRepeat,
      strJobName,
      intJobActive,
      sheetId,
      spreadsheetId,
      rangeAdd,
      dateStart,
      waitTime,
      schoolURL,
      schoolApiKey,
      schoolColumns,
      schoolColumnsOut,
      jobNameAgenda,
      clearScheet,
      useFilterRules,
      filterRules,
      strJobUserComment,
      strServiceAccount,
      strProjectUUID, 
      intLitePro,
      strGmailAccount
    ) {
      this.UUID = UUID
      this.strSchoolName = strSchoolName
      this.strJobTime = strJobTime
      this.strJobRepeat = strJobRepeat
      this.strJobName = strJobName
      this.intJobActive = intJobActive
      this.sheetId = sheetId
      this.spreadsheetId = spreadsheetId
      this.rangeAdd = rangeAdd
      this.dateStart = dateStart
      this.waitTime = waitTime
      this.schoolURL = schoolURL
      this.schoolApiKey = schoolApiKey
      this.schoolColumns = schoolColumns
      this.schoolColumnsOut = schoolColumnsOut
      this.jobNameAgenda = jobNameAgenda
      this.clearScheet = clearScheet
      this.useFilterRules = useFilterRules
      this.filterRules = filterRules
      this.strJobUserComment = strJobUserComment
      this.strServiceAccount = strServiceAccount
      this.strProjectUUID = strProjectUUID
      this.intLitePro = intLitePro
      this.strGmailAccount = strGmailAccount
    }
  }