<template>
    <v-card class="mx-auto px-6 py-8" max-width="80%" flat>
        <v-card>
            <v-card-title class="bg-amber-lighten-1">
            <h2 class="me-4 font-weight-light">
                Заказы
            </h2>
            </v-card-title>
            <v-card-text>
                Экспортировать Заказы из Геткурса в Гугл таблицы.
            </v-card-text>
        </v-card>
        <br>
        <v-card flat class="mx-auto px-6 py-1">
            
                <form>
                    <v-text-field
                        v-model="uuid"
                        prepend-icon="mdi-identifier"
                        label="ID Задачи"
                        readonly
                    ></v-text-field>
                    <v-select
                        :items="jobType"
                        prepend-icon="mdi-checkbox-marked-circle-plus-outline"
                        label="Выберите тип задачи"
                        v-model="jobTypeSelect"
                    ></v-select>
                    <v-text-field
                        v-model="jobRepeatEvery"
                        prepend-icon="mdi-timer-alert-outline"
                        :error-messages="nameErrors"
                        label="Запускать через N минут"
                        type="number"
                        required
                        v-show="jobRepeatEveryDisabled"
                        @input="$v.jobRepeatEvery.$touch()"
                        @blur="$v.jobRepeatEvery.$touch()"
                    ></v-text-field>
                    
                    <p v-show="jobScheduledDisabled">Выберите Часы и минуты для регулярного запуска (Например запускать в 4,6,8,9 часов 25 минут)</p>
                    <p v-show="jobScheduledDisabled"><b>Часы для запуска (МСК):</b></p>
                    
                    <v-card class="d-flex flex-wrap" flat tile v-show="jobScheduledDisabled">
                        <!-- <v-checkbox class="px-3" v-model="ex00" label="00" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex01" label="01" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox> -->
                        <v-checkbox class="px-3" v-model="ex02" label="02" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex03" label="03" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex04" label="04" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex05" label="05" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex06" label="06" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex07" label="07" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex08" label="08" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex09" label="09" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex10" label="10" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex11" label="11" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex12" label="12" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex13" label="13" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex14" label="14" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex15" label="15" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex16" label="16" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex17" label="17" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex18" label="18" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex19" label="19" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex20" label="20" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex21" label="21" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex22" label="22" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex23" label="23" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                    </v-card>
                    <br v-show="jobScheduledDisabled">
                    <p v-show="jobScheduledDisabled"><b>Минуты для запуска:</b></p>
                    <v-card class="d-flex flex-wrap" flat tile v-show="jobScheduledDisabled">
                        <v-checkbox class="px-3" v-model="exm00" label="00" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm05" label="05" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm10" label="10" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm15" label="15" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm20" label="20" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm25" label="25" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm30" label="30" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm35" label="35" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm40" label="40" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm45" label="45" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm50" label="50" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3 pb-6" v-model="exm55" label="55" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        
                    </v-card>
                    <v-card class="mx-auto px-4 py-4" outlined>
                        <v-tabs v-model="tab">
                            <v-tab>Авторизация, Сервисный аккоунт</v-tab>
                            <v-tab>Авторизация, Google</v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-text-field
                                    v-model="fullGoogleUrl"
                                    prepend-icon="mdi-link"
                                    label="Полная ссылка на Google Таблицу"
                                    @input="parseGoogleUrl"
                                ></v-text-field>
                                
                                <v-text-field
                                    v-model="sheetId"
                                    prepend-icon="mdi-google-spreadsheet"
                                    label="Уникальный идентификатор листа Google"
                                    :error-messages="sheetIdErrors"
                                    required
                                    @input="$v.sheetId.$touch()"
                                    @blur="$v.sheetId.$touch()"
                                ></v-text-field>
                                <v-text-field
                                    v-model="spreadsheetId"
                                    prepend-icon="mdi-google-spreadsheet"
                                    label="Уникальный идентификатор книги Google"
                                    :error-messages="spreadsheetIdErrors"
                                    required
                                    @input="$v.spreadsheetId.$touch()"
                                    @blur="$v.spreadsheetId.$touch()"
                                ></v-text-field>
                                <v-text-field
                                    v-model="rangeAdd"
                                    prepend-icon="mdi-google-spreadsheet"
                                    label="Наименование листа Google для отгрузки заказов"
                                    :error-messages="rangeAddErrors"
                                    required
                                    @input="$v.rangeAdd.$touch()"
                                    @blur="$v.rangeAdd.$touch()"
                                ></v-text-field>

                                <v-select
                                    v-model="selectedServiceEmail"
                                    :items="ServiceEmailList"
                                    label="Выберите учетную запись Google для записи данных в Таблицу"
                                    prepend-icon="mdi-email"
                                >
                                    <template v-slot:append-outer>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="copyEmailToClipboard"
                                                    :disabled="!selectedServiceEmail"
                                                >
                                                    <v-icon>mdi-content-copy</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Копировать учетную запись</span>
                                        </v-tooltip>
                                    </template>
                                </v-select>
                            </v-tab-item>

                            <v-tab-item>
                                <div class="d-flex align-center">
                                    <v-btn
                                        class="primary ma-2 mt-4"
                                        @click="authenticate"
                                    >
                                        Авторизуйтесь в Google
                                    </v-btn>
                                    <p class="mt-6 ml-4">Или выберите уже авторизованный аккоунт в таблице ниже</p>
                                </div>
                                <v-simple-table>
                                    <template v-slot:default>
                                    <thead>
                                        <tr>
                                        <th class="text-left">Эл. адрес Google</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr 
                                        v-for="item in existingGoogleData" 
                                        :key="item.google_user"
                                        @click="selectGoogleUser(item.google_user)"
                                        :class="{ 'selected-row': g_mailget === item.google_user }"
                                        style="cursor: pointer;"
                                        >
                                        <td>{{ item.google_user }}</td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                                <!-- <v-btn
                                    class="primary ma-2 class=mt-4"
                                    @click="checkSpreadsheetAccess"
                                    >
                                    Check Sheet Access
                                </v-btn> -->
                                <p v-if="authStatus">{{ authStatus }}</p>
                                <br>
                                <v-btn
                                    class="primary ma-2"
                                    @click="openPicker"
                                    >
                                    Выбрать Google Таблицу
                                </v-btn>
                                <v-text-field
                                    v-model="selectedFile"
                                    prepend-icon="mdi mdi-google-spreadsheet"
                                    label="Выбранный файл в google"
                                    readonly
                                    :disabled="!selectedFile"
                                ></v-text-field>
                                <v-combobox
                                    v-model="selectedSheet"
                                    :items="sheetsList"
                                    prepend-icon="mdi mdi-google-spreadsheet"
                                    item-text="name"
                                    item-value="id"
                                    label="Выберите лист из ранее выбранной таблицы google"
                                    @change="onSheetSelect"
                                    :disabled="!selectedFile"
                                ></v-combobox>
                                <v-alert dense  text   :type="alertGoogleType"  dismissible v-model="alertGoogleAuth" class="pa-1 ma-1">
                                                {{ alertGoogleMessage }}
                                    </v-alert>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                    <v-dialog ref="dialog" v-model="modal" :return-value.sync="dateStart" persistent width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field class="mt-4" v-model="dateStart" label="Дата начала отгрузки заказов из Геткурса" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateStart" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="modal = false">
                                Отмена
                            </v-btn>
                            <v-btn text color="primary" @click="$refs.dialog.save(dateStart)">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>
                    <v-card class="mx-auto px-4 py-2 mb-4" outlined>
                        <v-card-title class="bg-amber-lighten-1">
                            <h4 class="font-weight-light">
                                Укажите задержку перед запуском в Часах и Минутах!
                            </h4>
                        </v-card-title>
                        <v-text-field
                            v-model="waitTimeH"
                            :error-messages="waitTimeHErrors"
                            prepend-icon="mdi-timer-play-outline"
                            label="Задержка в Часах перед запуском загрузки из Геткурса"
                            type="number"
                            required
                            @input="$v.waitTimeH.$touch()"
                            @blur="$v.waitTimeH.$touch()"
                            min="0"
                            max="24"
                        ></v-text-field>
                        <v-text-field
                            v-model="waitTime"
                            prepend-icon="mdi-timer-play-outline"
                            :error-messages="waitTimeErrors"
                            label="Задержка в минутах перед запуском загрузки из Геткурса"
                            type="number"
                            required
                            @input="$v.waitTime.$touch()"
                            @blur="$v.waitTime.$touch()"
                            min="0"
                            max="59"
                        ></v-text-field>
                    </v-card>
                    <v-text-field
                        v-model="schoolURL"
                        prepend-icon="mdi-web"
                        label="URL адрес школы (Например: https://yanavegana.ru)"
                        :error-messages="schoolURLErrors"
                        required
                        @input="$v.schoolURL.$touch()"
                        @blur="$v.schoolURL.$touch()"
                        :readonly = "schoolURLRD"
                    ></v-text-field>
                    <v-card flat v-show="jobScheduledDisabled">
                    <v-text-field
                        v-model="schoolApiKey"
                        prepend-icon="mdi-api"
                        label="API ключ для подключения к Геткурсу"
                        :error-messages="schoolApiKeyErrors"
                        required
                        @input="$v.schoolApiKey.$touch()"
                        @blur="$v.schoolApiKey.$touch()"
                    ></v-text-field>
                    <v-text-field
                        v-model="strJobUserComment"
                        prepend-icon="mdi-comment-edit-outline"
                        label="Комментарий пользователя"
                    ></v-text-field>
                    <v-checkbox v-model="selectParams" label="Настроить особенные параметры фильтрации Заказов" color="primary" hide-details></v-checkbox>
                    <br v-show="selectParamsDisabled" >
                    <v-btn
                        class="primary ma-2"
                        @click="showAddrule"
                        v-show="selectParamsDisabled"
                        >
                        Добавить условие
                    </v-btn>
                    <v-btn
                        class="primary ma-2"
                        @click="processDeleterule"
                        v-show="selectParamsDisabled"
                        >
                        Удалить условие
                    </v-btn>
                    <br v-show="selectParamsDisabled" >
                    <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="rules"
                        :items-per-page="10"
                        class="elevation-1"
                        v-show="selectParamsDisabled" 
                        :single-select="singleSelect"
                        item-key="Number"
                        show-select
                    ></v-data-table>
                    <br>
                    <p>Настройка информации (полей) отгружаемой из Геткурса. Выполните разовый запрос в Геткурс, для загрузки всех доступных полей. Ненужные поля просто перенесите в первую колонку!</p>
                    <!-- <v-btn
                        class="primary ma-2"
                        @click="checkGKColumns"
                        >
                        Проверить колонку
                    </v-btn>
                    <v-btn
                        class="primary ma-2"
                        @click="checkColumns"
                        >
                        Проверить итоговую колонку
                    </v-btn>
                    <br> -->
                    <v-btn
                        class="primary ma-2"
                        @click="getGKColumnsExp"
                        :disabled="getGKColumnsExpDisabled"
                        >
                        Получить "Поля" из геткурса
                    </v-btn>
                    <v-progress-circular
                    :rotate="-90"
                    :size="60"
                    :width="10"
                    :value="timerCount"
                    color="primary"
                    v-show="showTimer"
                    >
                    {{ timerCount }}
                    </v-progress-circular>
                    <v-btn
                        v-show="ShowExportID"
                        class="primary ma-2"
                        @click="getGKColumnsOnId"
                        >
                        Обновить по ИД: {{ this.ExportID }}
                    </v-btn>
                    <v-checkbox
                        v-model="dateCreateAddColumn"
                        label="Добавить поле Дата создания (Дата и Время)"
                        color="primary"
                        :value="dateCreateAddColumn"
                        hide-details
                    ></v-checkbox>
                    <v-checkbox
                        v-model="datePayAddColumn"
                        label="Добавить поле Дата оплаты (Дата и Время)"
                        color="primary"
                        :value="datePayAddColumn"
                        hide-details
                    ></v-checkbox>
                    <div>
                        <v-alert dense  text   type="error"  dismissible v-model="alert3" class="pa-1 ma-1">
                                    {{ alertMessage }}
                        </v-alert>
                    </div>
                    <p></p>
                    <div class="lists-container">
                        <div class="list">
                        <h4>Доступные поля</h4>
                        <div class="list-wrapper">
                            <v-list dense class="list-content">
                            <v-list-item v-for="item in fromGcColumns" :key="item.id" class="list-item">
                                <v-checkbox v-model="selectedFromGc" :value="item.id" class="shrink mr-2 mt-0" hide-details />
                                <v-list-item-content>
                                <v-list-item-title class="item-text">{{ item.text }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            </v-list>
                        </div>
                        </div>

                        <div class="buttons">
                            <v-btn @click="moveToSelected" class="mb-2">
                                <v-icon>mdi-arrow-right</v-icon>
                            </v-btn>
                            <v-btn @click="moveFromSelected">
                                <v-icon>mdi-arrow-left</v-icon>
                            </v-btn>
                        </div>

                        <div class="list">
                        <h4>Выбранные поля</h4>
                        <div class="list-wrapper">
                            <v-list dense class="list-content">
                            <v-list-item v-for="item in selectedColumns" :key="item.id" class="list-item">
                                <v-checkbox v-model="selectedFromSelected" :value="item.id" class="shrink mr-2 mt-0" hide-details />
                                <v-list-item-content>
                                <v-list-item-title class="item-text">{{ item.text }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            </v-list>
                        </div>
                        </div>
                    </div>
                    <br>
                    <p>Количество выбранных столбцов: {{ this.selectedColumns.length }}</p>
                    </v-card>
                    <v-card
                    class="d-flex justify-space-between mb-6"
                    flat
                    tile
                    >
                        <v-card
                            class="pa-2"
                            flat
                            v-show="showStartButton"
                        >
                            <v-btn
                                class="primary"
                                @click="submit"
                                >
                                запустить задачу
                            </v-btn>
                        </v-card>
                        <v-card
                            class="pa-2"
                            flat
                            v-show="showSaveCopyButtons"
                        >
                            <v-btn
                                class="success"
                                @click="submit"
                                >
                                сохранить задачу
                            </v-btn>
                        </v-card>
                        <v-card
                            class="pa-2"
                            flat
                            v-show="showSaveCopyButtons"
                        >
                            <v-btn
                                class="warning"
                                @click="CopyJobtoNew"
                                >
                                скопировать задачу
                            </v-btn>
                        </v-card>
                        <v-card
                            class="pa-2"
                            flat
                        >
                            <v-btn
                                class="primary"
                                @click="CancelOper"
                                >
                                Отмена
                            </v-btn>
                        </v-card>
                    </v-card>
                    
                    
                </form>
           
        </v-card>
        <br>
        <div>
            <v-alert dense  text   type="success"  dismissible v-model="alert1" class="pa-1 ma-1">
                        Задача запущена!
            </v-alert>
            <v-alert dense  text   type="error"  dismissible v-model="alert2" class="pa-1 ma-1">
                        {{ alertMessage }}
            </v-alert>
        </div>
        <v-dialog v-model="dialogAddRule" persistent  max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline" style="color:#1976d2">Добавить правило фильтрации Заказа</span>
            </v-card-title>
            <hr />
            <v-card-text>
                <br>
                
                    <v-combobox
                        v-model="selectParam"
                        :items="Param"
                        label="Параметр для фильтрации"
                        outlined
                        dense
                    ></v-combobox>
                    <v-combobox
                        v-model="selectCondition"
                        :items="Condition"
                        label="Условие для параметра"
                        outlined
                        dense
                    ></v-combobox>
                    <v-text-field
                        v-model="Value"
                        label="Значение для параметра фильтрации"
                        required
                        outlined
                        dense
                    ></v-text-field>
                    <p v-show="SpecialDisabled">Особенные условия для фильтрации Названия в заказе. Например: Название = 'Зарегистрировался на вебинар', и мы хотим что бы поиск осуществлялся в названии Заказа с 0 позиции 14 символов. Поэтому особенное условие будет равно значению 0,14. Где 0 - это позиция с которой мы будет выполнять поиск, 14 - это позиция окончания поиска.</p>
                    <v-text-field
                        v-model="Special"
                        label="Особенные условия для фильтрации"
                        outlined
                        dense
                        v-show="SpecialDisabled"
                    ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click.native="processAddrule">Сохранить</v-btn>
                <v-btn small color="primary" @click.native="close">Отмена</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
        
    </v-card>

    
    
    
  </template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid'; 
import { Buffer } from 'buffer';
import axios from 'axios'
import store from '../store/store.js'
import date from 'date-and-time';

export default {
    mixins: [validationMixin],

    validations: {
        jobRepeatEvery: { required },
        sheetId: { required },
        spreadsheetId: { required },
        rangeAdd: { required },
        waitTime: { required },
        waitTimeH: { required },
        schoolURL: { required },
        schoolApiKey: { required }
    },

    data: () => ({
        showSaveCopyButtons: true,
        showStartButton: false,
        selectParamsDisabled: false,
        rulesNumbers: 0,
        dialogAddRule: false,
        singleSelect: true,
        selected: [],
        headers: [
          { text: '№', value: 'Number', align: 'start', sortable: false },
          { text: 'Параметр', value: 'Param' },
          { text: 'Условие', value: 'Condition' },
          { text: 'Значение', value: 'Value' },
          { text: 'Спец. Условие', value: 'Special' }
        ],
        selectParams: false,
        rules: [ ],
        selectParam: '',
        Param: [
          'Стоимость',
          'Название'
        ],
        selectCondition: '',
        Condition: [
          '>',
          '=',
          'Содержит'
        ],
        Value: '',
        SpecialDisabled: false,
        Special: '',
        show: false,
        uuid: uuidv4(),
        schoolURLRD: false,
        jobRepeatEvery: '',
        jobRepeatEveryDisabled: false,
        jobScheduled: '',
        jobScheduledDisabled: false,
        jobType: ['Разовая задача', 'Регулярная задача'],
        jobTypeSelect: '',
        alert1: false,
        alert2: false,
        alert3: false,
        alertMessage: '',
        canRunJobCheck: false,
        ex00: 0,
        ex01: 0,
        ex02: 0,
        ex03: 0,
        ex04: 0,
        ex05: 0,
        ex06: 0,
        ex07: 0,
        ex08: 0,
        ex09: 0,
        ex10: 0,
        ex11: 0,
        ex12: 0,
        ex13: 0,
        ex14: 0,
        ex15: 0,
        ex16: 0,
        ex17: 0,
        ex18: 0,
        ex19: 0,
        ex20: 0,
        ex21: 0,
        ex22: 0,
        ex23: 0,
        exm00: 0,
        exm05: 0,
        exm10: 0,
        exm15: 0,
        exm20: 0,
        exm25: 0,
        exm30: 0,
        exm35: 0,
        exm40: 0,
        exm45: 0,
        exm50: 0,
        exm55: 0,
        sheetId: '0',
        spreadsheetId: '',
        rangeAdd: '',
        dateStart: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        modal: false,
        waitTime: '',
        waitTimeH: '',
        schoolURL: '',
        schoolApiKey: '',
        ExportID: '',
        ShowExportID: false,
        selectedColumns:  [],
        fromGcColumns: [],
        selectedFromGc: [], // Выбранные ID из fromGcColumns
        selectedFromSelected: [], // Выбранные ID из selectedColumns
        showTimer: false,
        timerEnabled: true,
        timerCount: 100,
        getGKColumnsExpDisabled: false,
        dateCreateAddColumn: false,
        datePayAddColumn: false,
        strJobUserComment: '',
        fullGoogleUrl: '',
        selectedServiceEmail: '',
        ServiceEmailList: [],
        authWindow: null, //Gmail Auth params
        authStatus: '',
        authCheckInterval: null,
        selectedFile: null,
        pickerApiLoaded: false,
        oauthToken: '',
        g_mailget: '',
        sheetsList: [],
        selectedSheet: null,
        tab: null,
        existingGoogleData: [ ],
        alertGoogleType: 'success',
        alertGoogleAuth: false,
        alertGoogleMessage: ''
    }),

    computed: {
      nameErrors () {
        const errors = []
        if (!this.$v.jobRepeatEvery.$dirty) return errors
            !this.$v.jobRepeatEvery.required && errors.push('Обязательное поле. Укажите количество минут через которое произведем запуск разовой задачи.')
        return errors
      },
      sheetIdErrors () {
        const errors = []
        if (!this.$v.sheetId.$dirty) return errors
            !this.$v.sheetId.required && errors.push('Обязательное поле. Укажите Уникальный идентификатор листа Google.')
        return errors
      },
      spreadsheetIdErrors () {
        const errors = []
        if (!this.$v.spreadsheetId.$dirty) return errors
            !this.$v.spreadsheetId.required && errors.push('Обязательное поле. Укажите Уникальный идентификатор книги Google.')
        return errors
      },
      rangeAddErrors () {
        const errors = []
        if (!this.$v.rangeAdd.$dirty) return errors
            !this.$v.rangeAdd.required && errors.push('Обязательное поле. Наименование листа Google для отгрузки заказов.')
        return errors
      },
      waitTimeErrors () {
        const errors = []
        if (!this.$v.waitTime.$dirty) return errors
            !this.$v.waitTime.required && errors.push('Обязательное поле. Укажите задержку в минутах перед запросом информации из Геткурса (Геткурсу требуется время для обработки вашего запроса).')
            this.waitTime > 59 && errors.push('Минуты не могут быть больше 59.')
      
        return errors
      },
      waitTimeHErrors () {
        const errors = []
        if (!this.$v.waitTimeH.$dirty) return errors
            !this.$v.waitTimeH.required && errors.push('Обязательное поле. Укажите задержку в часах перед запросом информации из Геткурса (Геткурсу требуется время для обработки вашего запроса).')
            this.waitTimeH > 23 && errors.push('Часы не могут быть больше 23.')
      
        return errors
      },
      schoolURLErrors () {
        const errors = []
        if (!this.$v.schoolURL.$dirty) return errors
            !this.$v.schoolURL.required && errors.push('Обязательное поле. URL адрес Геткурса для загрузки данных. (https://yanavegana.ru)')
        return errors
      },
      schoolApiKeyErrors () {
        const errors = []
        if (!this.$v.schoolApiKey.$dirty) return errors
            !this.$v.schoolApiKey.required && errors.push('Обязательное поле. API ключ для подключения и закрузки данных из Геткурса.')
        return errors
      },
    },

    methods: {
        selectGoogleUser(googleUser){
            this.g_mailget = googleUser;
            this.getGoogleAuthACTbyEmail();
            this.rangeAdd = '';
            this.sheetId = 0;
            this.spreadsheetId = '';
            this.selectedFile = '';
            this.selectedSheet = '';
        },
        loadGoogleAccountsByProject(){

            const settingProj = {
                setApi: store.state.CurrentApiKey,
                setProjectUUID: store.state.CronProjectUUID.trim()
            }

            this.$store.dispatch('getGoogleEmailAccountByProjUUID', settingProj)
            .then(() => {
                this.existingGoogleData = this.$store.getters.GoogleEmailAccountByProjUUID
            })
            .catch((error) => {
                console.log(error)
                console.log('No App Configuration!')               
            })

        },
        loadPickerApi() {
            const script = document.createElement('script');
            script.src = 'https://apis.google.com/js/api.js?onload=onApiLoad';
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
            window.onApiLoad = this.onApiLoad;
        },
        onApiLoad() {
            window.gapi.load('picker', { callback: this.onPickerApiLoad });
        },
        onPickerApiLoad() {
            this.pickerApiLoaded = true;
        },
        async openPicker() {
            if (!this.pickerApiLoaded) {
                this.alertGoogleType = 'error';
                this.alertGoogleAuth = true;
                this.alertGoogleMessage = 'Ошибка, не возможно выбрать файлы, убедитесь что вы авторизовались в Google'
                //console.error('Picker API не загружена');
                return;
            }

            if (!this.oauthToken) {
                this.alertGoogleType = 'error';
                this.alertGoogleAuth = true;
                this.alertGoogleMessage = 'Ошибка, не возможно выбрать файлы, убедитесь что вы авторизовались в Google'
                //console.error('Отсутствует токен OAuth');
                return;
            }

            const view = new window.google.picker.DocsView(window.google.picker.ViewId.SPREADSHEETS);
            view.setMimeTypes("application/vnd.google-apps.spreadsheet");
            view.setMode(window.google.picker.DocsViewMode.LIST);

            const settingApp = {
                setApi: store.state.CurrentApiKey,
            }

            await this.$store.dispatch('getGoogleAppSettings', settingApp)
            .then(() => {
                //console.log('OK')
                //console.log('Auth URL response:', this.$store.getters.GenerateAuthUrl[0].authURL);

                if (this.$store.getters.GoogleAppSettings.length > 0) {
                    let decryptedid = this.$store.getters.GoogleAppSettings[0].id;
                    let decryptedgApi = this.$store.getters.GoogleAppSettings[0].gApi;
                    
                    //console.log(decryptedid)
                    //console.log(decryptedgApi)
                    //console.log(this.oauthToken)

                    const picker = new window.google.picker.PickerBuilder()
                        .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
                        .setAppId(decryptedid) // Замените на ID вашего приложения
                        .setOAuthToken(this.oauthToken)
                        .addView(view)
                        .setDeveloperKey(decryptedgApi)
                        .setCallback(this.pickerCallback)
                        .disableFeature(window.google.picker.Feature.MINE_ONLY)
                        .setLocale('ru') // Устанавливаем русский язык
                        .build();
                    picker.setVisible(true);
                }
                else
                {
                    console.log('No App Configuration!')
                }
                
            })
            .catch((error) => {
                console.log(error)
                console.log('No App Configuration!')               
            })
        
        },
        pickerCallback(data) {
            if (data[window.google.picker.Response.ACTION] == window.google.picker.Action.PICKED) {
                //console.log(data[window.google.picker.Response.DOCUMENTS])
                const doc = data[window.google.picker.Response.DOCUMENTS][0];
                //console.log(doc)
                this.selectedFile = doc.name;
                this.alertGoogleType = 'success';
                this.alertGoogleAuth = true;
                this.alertGoogleMessage = 'Документ выбран! Теперь выберите лист из документа...'
                //console.log('Выбран файл:', this.selectedFile);
                this.sendFileIdToServer(doc[window.google.picker.Document.ID]);
            }
        },
        sendFileIdToServer(fileId) {
            //console.log('Отправка ID файла на сервер:', fileId);
            this.spreadsheetId = fileId
            this.getSheetsList(this.spreadsheetId);
            // Реализация отправки fileId на сервер
        },
        async getSheetsList(spreadsheetId) {

            const settingSheetsList = {
                setApi: store.state.CurrentApiKey,
                setSpreadsheetId: spreadsheetId,
                setGmail: this.g_mailget
            }

            await this.$store.dispatch('getGoogleSheetsLists', settingSheetsList)
            .then(() => {
                //console.log('OK')
                //console.log('Auth URL response:', this.$store.getters.GenerateAuthUrl[0].authURL);

                if (this.$store.getters.GoogleSheetsLists.length > 0) {
                    //console.log(this.$store.getters.GoogleSheetsLists)
                    this.sheetsList = this.$store.getters.GoogleSheetsLists;
                }
                else
                {
                    console.error('Неожиданная структура данных:', this.$store.getters.GoogleSheetsLists);
                    this.sheetsList = [];
                }
                
            })
            .catch(() => {
                console.error('Неожиданная структура данных');
                this.sheetsList = [];            
            })
        },
        onSheetSelect() {
            if (this.selectedSheet) {
                this.rangeAdd = this.selectedSheet.name;
                this.sheetId = this.selectedSheet.id.toString();
                this.alertGoogleType = 'success';
                this.alertGoogleAuth = true;
                this.alertGoogleMessage = 'Лист в документе выбран. Таблица подключена!'
            }
        },
        async checkSpreadsheetAccess(){
            console.log('Starting Check process');
                const response = await axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.CurrentApiKey,
                    'spreadsheetId': this.spreadsheetId,
                    'gmail': this.g_mailget,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'checkGoogleSheetAccess',
                    data: []
                });

                console.log('Check URL response:', response.data);
        },
        async authenticate() {
            if (this.isAuthenticating) {
                console.log('Authentication is already in progress');
            return;
            }

            //try {
            console.log('Starting authentication process');

            const settingAuthUrl = {
                setApi: store.state.CurrentApiKey,
                setTaskUUID: this.uuid,
                setProjectUUID: store.state.CronProjectUUID.trim()
            }

            await this.$store.dispatch('getGoogleGenerateAuthUrl', settingAuthUrl)
            .then(() => {
                //console.log('OK')
                //console.log('Auth URL response:', this.$store.getters.GenerateAuthUrl[0].authURL);

                if (this.$store.getters.GenerateAuthUrl.length > 0) {
                    const authUrl = this.$store.getters.GenerateAuthUrl[0].authURL;
                    
                    this.isAuthenticating = true;
                    this.authStatus = 'Authentication in progress...';
                    
                    console.log('Opening authentication window');
                    window.open(authUrl, 'Google Auth', 'width=700,height=700');
                    
                    this.startAuthStatusTracking();
                } else {
                    throw new Error('Invalid response format: authUrl not found in response');
                }
                
            })
            .catch((error) => {
                console.error('Error initiating authentication:', error);
                this.authStatus = 'Error starting authentication process';
                this.isAuthenticating = false;
               
            })
        
        },
        startAuthStatusTracking() {
            const eventSource = new EventSource(`${store.state.urlCJ}trackAuthStatus?taskUUID=${this.uuid}&api_key=${store.state.CurrentApiKey}`);

            eventSource.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log('Received auth status update');

                // Проверяем, соответствует ли полученный UUID нашему
                if (data.taskUUID === this.uuid) {
                this.authStatus = data.message;

                if (data.status === 'completed') {
                    this.handleAuthSuccess(data);
                    eventSource.close();
                } else if (data.status === 'failed') {
                    this.handleAuthFailure(data);
                    eventSource.close();
                }
                } else {
                    console.log('Received update for different task UUID, ignoring');
                }
            };

            eventSource.onerror = (error) => {
                console.error('Error in SSE connection:', error);
                this.authStatus = 'Error tracking authentication status';
                this.isAuthenticating = false;
                eventSource.close();
            };
        },

        handleAuthSuccess(data) {
            console.log('Authentication successful');
            //console.log(data)

            /* const currentDate = new Date(); // Используем текущую дату
            let decryptedACT = this.decrypt(data.act, currentDate); */
            //this.oauthToken = decryptedACT.trim()

            this.g_mailget = data.gemail.trim()
            //console.log(this.g_mailget)
            this.isAuthenticating = false;
            this.getGoogleAuthACTbyEmail();
            // Здесь можно выполнить дополнительные действия после успешной аутентификации
        },

        handleAuthFailure(data) {
            console.log('Authentication failed');
            console.log(data)
            this.isAuthenticating = false;
        },
        async getGoogleAuthACTbyEmail() {
            //try {

            const settingGoogleAuthACT = {
                setApi: store.state.CurrentApiKey,
                setGmail: this.g_mailget
            }

            await this.$store.dispatch('getGoogleAuthACTbyEmail', settingGoogleAuthACT)
            .then(() => {
                if (this.$store.getters.GoogleAuthACTbyEmail.length > 0) {
                    this.oauthToken = this.$store.getters.GoogleAuthACTbyEmail[0].oauthToken;
                    this.alertGoogleType = 'success';
                    this.alertGoogleAuth = true;
                    this.alertGoogleMessage = 'Данные аккоунта получены. Выберите Google таблицу...'
                    if(this.rangeAdd !== '' &&  this.sheetId !== ''){
                        this.alertGoogleMessage = 'Лист в документе выбран. Таблица подключена!'
                    }
                } else {
                    console.log('Authentication failed or not completed');
                }
                
            })
            .catch((error) => {
                console.error('Error checking auth status:', error);
               
            })
        },
        createScheduleString(hours, minutes) {
            let schedule = '';
            if (hours > 0) {
                schedule += `${hours} hour${hours > 1 ? 's' : ''}`;
            }
            if (minutes > 0) {
                if (schedule) schedule += ' ';
                schedule += `${minutes} minute${minutes > 1 ? 's' : ''}`;
            }
            return schedule;
        },
        parseScheduleString(scheduleString) {
            let hours = 0;
            let minutes = 0;

            // Регулярное выражение для поиска часов и минут
            const hourRegex = /(\d+)\s*hour[s]?/;
            const minuteRegex = /(\d+)\s*minute[s]?/;

            // Поиск часов
            const hourMatch = scheduleString.match(hourRegex);
            if (hourMatch) {
                hours = parseInt(hourMatch[1], 10);
            }

            // Поиск минут
            const minuteMatch = scheduleString.match(minuteRegex);
            if (minuteMatch) {
                minutes = parseInt(minuteMatch[1], 10);
            }

            return { 
                hours: isNaN(hours) ? 0 : hours, 
                minutes: isNaN(minutes) ? 0 : minutes 
            };
        },
        copyEmailToClipboard() {
            if (this.selectedServiceEmail) {
                navigator.clipboard.writeText(this.selectedServiceEmail).catch(err => {
                    console.error('Ошибка при копировании: ', err);
                });
            }
        },
        parseGoogleUrl() {
            if (this.fullGoogleUrl) {
                // Извлекаем ID таблицы
                const spreadsheetIdMatch = this.fullGoogleUrl.match(/\/d\/([a-zA-Z0-9-_]+)/);
                if (spreadsheetIdMatch && spreadsheetIdMatch[1]) {
                this.spreadsheetId = spreadsheetIdMatch[1];
                }
                
                // Извлекаем ID листа
                const sheetIdMatch = this.fullGoogleUrl.match(/[#&]gid=(\d+)/);
                if (sheetIdMatch && sheetIdMatch[1]) {
                this.sheetId = sheetIdMatch[1];
                } else {
                // Если gid не найден, устанавливаем значение по умолчанию "0"
                this.sheetId = "0";
                }
            }
        },
        moveToSelected() {
            const itemsToMove = this.fromGcColumns.filter(item => this.selectedFromGc.includes(item.id));
            this.selectedColumns = [...this.selectedColumns, ...itemsToMove];
            this.fromGcColumns = this.fromGcColumns.filter(item => !this.selectedFromGc.includes(item.id));
            this.selectedFromGc = [];
        },
        moveFromSelected() {
            const itemsToMove = this.selectedColumns.filter(item => this.selectedFromSelected.includes(item.id));
            this.fromGcColumns = [...this.fromGcColumns, ...itemsToMove];
            this.selectedColumns = this.selectedColumns.filter(item => !this.selectedFromSelected.includes(item.id));
            this.selectedFromSelected = [];
        },
        close () {
            this.dialogAddRule = false
        },
        showAddrule () {
            this.dialogAddRule = true
        },
        processAddrule () {
            this.rulesNumbers = this.rulesNumbers + 1
            this.rules.push( { Number: this.rulesNumbers, Param: this.selectParam, Condition: this.selectCondition, Value: this.Value, Special: this.Special } )
            this.dialogAddRule = false
        },
        processDeleterule () {
            //console.log(this.selected)
            let index = this.rules.indexOf(this.selected[0])
            //console.log(index)
            this.rules.splice(index, 1);
        },
        CopyJobtoNew (){
            this.showSaveCopyButtons = false;
            this.showStartButton = true;
            this.uuid = uuidv4();
            this.jobTypeSelect = 'Регулярная задача';
        },
        CancelOper () {
            this.$router.push('/mainScreen/cronJobList')
            this.$emit('close');
        },
        submit () {
            this.canRunJobCheck = true;
            let ErrorMessage = '';

            if(this.ex00 == 0 && 
                this.ex02 == 0 && 
                this.ex03 == 0 && 
                this.ex04 == 0 && 
                this.ex05 == 0 && 
                this.ex06 == 0 && 
                this.ex07 == 0 && 
                this.ex08 == 0 && 
                this.ex09 == 0 && 
                this.ex10 == 0 && 
                this.ex11 == 0 && 
                this.ex12 == 0 && 
                this.ex13 == 0 && 
                this.ex14 == 0 && 
                this.ex15 == 0 && 
                this.ex16 == 0 && 
                this.ex17 == 0 && 
                this.ex18 == 0 && 
                this.ex19 == 0 && 
                this.ex20 == 0 && 
                this.ex21 == 0 && 
                this.ex22 == 0 && 
                this.ex23 == 0
            )
            { 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не указаны часы для выполнения задачи; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.exm00 == 0 && 
                this.exm05 == 0 && 
                this.exm10 == 0 && 
                this.exm15 == 0 && 
                this.exm25 == 0 && 
                this.exm30 == 0 && 
                this.exm35 == 0 && 
                this.exm40 == 0 && 
                this.exm45 == 0 && 
                this.exm50 == 0 && 
                this.exm55 == 0
            )
            { 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не указаны минуты для выполнения задачи; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.selectedServiceEmail.trim() == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не выбран сервисный Google пользователь; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.strJobUserComment == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не введен Комментарий для задачи; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.sheetId == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не введен Уникальный идентификатор листа Google; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.spreadsheetId == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не введен Уникальный идентификатор книги Google; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.rangeAdd == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не введено Наименование листа Google для отгрузки заказов; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.dateStart == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не введена Дата начала отгрузки заказов из Геткурса; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }
            if(this.jobTypeSelect == 'Регулярная задача'){
                if(this.waitTime === ''){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Не введена Задержка в минутах перед запуском загрузки из Геткурса; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }
                if(this.waitTimeH === ''){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Не введена Задержка в часах перед запуском загрузки из Геткурса; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }

                if(this.waitTime <= 0){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Задержка в минутах перед запуском загрузки из Геткурса, не может быть меньше или равна 0; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }
            }
            else
            {
                if(this.waitTime === ''){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Не введена Задержка в минутах перед запуском загрузки из Геткурса; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }
                if(this.waitTimeH === ''){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Не введена Задержка в часах перед запуском загрузки из Геткурса; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }

                if(this.waitTime <= 0){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Задержка в минутах перед запуском загрузки из Геткурса, не может быть меньше или равна 0; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }

                if(this.jobRepeatEvery == ''){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Не введен параметр Запускать через N минут; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }

                if(this.jobRepeatEvery <= 0){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Параметр Запускать через N минут, не может быть меньше или равен 0; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }
            }
            if(this.canRunJobCheck == true){
                if(this.jobTypeSelect != '')
                {
                    let data = []
                    //console.log(data)

                    let Hours = '';
                    let Minutes = '';

                    if(this.ex00 == 1){ Hours = Hours + '0,' }
                    if(this.ex01 == 1){ Hours = Hours + '1,' }
                    if(this.ex02 == 1){ Hours = Hours + '2,' }
                    if(this.ex03 == 1){ Hours = Hours + '3,' }
                    if(this.ex04 == 1){ Hours = Hours + '4,' }
                    if(this.ex05 == 1){ Hours = Hours + '5,' }
                    if(this.ex06 == 1){ Hours = Hours + '6,' }
                    if(this.ex07 == 1){ Hours = Hours + '7,' }
                    if(this.ex08 == 1){ Hours = Hours + '8,' }
                    if(this.ex09 == 1){ Hours = Hours + '9,' }
                    if(this.ex10 == 1){ Hours = Hours + '10,' }
                    if(this.ex11 == 1){ Hours = Hours + '11,' }
                    if(this.ex12 == 1){ Hours = Hours + '12,' }
                    if(this.ex13 == 1){ Hours = Hours + '13,' }
                    if(this.ex14 == 1){ Hours = Hours + '14,' }
                    if(this.ex15 == 1){ Hours = Hours + '15,' }
                    if(this.ex16 == 1){ Hours = Hours + '16,' }
                    if(this.ex17 == 1){ Hours = Hours + '17,' }
                    if(this.ex18 == 1){ Hours = Hours + '18,' }
                    if(this.ex19 == 1){ Hours = Hours + '19,' }
                    if(this.ex20 == 1){ Hours = Hours + '20,' }
                    if(this.ex21 == 1){ Hours = Hours + '21,' }
                    if(this.ex22 == 1){ Hours = Hours + '22,' }
                    if(this.ex23 == 1){ Hours = Hours + '23,' }

                    Hours = Hours.substring(0,Hours.length-1);
                    //console.log(Hours)

                    if(this.exm00 == 1){ Minutes = '0' }
                    if(this.exm05 == 1){ Minutes = '5' }
                    if(this.exm10 == 1){ Minutes = '10' }
                    if(this.exm15 == 1){ Minutes = '15' }
                    if(this.exm20 == 1){ Minutes = '20' }
                    if(this.exm25 == 1){ Minutes = '25' }
                    if(this.exm30 == 1){ Minutes = '30' }
                    if(this.exm35 == 1){ Minutes = '35' }
                    if(this.exm40 == 1){ Minutes = '40' }
                    if(this.exm45 == 1){ Minutes = '45' }
                    if(this.exm50 == 1){ Minutes = '50' }
                    if(this.exm55 == 1){ Minutes = '55' }

                    //console.log(Minutes + ' ' + Hours + ' * * *')
                    //schedule, in 20 minutes
                    //repeatEvery, 3 minutes
                    let CronType = '';
                    let CronTime = '';
                    /* console.log(this.createScheduleString(this.waitTimeH, this.waitTime))
                    let WaitTest = this.createScheduleString(this.waitTimeH, this.waitTime)
                    let WaitTestOut = this.parseScheduleString(WaitTest)
                    console.log(WaitTestOut.hours)
                    console.log(WaitTestOut.minutes) */

                    let CronWaitTime = 'in ' + this.createScheduleString(this.waitTimeH, this.waitTime);

                    if(this.schoolURL.trim().substring(this.schoolURL.length-1) == '/')
                    {
                        this.schoolURL = this.schoolURL.trim().substring(0,this.schoolURL.length-1);
                    }

                    if(this.jobTypeSelect == 'Регулярная задача'){
                        CronType = 'repeatEvery';
                        CronTime = Minutes + ' ' + Hours + ' * * *';
                    }
                    else {
                        CronType = 'schedule';
                        CronTime = 'in ' + this.jobRepeatEvery + ' minutes';
                    }
                    //console.log(CronTime)
                    //console.log(CronType)

                    let setJobURLonButton = '';
                    if(this.showSaveCopyButtons == true){ setJobURLonButton = 'updateJobinDB' }
                    if(this.showStartButton == true){ setJobURLonButton = 'addJob' }

                    let strGmailAccount = '';
                    
                    if(this.g_mailget !== '' && this.selectedSheet.name !== '')
                    {
                        strGmailAccount = [
                            {
                                g_mailget: this.g_mailget,
                                selectedFile: this.selectedFile,
                                selectedSheet: this.selectedSheet
                            }
                        ]
                    }

                    //console.log(strGmailAccount)

                    axios({
                        method: 'POST',
                        headers: {
                            'api_key': store.state.CurrentApiKey,
                            'jobUUID': this.uuid,
                            'jobRepeatEvery': CronTime,
                            'jobType': CronType,
                            'jobName': 'GC to Google Deals Export ID',
                            'sheetId': this.sheetId,
                            'spreadsheetId': this.spreadsheetId,
                            'rangeAdd': this.rangeAdd,
                            'dateStart': this.dateStart,
                            'waitTime': CronWaitTime,
                            'schoolURL': this.schoolURL,
                            'schoolApiKey': this.schoolApiKey,
                            'strJobNameAbout': Buffer.from('Заказы из Геткурса в Гугл таблицы', 'utf-8').toString('base64'),
                            'schoolColumns': Buffer.from(JSON.stringify(this.selectedColumns), 'utf-8').toString('base64'),
                            'schoolColumnsOut': Buffer.from(JSON.stringify(this.fromGcColumns), 'utf-8').toString('base64'),
                            'useFilterRules': this.selectParams,
                            'filterRules': Buffer.from(JSON.stringify(this.rules), 'utf-8').toString('base64'),
                            'strJobUserComment': Buffer.from(this.strJobUserComment, 'utf-8').toString('base64'),
                            'strGlobalProjectName': store.state.GlobalProjectName.trim(),
                            'strCronProjectUUID': store.state.CronProjectUUID.trim(),
                            'strServiceAccount': this.selectedServiceEmail.trim(),
                            'strGmailAccount': Buffer.from(JSON.stringify(strGmailAccount), 'utf-8').toString('base64'),
                            'intLitePro': 0,
                            'Content-Type': 'application/json'
                        },
                        url: store.state.urlCJ + setJobURLonButton,
                        data: data

                    })
                    .then((response) => {
                        //console.log(response.data)
                        if(response.data == 'OK')
                        {
                            this.alert1 = true;
                            this.alert2 = false;
                            this.$router.push('/mainScreen/cronJobList')
                            this.$emit('close');
                        }
                        else
                        {
                            if(response.data == 'Found System Job from Running list')
                            {
                                this.alert1 = false;
                                this.alert2 = true;
                            }
                        }
                    })
                    .catch((response) => {
                        //console.log(response)
                        let err = Object.assign({}, response)
                        console.log('error', err.response);
                                        
                        if(err.response.status == '488')
                        {
                            this.$router.push('/')
                        }
                    });
                }
                else
                {
                    this.alert1 = false;
                    this.alertMessage = 'Не выбран тип задачи';
                    this.alert2 = true;
                }
            }
        }, 
        checkGKColumns () {
            //console.log(this.fromGcColumns[0])
            this.showTimer = true;
            this.timerCount = 100;
            this.countDown();
        },
        checkColumns () {
            //console.log(this.selectedColumns)
            let infoKyesMy = [];

            let GetColumnsFromJob = this.selectedColumns;

            for(let i=0;i<GetColumnsFromJob.length;i++){
                infoKyesMy.push(GetColumnsFromJob[i].text)
            }
            

            //console.log(infoKyesMy)

            let infoKyesMy1 = [
                        "id_заказа",
                        "номер",
                        "id_пользователя",
                        "пользователь",
                        "email",
                        "дата_создания",
                        "дата_оплаты",
                        "title",
                        "статус",
                        "стоимость_rub",
                        "user_utm_medium",
                        "user_utm_campaign",
                        "user_utm_content",
                        "user_utm_term",
                        "user_utm_group",
                        "дата_создания_дт",
                        "дата_создания_вр",
                        "дата_оплаты_дт",
                        "дата_оплаты_вр",
                        "теги",
                        "теги_предложений"
                        ]
            console.log(infoKyesMy1)
        },
        async getGKColumnsExp () {
            let data = []
            this.getGKColumnsExpDisabled = true;

            //console.log(this.schoolURL.trim().substring(this.schoolURL.length-1))

            if(this.schoolURL.trim().substring(this.schoolURL.length-1) == '/')
            {
                this.schoolURL = this.schoolURL.trim().substring(0,this.schoolURL.length-1);
            }

            //console.log(this.schoolURL)

            //this.schoolURL = 'https://yanavegana.ru'
            //this.schoolApiKey= 'mOkw34AWZUK4fZ2kHysWNGELsYiEjtieenveh2hHS8RKmWxlOntPnCpm8TvhEoBlaAqaCswNV3sUxXJi370814tNhZqxjsG76huSZC69Et9gkqPJRWXaOWxiCboywrFd'

            axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.CurrentApiKey,
                    'schoolURL': this.schoolURL,
                    'schoolApiKey': this.schoolApiKey,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getGKDealsColumnsExp',
                data: data

            })
            .then((response) => {
                //console.log(response.data)
                this.ExportID = response.data
                //console.log(response.status)
                if(this.ExportID > 0){
                    this.alert1 = false;
                    this.alert2 = false;
                    this.alert3 = false;
                
                    this.showTimer = true;
                    this.timerCount = 100;
                    this.ShowExportID = false;
                    this.countDown();
                }
            })
            .catch((response) => {
                let ErrorMessage = '';
                this.alert1 = false;
                this.alert2 = false;
                ErrorMessage = ErrorMessage + 'Геткурс в данный момент занят. Дождитесь окончания текущей задачи в геткурсе!'
                this.alertMessage = ErrorMessage;
                this.alert3 = true;
                this.getGKColumnsExpDisabled = false;

                let err = Object.assign({}, response)
                console.log('error', err.response);
                                
                if(err.response.status == '488')
                {
                    this.$router.push('/')
                }

            })
        },
        countDown() {
            //If the counter has not reached the end
            if(this.timerCount > 0) {
                //Wait 1 second, then decrement the counter
                setTimeout(()=>{
                    this.timerCount-=5;
                    this.countDown();
                }, 1000)
            }
            else
            {
                //Count down has reached zero, redirect
                this.getGKColumnsOnId ();
            }
        },
        async getGKColumnsOnId () {
            let data = []
            this.showTimer = false;
            this.ShowExportID = true;
            //this.schoolURL = 'https://yanavegana.ru'
            //this.schoolApiKey= 'mOkw34AWZUK4fZ2kHysWNGELsYiEjtieenveh2hHS8RKmWxlOntPnCpm8TvhEoBlaAqaCswNV3sUxXJi370814tNhZqxjsG76huSZC69Et9gkqPJRWXaOWxiCboywrFd'

            //this.ExportID = 17059306;

            axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.CurrentApiKey,
                    'schoolURL': this.schoolURL,
                    'schoolApiKey': this.schoolApiKey,
                    'ExportID': this.ExportID,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getGKDealsColumns',
                data: data

            })
            .then((response) => {
                //console.log(response.data[0])
                if(response.data[0] !== undefined){
                    let infoKyes = response.data;
                    
                    for(let i=0;i<infoKyes.length;i++){
                        let replace = infoKyes[i].replace(/ /g, '_');
                        let replace1 = replace.replace(('('), '');
                        let replace2 = replace1.replace((')'), '');
                        let replace3 = replace2.replace((','), '');
                        let replace4 = replace3.replace(('-'), '_');
                        let replace5 = replace4.replace(('?'), '');
                        //console.log(replace3.toLowerCase())
                        infoKyes[i]=replace5.toLowerCase();
                    }

                    //Заполняем выбранные колонки
                    this.fromGcColumns = []
                    //this.ExportID = response.data
                    let bodySelectedColumn = infoKyes;
                    let sSelectedColumn = '';
                    for(let key=0; key < bodySelectedColumn.length; key++) {  
                        sSelectedColumn = bodySelectedColumn[key];
                        this.fromGcColumns.push({ id: key, text: sSelectedColumn });
                    }
                    
                    //Убираем отключенные колонки в случае если пользователь повторно подгружает колонки из Геткурса
                    let sFromGK = '';
                    let bodyFromGK = this.selectedColumns
                    
                    for(let keyFromGK=0; keyFromGK < bodyFromGK.length; keyFromGK++) {  
                        sFromGK = bodyFromGK[keyFromGK];
                        let findIdColumn = this.fromGcColumns.find(column => column.text === sFromGK.text);
                        
                        if (findIdColumn) {
                            let indexSearchSelectedColumn = this.fromGcColumns.findIndex(item => item.id === findIdColumn.id);
                            
                            if (indexSearchSelectedColumn !== -1) {
                                this.fromGcColumns.splice(indexSearchSelectedColumn, 1);
                            }
                        }
                    }

                    this.getGKColumnsExpDisabled = false;
                }
                
            })
            .catch((response) => {
                //console.log(response)
                let err = Object.assign({}, response)
                console.log('error', err.response);
                                
                if(err.response.status == '488')
                {
                    this.$router.push('/')
                }
            });
        }
    },

    watch: {
        selectCondition(){
            //console.log(this.selectCondition)
            if(this.selectCondition == '='){
                this.SpecialDisabled = true;
            }
            else
            {
                this.SpecialDisabled = false;
            }
        },
        jobTypeSelect(){
            //console.log(this.jobTypeSelect);
            if(this.jobTypeSelect == 'Разовая задача')
            {
                this.jobRepeatEveryDisabled = true;
                this.jobScheduledDisabled = false;
                this.schoolURLRD = true;
                this.showSaveCopyButtons = false;
                this.showStartButton = true;
                this.uuid = uuidv4();
            }
            else
            {
                this.jobRepeatEveryDisabled = false;
                this.jobScheduledDisabled = true;
            }
        },
        exm00(){
            if(this.exm00 == 1){
                this.exm05=0;
                this.exm10=0;
                this.exm15=0;
                this.exm20=0;
                this.exm25=0;
                this.exm30=0;
                this.exm35=0;
                this.exm40=0;
                this.exm45=0;
                this.exm50=0;
                this.exm55=0;
            }
        },
        exm05(){
            if(this.exm05 == 1){
            this.exm00=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm10(){
            if(this.exm10 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm15(){
            if(this.exm15 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm20(){
            if(this.exm20 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm25(){
            if(this.exm25 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm30(){
            if(this.exm30 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm35(){
            if(this.exm35 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm40(){
            if(this.exm40 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm45(){
            if(this.exm45 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm50(){
            if(this.exm50 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm55=0;
            }
        },
        exm55(){
            if(this.exm55 == 1){
            this.exm00 = 0;
            this.exm05 = 0;
            this.exm10 = 0;
            this.exm15 = 0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            }
        },
        dateCreateAddColumn() {
            //console.log(this.dateCreateAddColumn);
            let indexS = this.selectedColumns.map(function(item) {
                    return item.id
            }).indexOf(900);
            //console.log(indexS)
            if(this.dateCreateAddColumn == true && indexS==-1){
                this.selectedColumns.push({ id: 900, text: 'дата_создания_дт' });
                this.selectedColumns.push({ id: 901, text: 'дата_создания_вр' });
            }
            if(this.dateCreateAddColumn == null){
                let index = this.selectedColumns.map(function(item) {
                    return item.id
                }).indexOf(900);
                this.selectedColumns.splice(index, 1);

                index = this.selectedColumns.map(function(item) {
                    return item.id
                }).indexOf(901);
                this.selectedColumns.splice(index, 1);
            }
        },
        datePayAddColumn() {
            //console.log(this.datePayAddColumn);
            let indexS = this.selectedColumns.map(function(item) {
                    return item.id
            }).indexOf(902);
            if(this.datePayAddColumn == true && indexS==-1){
                this.selectedColumns.push({ id: 902, text: 'дата_оплаты_дт' });
                this.selectedColumns.push({ id: 903, text: 'дата_оплаты_вр' });
            }
            if(this.datePayAddColumn == null){
                let index = this.selectedColumns.map(function(item) {
                    return item.id
                }).indexOf(902);
                this.selectedColumns.splice(index, 1);

                index = this.selectedColumns.map(function(item) {
                    return item.id
                }).indexOf(903);
                this.selectedColumns.splice(index, 1);
            }
        },
        selectParams(){
            if(this.selectParams == true){ this.selectParamsDisabled = true; }
            if(this.selectParams == false){ this.selectParamsDisabled = false; }
        }
        
    },
    async mounted(){
        this.loadPickerApi();
        this.loadGoogleAccountsByProject();
        //this.$store.getters.ProjectUUID
        //console.log(this.$store.getters.ProjectUUID)

        this.$store.dispatch('loadpgGoogleServiceAccounts')
        //console.log(this.$store.getters.pgGoogleServiceAccounts)
        const accounts = this.$store.getters.pgGoogleServiceAccounts;
        this.ServiceEmailList = Object.values(accounts).map(account => account.gAccount_name);

        const settingJobUUID = {
            strProjectUUID: this.$store.getters.ProjectUUID,
        }
        await this.$store.dispatch('loadjobProjectsById', settingJobUUID)
        .then(async() => {
        

            //console.log('Chto to1')
            //console.log(this.$store.getters.jobProjectsById[0])
            this.uuid = this.$store.getters.jobProjectsById[0].UUID
            this.sheetId = this.$store.getters.jobProjectsById[0].sheetId
            this.spreadsheetId = this.$store.getters.jobProjectsById[0].spreadsheetId
            this.rangeAdd = this.$store.getters.jobProjectsById[0].rangeAdd

            let GetDateStart = new Date(this.$store.getters.jobProjectsById[0].dateStart)
            this.dateStart = date.format(GetDateStart, 'YYYY-MM-DD');

            /* console.log(this.createScheduleString(this.waitTimeH, this.waitTime))
               let WaitTest = this.createScheduleString(this.waitTimeH, this.waitTime)
               let WaitTestOut = this.parseScheduleString(WaitTest)
               console.log(WaitTestOut.hours)
               console.log(WaitTestOut.minutes) */
            let WaitTestOut = this.parseScheduleString(this.$store.getters.jobProjectsById[0].waitTime);
            this.waitTimeH = WaitTestOut.hours;
            this.waitTime = WaitTestOut.minutes;
            this.schoolURL = this.$store.getters.jobProjectsById[0].schoolURL
            //this.strJobUserComment = this.$store.getters.jobProjectsById[0].strJobUserComment
            this.strJobUserComment = this.$store.getters.jobProjectsById[0].strJobUserComment == null ? '' : this.$store.getters.jobProjectsById[0].strJobUserComment.toString().trim()
            this.schoolApiKey = this.$store.getters.jobProjectsById[0].schoolApiKey
            
            //console.log(this.$store.getters.jobProjectsById[0].strGmailAccount)

            if(this.$store.getters.jobProjectsById[0].strGmailAccount !== '' && this.$store.getters.jobProjectsById[0].strGmailAccount !== undefined && this.$store.getters.jobProjectsById[0].strGmailAccount !== null){
                let getGoogleAccountParams = JSON.parse(this.$store.getters.jobProjectsById[0].strGmailAccount)
                //console.log(getGoogleAccountParams)
                try{
                    this.selectedFile = getGoogleAccountParams[0].selectedFile
                    this.g_mailget = getGoogleAccountParams[0].g_mailget
                    this.sheetsList = [
                        {
                            id: this.sheetId,
                            name: getGoogleAccountParams[0].selectedSheet
                        }
                    ]
                    this.selectedSheet = getGoogleAccountParams[0].selectedSheet
                    this.getGoogleAuthACTbyEmail();
                }
                catch
                {
                    console.log('Err Google Account')
                }
            }

            if(this.$store.getters.jobProjectsById[0].strJobRepeat == 'repeatEvery'){
                this.jobTypeSelect = 'Регулярная задача';
            }
            else
            {
                this.jobTypeSelect = 'Разовая задача';
            }

            if(this.$store.getters.jobProjectsById[0].useFilterRules == 1)
            {
                this.selectParams = true;
                this.rules = JSON.parse(this.$store.getters.jobProjectsById[0].filterRules)

                for(let iRules = 0; iRules<this.rules.length; iRules++){
                    //console.log(this.rules[iRules].Number)
                    if(this.rulesNumbers<this.rules[iRules].Number){
                        this.rulesNumbers = this.rules[iRules].Number;
                    }
                }
            }

            let CronTime = this.$store.getters.jobProjectsById[0].strJobTime;
            let CronTimeArr = CronTime.split(' ');

            //console.log(CronTimeArr[1]);

            let CronTimeMinute = CronTimeArr[0];

            if(CronTimeMinute == 0){ this.exm00 = 1}
            if(CronTimeMinute == 5){ this.exm05 = 1}
            if(CronTimeMinute == 10){ this.exm10 = 1}
            if(CronTimeMinute == 15){ this.exm15 = 1}
            if(CronTimeMinute == 20){ this.exm20 = 1}
            if(CronTimeMinute == 25){ this.exm25 = 1}
            if(CronTimeMinute == 30){ this.exm30 = 1}
            if(CronTimeMinute == 35){ this.exm35 = 1}
            if(CronTimeMinute == 40){ this.exm40 = 1}
            if(CronTimeMinute == 45){ this.exm45 = 1}
            if(CronTimeMinute == 50){ this.exm50 = 1}
            if(CronTimeMinute == 55){ this.exm55 = 1}

            let CronTimeHoursArr = CronTimeArr[1].split(',');

            for(let iCron = 0; iCron<CronTimeHoursArr.length; iCron++){
                if(CronTimeHoursArr[iCron] == 0){ this.ex00 = 1}
                if(CronTimeHoursArr[iCron] == 1){ this.ex01 = 1}
                if(CronTimeHoursArr[iCron] == 2){ this.ex02 = 1}
                if(CronTimeHoursArr[iCron] == 3){ this.ex03 = 1}
                if(CronTimeHoursArr[iCron] == 4){ this.ex04 = 1}
                if(CronTimeHoursArr[iCron] == 5){ this.ex05 = 1}
                if(CronTimeHoursArr[iCron] == 6){ this.ex06 = 1}
                if(CronTimeHoursArr[iCron] == 7){ this.ex07 = 1}
                if(CronTimeHoursArr[iCron] == 8){ this.ex08 = 1}
                if(CronTimeHoursArr[iCron] == 9){ this.ex09 = 1}
                if(CronTimeHoursArr[iCron] == 10){ this.ex10 = 1}
                if(CronTimeHoursArr[iCron] == 11){ this.ex11 = 1}
                if(CronTimeHoursArr[iCron] == 12){ this.ex12 = 1}
                if(CronTimeHoursArr[iCron] == 13){ this.ex13 = 1}
                if(CronTimeHoursArr[iCron] == 14){ this.ex14 = 1}
                if(CronTimeHoursArr[iCron] == 15){ this.ex15 = 1}
                if(CronTimeHoursArr[iCron] == 16){ this.ex16 = 1}
                if(CronTimeHoursArr[iCron] == 17){ this.ex17 = 1}
                if(CronTimeHoursArr[iCron] == 18){ this.ex18 = 1}
                if(CronTimeHoursArr[iCron] == 19){ this.ex19 = 1}
                if(CronTimeHoursArr[iCron] == 20){ this.ex20 = 1}
                if(CronTimeHoursArr[iCron] == 21){ this.ex21 = 1}
                if(CronTimeHoursArr[iCron] == 22){ this.ex22 = 1}
                if(CronTimeHoursArr[iCron] == 23){ this.ex23 = 1}
            }

            this.selectedColumns = JSON.parse(this.$store.getters.jobProjectsById[0].schoolColumns)
            this.fromGcColumns =  JSON.parse(this.$store.getters.jobProjectsById[0].schoolColumnsOut)

            //console.log(this.$store.getters.jobProjectsById[0].strServiceAccount)
            if(this.$store.getters.jobProjectsById[0].strServiceAccount == undefined || this.$store.getters.jobProjectsById[0].strServiceAccount == ''){
                this.selectedServiceEmail = 'sheets@striking-berm-310303.iam.gserviceaccount.com'
            }
            else
            {
                this.selectedServiceEmail = this.$store.getters.jobProjectsById[0].strServiceAccount
            }

            let indexS900 = this.selectedColumns.map(function(item) {
                    return item.id
            }).indexOf(900);
            //console.log(indexS)
            if(indexS900!=-1){
                this.dateCreateAddColumn = true;
            }

            let indexS902 = this.selectedColumns.map(function(item) {
                    return item.id
            }).indexOf(902);
            //console.log(indexS)
            if(indexS902!=-1){
                this.datePayAddColumn = true;
            }

            if(this.$store.getters.ProjectCOPY == 1){
                this.showSaveCopyButtons = false;
                this.showStartButton = true;
                this.uuid = uuidv4();
            }
            if(this.$store.getters.ProjectCOPY == 2){
                this.jobTypeSelect = 'Разовая задача';
                this.schoolURLRD = true;
                this.showSaveCopyButtons = false;
                this.showStartButton = true;
                let GetDateStartOne = new Date()
                this.dateStart = date.format(GetDateStartOne, 'YYYY-MM-DD');
                this.uuid = uuidv4();
            }
        })
    }

    
}
</script>

<style scoped>
.selected-row {
  background-color: #e0e0e0;
}

.widget-container {
  display: flex;
}

.widget-container > * {
  height: 400px;
  width: 50%;
  padding: 10px;
}

.dx-scrollview-content {
  min-height: 380px;
}

.lists-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.list {
  width: 45%;
}

.list-wrapper {
  height: 500px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
}

.list-content {
  overflow-x: auto;
  white-space: nowrap;
}

.list-item {
  padding: 0 8px;
  min-height: 32px !important;
}

.item-text {
  font-size: 14px;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 20px;
}

.v-list-item__content {
  padding: 2px 0;
}

</style>