<template>
    <v-card class="mx-auto px-6 py-8" max-width="80%" flat>
        <v-card>
            <v-card-title class="bg-amber-lighten-1">
            <h2 class="me-4 font-weight-light">
                Сделки, Воронки
            </h2>
            </v-card-title>
            <v-card-text>
                Экспортировать Контакты из AmoCrm в Postgresql.
            </v-card-text>
        </v-card>
        <br>
        <v-dialog v-model="showErrorColumnDialog" max-width="290">
            <v-card>
                <v-card-title class="headline">Внимание</v-card-title>
                <v-card-text>{{ errorColumnMessage }}</v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeErrorColumnDialog">
                    Закрыть
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card flat class="mx-auto px-6 py-1">
            
                <form>
                    <v-text-field
                        v-model="uuid"
                        prepend-icon="mdi-identifier"
                        label="ID Задачи"
                        readonly
                    ></v-text-field>
                    <v-select
                        :items="jobType"
                        prepend-icon="mdi-checkbox-marked-circle-plus-outline"
                        label="Выберите тип задачи"
                        v-model="jobTypeSelect"
                    ></v-select>
                    <v-text-field
                        v-model="jobRepeatEvery"
                        prepend-icon="mdi-timer-alert-outline"
                        :error-messages="nameErrors"
                        label="Запускать через N минут"
                        type="number"
                        required
                        v-show="jobRepeatEveryDisabled"
                        @input="$v.jobRepeatEvery.$touch()"
                        @blur="$v.jobRepeatEvery.$touch()"
                    ></v-text-field>
                    <v-checkbox v-model="ClearScheet" label="Очищать Таблицу перед загрузкой данных?" color="primary" hide-details></v-checkbox>
                    <br>
                    <p v-show="jobScheduledDisabled">Выберите Часы и минуты для регулярного запуска (Например запускать в 4,6,8,9 часов 25 минут)</p>
                    <p v-show="jobScheduledDisabled"><b>Часы для запуска (МСК):</b></p>
                    
                    <v-card class="d-flex flex-wrap" flat tile v-show="jobScheduledDisabled">
                        <!-- <v-checkbox class="px-3" v-model="ex00" label="00" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex01" label="01" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox> -->
                        <v-checkbox class="px-3" v-model="ex02" label="02" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex03" label="03" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex04" label="04" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex05" label="05" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex06" label="06" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex07" label="07" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex08" label="08" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex09" label="09" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex10" label="10" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex11" label="11" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex12" label="12" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex13" label="13" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex14" label="14" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex15" label="15" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex16" label="16" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex17" label="17" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex18" label="18" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex19" label="19" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex20" label="20" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex21" label="21" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex22" label="22" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="ex23" label="23" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                    </v-card>
                    <br v-show="jobScheduledDisabled">
                    <p v-show="jobScheduledDisabled"><b>Минуты для запуска:</b></p>
                    <v-card class="d-flex flex-wrap" flat tile v-show="jobScheduledDisabled">
                        <v-checkbox class="px-3" v-model="exm00" label="00" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm05" label="05" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm10" label="10" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm15" label="15" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm20" label="20" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm25" label="25" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm30" label="30" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm35" label="35" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm40" label="40" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm45" label="45" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3" v-model="exm50" label="50" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        <v-checkbox class="px-3 pb-6" v-model="exm55" label="55" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                        
                    </v-card>
                    
                    <v-text-field
                        v-model="rangeAdd"
                        prepend-icon="mdi-history"
                        label="Глубина дней для импорта от текущей даты"
                        :error-messages="rangeAddErrors"
                        required
                        type="number"
                        v-show="jobScheduledDisabled"
                        @input="$v.rangeAdd.$touch()"
                        @blur="$v.rangeAdd.$touch()"
                    ></v-text-field>
                    <v-dialog ref="dialog" v-model="modal" :return-value.sync="dateStart" persistent width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateStart" label="Дата начала отгрузки Контактов из AmoCrm" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" v-show="jobRepeatEveryDisabled"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateStart" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="modal = false">
                                Отмена
                            </v-btn>
                            <v-btn text color="primary" @click="$refs.dialog.save(dateStart)">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>
                    <v-text-field
                        v-model="schoolURL"
                        prepend-icon="mdi-web"
                        label="URL адрес школы (Например: https://yanavegana.ru)"
                        :error-messages="schoolURLErrors"
                        required
                        @input="handleSchoolURLInput"
                        @blur="$v.schoolURL.$touch()"
                    ></v-text-field>
                    <v-text-field
                        v-model="schoolAmoURL"
                        prepend-icon="mdi-web"
                        label="URL адрес школы в AmoCrm (Например: https://hrhrsecrets.amocrm.ru) ВАЖНО! Этот URL должен быть прописан дословно в интеграции."
                        :error-messages="schoolAmoURLErrors"
                        required
                        @input="handleSchoolAmoURLInput"
                        @blur="$v.schoolAmoURL.$touch()"
                    ></v-text-field>
                    <v-card class="mx-auto px-4 py-4" outlined>
                        <v-tabs v-model="tab">
                            <v-tab>Новый ключ</v-tab>
                            <v-tab>Существующий ключ</v-tab>
                            <v-tab>Долгосрочный ключ</v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-card flat>
                                <v-card-text>
                                    <v-text-field
                                        v-model="schoolAmoSecretKey"
                                        prepend-icon="mdi-keyboard-settings-outline"
                                        label="Секретный ключ (Интеграция AmoCrm)"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="schoolAmoIdIntegration"
                                        prepend-icon="mdi-identifier"
                                        label="ID Интеграции AmoCrm"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="schoolAmoCode"
                                        prepend-icon="mdi-code-brackets"
                                        label="Код авторизации (Интеграция AmoCrm)"
                                    ></v-text-field>
                                    <v-btn
                                        class="primary ma-2"
                                        @click="generateAmoApiKey"
                                        >
                                        Сгенерировать ключ для AmoCrm
                                    </v-btn>
                                    <div>
                                        <v-alert dense  text   type="error"  dismissible v-model="alertApiError" class="pa-1 ma-1">
                                                    {{ alertMessage }}
                                        </v-alert>
                                        <v-alert dense  text   type="success"  dismissible v-model="alertApiSuccess" class="pa-1 ma-1">
                                                    {{ alertMessage }}
                                        </v-alert>
                                    </div>
                                </v-card-text>
                                </v-card>
                            </v-tab-item>

                            <v-tab-item>
                                <v-card flat>
                                <v-card-text>
                                    <v-alert dense  text   type="success"  dismissible v-model="alertApiFound" class="pa-1 ma-1">
                                                {{ alertMessage }}
                                    </v-alert>
                                    <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th class="text-left">URL в АМО</th>
                                            <th class="text-left">Дата окончания ключа</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="item in existingKeyData" :key="item.url">
                                            <td>{{ item.url }}</td>
                                            <td>{{ item.expirationDate }}</td>
                                        </tr>
                                        </tbody>
                                    </template>
                                    </v-simple-table>
                                </v-card-text>
                                </v-card>
                            </v-tab-item>

                            <v-tab-item>
                                <v-card flat>
                                <v-card-text>
                                    <v-text-field
                                        v-model="schoolApiKey"
                                        prepend-icon="mdi-api"
                                        label="API ключ для подключения к AmoCrm"
                                    ></v-text-field>
                                </v-card-text>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                    <v-text-field
                        v-model="dbTableName"
                        prepend-icon="mdi-database-check-outline"
                        label="Название таблицы в БД PostgreSQL"
                        :error-messages="dbTableNameErrors"
                        required
                        @input="$v.dbTableName.$touch()"
                        @blur="$v.dbTableName.$touch()"
                    ></v-text-field>
                    <v-text-field
                        v-model="strJobUserComment"
                        prepend-icon="mdi-comment-edit-outline"
                        label="Комментарий пользователя"
                    ></v-text-field>
                    <br>
                    
                    
                    <DxDataGrid
                        id="gridContainer"
                        :data-source="pgcolumns"
                        :show-borders="true"
                        :selected-row-keys="selectedItemKeys"
                        @selection-changed="selectionChanged"
                        @row-updating="onRowUpdating"
                        :column-auto-width="true"
                        >
                        <DxEditing
                            :allow-updating="true"
                            :allow-adding="false"
                            :allow-deleting="true"
                            mode="cell"
                        />
                        <DxPaging :enabled="false"/>
                        <DxSelection mode="multiple"/>
                        <DxColumn
                            data-field="columnName"
                            caption="Наименование колонки"
                            :allow-sorting="false"
                        />
                        <DxColumn
                            data-field="columnType"
                            caption="Тип колонки"
                            :allow-sorting="false"
                        >
                            <DxLookup
                                :data-source="columntypes"
                                value-expr="ID"
                                display-expr="Name"
                            />
                        </DxColumn>
                        <DxColumn
                            data-field="columnLeng"
                            caption="Длина"
                            :allow-sorting="false"
                        />
                        <DxColumn
                            data-field="columnGKName"
                            caption="Имя в AMO"
                            :allow-sorting="false"
                        >
                            <DxLookup
                                :data-source="gkcolumns"
                                value-expr="ID"
                                display-expr="Name"
                            />
                        </DxColumn>
                        <DxToolbar>
                            <!-- <DxItem location="after">
                                <template #default>
                                    <DxButton
                                        @click="addRecords()"
                                        icon="add"
                                        text="Добавить новую запись"
                                    />
                                </template>
                            </DxItem> -->
                            
                        </DxToolbar>
                    </DxDataGrid>
                    <p></p>
                    <p>Настройка информации (полей) отгружаемой из AmoCrm. Выполните разовый запрос в AmoCrm, для загрузки всех доступных полей. Новые поля не по шаблону будут добавлены в конец списка!</p>
                    <v-btn
                        class="primary ma-2"
                        @click="getAmoCustomFields"
                        >
                        Получить "Доп. Поля" из AmoCrm
                    </v-btn>
                    <v-btn
                        color="blue-grey"
                        class="ma-2 white--text"
                        @click="addRecords()"
                        >
                        Добавить колонку
                    </v-btn>
                    <div>
                        <v-alert dense  text   type="error"  dismissible v-model="alert5" class="pa-1 ma-1">
                                    {{ alertMessage }}
                        </v-alert>
                        <v-alert dense  text   type="success"  dismissible v-model="alert6" class="pa-1 ma-1">
                                    {{ alertMessage }}
                        </v-alert>
                    </div>
                    <br>
                    <v-divider></v-divider>
                    <br>
                    <v-btn
                        class="primary"
                        @click="submit"
                        >
                        запустить задачу
                    </v-btn>
                    
                </form>
           
        </v-card>
        <br>
        <div>
            <v-alert dense  text   type="success"  dismissible v-model="alert1" class="pa-1 ma-1">
                        Задача запущена!
            </v-alert>
            <v-alert dense  text   type="error"  dismissible v-model="alert2" class="pa-1 ma-1">
                        {{ alertMessage }}
            </v-alert>
        </div>
        
    </v-card>
    
    
  </template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer'; 
import date from 'date-and-time'
import 'date-and-time/plugin/timezone'

//import { DxButton } from 'devextreme-vue/button';

import axios from 'axios'
import store from '../store/store.js'

import {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxSelection,
        DxLookup,
        DxToolbar,
        //DxItem,
    } from 'devextreme-vue/data-grid';


export default {
    mixins: [validationMixin],

    validations: {
        jobRepeatEvery: { required },
        sheetId: { required },
        spreadsheetId: { required },
        rangeAdd: { required },
        waitTime: { required },
        schoolURL: { required },
        schoolAmoURL: { required },
        dbTableName: { required }
    },

    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxSelection,
        DxLookup,
        //DxButton,
        DxToolbar,
        //DxItem,
    },

    data: () => ({
        selectParamsDisabled: false,
        selectParams: false,
        show: false,
        uuid: uuidv4(),
        jobRepeatEvery: '',
        jobRepeatEveryDisabled: false,
        jobScheduled: '',
        jobScheduledDisabled: false,
        //jobType: ['Разовая задача', 'Регулярная задача'],
        jobType: ['Регулярная задача'],
        jobTypeSelect: '',
        alert1: false,
        alert2: false,
        alert3: false,
        alert4: false,
        alert5: false,
        alert6: false,
        alertApiError: false,
        alertApiSuccess: false,
        alertApiFound: false,
        alertMessage: '',
        canRunJobCheck: false,
        ex00: 0,
        ex01: 0,
        ex02: 0,
        ex03: 0,
        ex04: 1,
        ex05: 0,
        ex06: 1,
        ex07: 0,
        ex08: 1,
        ex09: 1,
        ex10: 0,
        ex11: 0,
        ex12: 0,
        ex13: 0,
        ex14: 0,
        ex15: 0,
        ex16: 0,
        ex17: 0,
        ex18: 0,
        ex19: 0,
        ex20: 0,
        ex21: 0,
        ex22: 0,
        ex23: 0,
        exm00: 0,
        exm05: 0,
        exm10: 0,
        exm15: 0,
        exm20: 0,
        exm25: 1,
        exm30: 0,
        exm35: 0,
        exm40: 0,
        exm45: 0,
        exm50: 0,
        exm55: 0,
        sheetId: '',
        spreadsheetId: '',
        rangeAdd: '0',
        dateStart: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        modal: false,
        waitTime: 1,
        schoolURL: '',
        schoolAmoURL: '',
        schoolAmoSecretKey: '',
        schoolAmoIdIntegration: '',
        schoolAmoCode: '',
        schoolApiKey: '',
        ExportID: '',
        ShowExportID: false,
        fromGcColumns: [],
        dbTableName: '',
        ClearScheet: false,
        pgcolumns: [
            { ID: 0, columnName: 'id',  columnType: 1, columnLeng: 0, columnGKName: 1, altertbl: 0 },
            { ID: 1, columnName: 'name',  columnType: 0, columnLeng: 300, columnGKName: 2, altertbl: 0 },
            { ID: 2, columnName: 'price',  columnType: 2, columnLeng: 18.2, columnGKName: 3, altertbl: 0 },
            { ID: 3, columnName: 'responsible_user_id',  columnType: 1, columnLeng: 0, columnGKName: 4, altertbl: 0 },
            { ID: 4, columnName: 'group_id',  columnType: 1, columnLeng: 0, columnGKName: 5, altertbl: 0 },
            { ID: 5, columnName: 'status_id',  columnType: 1, columnLeng: 0, columnGKName: 6, altertbl: 0 },
            { ID: 6, columnName: 'pipeline_id',  columnType: 1, columnLeng: 0, columnGKName: 7, altertbl: 0 },
            { ID: 7, columnName: 'loss_reason_id',  columnType: 1, columnLeng: 0, columnGKName: 8, altertbl: 0 },
            { ID: 8, columnName: 'created_by',  columnType: 1, columnLeng: 0, columnGKName: 9, altertbl: 0 },
            { ID: 9, columnName: 'updated_by',  columnType: 1, columnLeng: 0, columnGKName: 10, altertbl: 0 },
            { ID: 10, columnName: 'created_at',  columnType: 3, columnLeng: 0, columnGKName: 11, altertbl: 0 },
            { ID: 11, columnName: 'updated_at',  columnType: 3, columnLeng: 0, columnGKName: 12, altertbl: 0 },
            { ID: 12, columnName: 'closed_at',  columnType: 3, columnLeng: 0, columnGKName: 13, altertbl: 0 },
            { ID: 13, columnName: 'closest_task_at',  columnType: 3, columnLeng: 0, columnGKName: 14, altertbl: 0 },
            { ID: 14, columnName: 'is_deleted',  columnType: 1, columnLeng: 0, columnGKName: 15, altertbl: 0 },
            { ID: 15, columnName: 'contacts_id',  columnType: 1, columnLeng: 0, columnGKName: 16, altertbl: 0 }
        ],
        gkcolumns: [
            { ID: 0, Name: 'NULL' },
            { ID: 1, Name: 'id' },
            { ID: 2, Name: 'name' },
            { ID: 3, Name: 'price' },
            { ID: 4, Name: 'responsible_user_id' },
            { ID: 5, Name: 'group_id' },
            { ID: 6, Name: 'status_id' },
            { ID: 7, Name: 'pipeline_id' },
            { ID: 8, Name: 'loss_reason_id' },
            { ID: 9, Name: 'created_by' },
            { ID: 10, Name: 'updated_by' },
            { ID: 11, Name: 'created_at' },
            { ID: 12, Name: 'updated_at' },
            { ID: 13, Name: 'closed_at' },
            { ID: 14, Name: 'closest_task_at' },
            { ID: 15, Name: 'is_deleted' },
            { ID: 16, Name: 'contacts_id' },
            { ID: 17, Name: '----------------' }
        ],
        columntypes: [
            { ID: 0, Name: 'Строка' },
            { ID: 1, Name: 'Целое число' },
            { ID: 2, Name: 'Сумма' },
            { ID: 3, Name: 'Дата' },
            { ID: 4, Name: 'Время' },
            { ID: 5, Name: 'Текст' }
        ],
        selectedItemKeys: [],
        strJobUserComment: '',
        tab: null,
        existingKeyData: [ ],
        showErrorColumnDialog: false,
        errorColumnMessage: ''
    }),

    computed: {
      nameErrors () {
        const errors = []
        if (!this.$v.jobRepeatEvery.$dirty) return errors
            !this.$v.jobRepeatEvery.required && errors.push('Обязательное поле. Укажите количество минут через которое произведем запуск разовой задачи.')
        return errors
      },
      sheetIdErrors () {
        const errors = []
        if (!this.$v.sheetId.$dirty) return errors
            !this.$v.sheetId.required && errors.push('Обязательное поле. Количество дней в глубину для запроса данных.')
        return errors
      },
      spreadsheetIdErrors () {
        const errors = []
        if (!this.$v.spreadsheetId.$dirty) return errors
            !this.$v.spreadsheetId.required && errors.push('Обязательное поле. Укажите Уникальный идентификатор книги Google.')
        return errors
      },
      rangeAddErrors () {
        const errors = []
        if (!this.$v.rangeAdd.$dirty) return errors
            !this.$v.rangeAdd.required && errors.push('Обязательное поле. Количество дней в глубину для запроса данных.')
        return errors
      },
      waitTimeErrors () {
        const errors = []
        if (!this.$v.waitTime.$dirty) return errors
            !this.$v.waitTime.required && errors.push('Обязательное поле. Укажите задержку перед запросом информации из AmoCrm (AmoCrm требуется время для обработки вашего запроса).')
        return errors
      },
      schoolURLErrors () {
        const errors = []
        if (!this.$v.schoolURL.$dirty) return errors
            !this.$v.schoolURL.required && errors.push('Обязательное поле. URL адрес Школы. (https://yanavegana.ru)')
        return errors
      },
      schoolAmoURLErrors () {
        const errors = []
        if (!this.$v.schoolAmoURL.$dirty) return errors
            !this.$v.schoolAmoURL.required && errors.push('Обязательное поле. AmoCrm URL адрес Школы. (https://hrhrsecrets.amocrm.ru/)')
        return errors
      },
      /* schoolApiKeyErrors () {
        const errors = []
        if (!this.$v.schoolApiKey.$dirty) return errors
            !this.$v.schoolApiKey.required && errors.push('Обязательное поле. API ключ для подключения и закрузки данных из AmoCrm.')
        return errors
      }, */
      dbTableNameErrors () {
        const errors = []
        if (!this.$v.dbTableName.$dirty) return errors
            !this.$v.dbTableName.required && errors.push('Обязательное поле. Название таблицы для отнрузки данных в PostgreSQL.')
        return errors
      },
    },

    methods: {
        closeErrorColumnDialog() {
            this.showErrorColumnDialog = false;
            this.errorColumnMessage = '';
        },
        onRowUpdating(e) {
            if (e.newData.columnType !== undefined && e.newData.columnType !== e.oldData.columnType) {
                // Немедленно обновляем данные
                this.updateColumnLength(e.newData, e.key);
                
                // Обновляем newData, чтобы изменения отразились в гриде
                const updatedRow = this.pgcolumns.find(item => item.ID === e.key);
                if (updatedRow) {
                    e.newData.columnLeng = updatedRow.columnLeng;
                }
            }
            if(e.newData.columnName !== undefined && e.newData.columnName !== e.oldData.columnName){
                //console.log(e.oldData.columnName)
                //console.log(e.oldData.altertbl)
                
                // Проверяем формат нового названия колонки
                const validColumnNameRegex = /^[a-zA-Z0-9_]+$/;
                if (!validColumnNameRegex.test(e.newData.columnName)) {
                    e.cancel = true;
                    
                    // Показываем сообщение об ошибке
                    this.errorColumnMessage = 'Название колонки может содержать только латинские буквы, цифры и знак подчеркивания';
                    this.showErrorColumnDialog = true;
                    
                    // Восстанавливаем старое значение
                    this.$nextTick(() => {
                    if (e.component) {
                        e.component.cancelEditData();
                    }
                    });
                }
                
            }
        },
        updateColumnLength(newData, key) {
            let columnLeng;
            switch(newData.columnType) {
                case 0: // Строка
                    columnLeng = 250;
                    break;
                case 1: // Целое число
                case 5: // Текст
                    columnLeng = 0;
                    break;
                case 2: // Сумма
                    columnLeng = '18.2';
                    break;
                case 3: // Дата
                case 4: // Время
                    columnLeng = 0;
                    break;
                default:
                    // Для других типов оставляем текущее значение
                    return;
            }
            
            // Найдем обновляемую строку
            const rowToUpdate = this.pgcolumns.find(item => 
                item.ID === key.ID &&
                item.columnName === key.columnName &&
                item.columnGKName === key.columnGKName
            );

            if (rowToUpdate) {
                // Обновляем значение columnLeng
                this.$set(rowToUpdate, 'columnLeng', columnLeng);
                this.$set(rowToUpdate, 'columnType', newData.columnType);
                                
                // Вызываем $forceUpdate для обновления представления
                this.$forceUpdate();
            }
            
            return columnLeng;
        },
        extractDomainName(url) {
            try {
                const parsedUrl = new URL(url);
                const hostnameParts = parsedUrl.hostname.split('.');

                if (hostnameParts.includes('getcourse')) {
                    return hostnameParts[0];
                }
                
                // Для случаев вроде 'course.polinabolshakova.ru'
                if (hostnameParts.length > 2 && hostnameParts[0] !== 'www') {
                    return hostnameParts[hostnameParts.length - 2].replace(/[-_]/g, '');
                }
                
                // Для случаев вроде 'yanavegana.ru'
                return hostnameParts[hostnameParts.length - 2].replace(/[-_]/g, '');
                
            } catch (error) {
                // Если URL некорректный, возвращаем пустую строку
                return '';
            }
        },
        handleSchoolURLInput(value) {
            this.$v.schoolURL.$touch();
            this.dbTableName = this.extractDomainName(value);
        },
        formatDate(dateString) {
            try {
                // Парсим исходную дату (она в UTC)
                let parsedDate = new Date(dateString)

                // Проверяем, является ли дата валидной
                if (isNaN(parsedDate.getTime())) {
                    throw new Error('Invalid date')
                }

                // Конвертируем дату в указанный часовой пояс
                //const formattedDate = date.format(parsedDate, 'DD.MM.YYYY HH:mm', 'Europe/Moscow')
                const formattedDate = date.format(parsedDate, 'DD.MM.YYYY HH:mm', '')
                
                return formattedDate
            } catch (error) {
                console.error('Ошибка при форматировании даты:', error)
                return 'Неверный формат даты'
            }
        },
        isValidURL(string) {
            try {
                new URL(string)
                return true
            } catch (err) {
                return false
            }
        },
        handleSchoolAmoURLInput(value) {
            if (!this.isValidURL(value)) {
                return
            }
            let data = []

            if(value.trim().substring(value.length-1) == '/')
            {
                value = value.trim().substring(0,value.length-1);
                this.schoolAmoURL = value;
            }
           
            axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.CurrentApiKey,
                    'schoolAmoURL': value,
                    'strCronProjectUUID': store.state.CronProjectUUID.trim(),
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getAmoCrmTokenByProjectId',
                data: data

            })
            .then((response) => {
                //console.log(response.data[0])
                if(response.status == '200'){
                    //console.log(response.data[0].AccessDateEnd)
                    if(response.data[0] !== undefined){

                        this.tab = 1;

                        let ErrorMessage = '';
                        this.alertApiFound = false;
                        
                        ErrorMessage = ErrorMessage + 'Найден API ключ для введенного адреса!'
                        this.alertMessage = ErrorMessage;
                        this.alertApiFound = true;

                        this.existingKeyData = [
                                { url: response.data[0].schoolAmoURL, expirationDate: this.formatDate(response.data[0].AccessDateEnd) }
                            ]
                    }
                    else
                    {
                        this.tab = 0;

                        let ErrorMessage = '';
                        this.alertMessage = ErrorMessage;
                        this.alertApiFound = false;
                        
                        this.existingKeyData = [ ]
                    }
                }
                else
                {
                    this.tab = 0;

                    let ErrorMessage = '';
                    this.alertMessage = ErrorMessage;
                    this.alertApiFound = false;
                    
                    this.existingKeyData = [ ]
                }
            })
            .catch((response) => {
                //console.log(response)
                let err = Object.assign({}, response)
                console.log('error', err.response);
                                
                if(err.response.status == '488')
                {
                    this.$router.push('/')
                }
            });
            
        },
        selectionChanged (data) {
            this.selectedItemKeys = data.selectedRowKeys;
            //console.log(this.selectedItemKeys)
        },
        addRecords () {
            //console.log(this.pgcolumns.length);
            this.pgcolumns.push({ ID: this.pgcolumns.length, columnName: '',  columnType: 0, columnLeng: 50, columnGKName: 0, altertbl: 0 })
            //console.log(this.pgcolumns);
        },
        async submit () {
            this.canRunJobCheck = true;
            let ErrorMessage = '';

            if(this.ex00 == 0 && 
                this.ex02 == 0 && 
                this.ex03 == 0 && 
                this.ex04 == 0 && 
                this.ex05 == 0 && 
                this.ex06 == 0 && 
                this.ex07 == 0 && 
                this.ex08 == 0 && 
                this.ex09 == 0 && 
                this.ex10 == 0 && 
                this.ex11 == 0 && 
                this.ex12 == 0 && 
                this.ex13 == 0 && 
                this.ex14 == 0 && 
                this.ex15 == 0 && 
                this.ex16 == 0 && 
                this.ex17 == 0 && 
                this.ex18 == 0 && 
                this.ex19 == 0 && 
                this.ex20 == 0 && 
                this.ex21 == 0 && 
                this.ex22 == 0 && 
                this.ex23 == 0
            )
            { 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не указаны часы для выполнения задачи; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.exm00 == 0 && 
                this.exm05 == 0 && 
                this.exm10 == 0 && 
                this.exm15 == 0 && 
                this.exm25 == 0 && 
                this.exm30 == 0 && 
                this.exm35 == 0 && 
                this.exm40 == 0 && 
                this.exm45 == 0 && 
                this.exm50 == 0 && 
                this.exm55 == 0
            )
            { 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не указаны минуты для выполнения задачи; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.strJobUserComment == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не введен Комментарий для задачи; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.dbTableName == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не введено Название таблицы; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            let hasTableInDB = '';
            hasTableInDB = 'NO';

            await axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.CurrentApiKey,
                    'jobUUID': this.uuid,
                    'strGlobalProjectName': store.state.GlobalProjectName.trim(),
                    'dbTableNameSql': 'tbl_amocrmleads_' + this.dbTableName,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'pgCheckTableExists',
                data: []

            })
            .then((response) => {
                //console.log(response.data)
                if(response.data == 'YES')
                {
                    hasTableInDB = 'YES';
                }
            })

            if(hasTableInDB === 'YES'){
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Таблица с таким наименованием уже существует в Базе Данных. Это означает, что у вас уже есть работающая задача по данному проекту по отгрузке данных этого вида из ГК. Если вам нужно создать дублирующую задачу по этому же проекту, но с другими параметрами, воспользуйтесь функционалом "Копировать" в верхнем правом углу экрана "Список запланированных задач"; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            const validColumnNameRegex = /^[a-zA-Z0-9_]+$/;
            if (!validColumnNameRegex.test(this.dbTableName)) {
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Название таблицы может содержать только латинские буквы, цифры и знак подчеркивания; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.rangeAdd == '' && this.jobTypeSelect == 'Регулярная задача'){  
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не введено Глубина погружения по дате отгрузки; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.dateStart == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не введена Дата начала отгрузки заказов из AmoCrm; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }
            if(this.jobTypeSelect == 'Регулярная задача'){
                if(this.waitTime === ''){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Не введена Задержка в минутах перед запуском загрузки из AmoCrm; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }

                if(this.waitTime <= 0){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Задержка в минутах перед запуском загрузки из AmoCrm, не может быть меньше или равна 0; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }
            }
            else
            {
                if(this.waitTime === ''){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Не введена Задержка в минутах перед запуском загрузки из AmoCrm; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }

                if(this.waitTime <= 0){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Задержка в минутах перед запуском загрузки из AmoCrm, не может быть меньше или равна 0; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }

                if(this.jobRepeatEvery == ''){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Не введен параметр Запускать через N минут; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }

                if(this.jobRepeatEvery <= 0){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Параметр Запускать через N минут, не может быть меньше или равен 0; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }
            }
            //console.log(this.jobRepeatEvery);
            if(this.canRunJobCheck == true){
                if(this.jobTypeSelect != '')
                {
                    let TmpColumnData = [];
                    for(let i=0;i<this.pgcolumns.length;i++){
                        //console.log(this.columntypes[this.pgcolumns[i].columnType].Name);
                        TmpColumnData.push({ ID: i, columnName: this.pgcolumns[i].columnName,  columnType: this.columntypes[this.pgcolumns[i].columnType].Name, columnLeng: this.pgcolumns[i].columnLeng, columnGKName: this.gkcolumns[this.pgcolumns[i].columnGKName].Name, altertbl: this.pgcolumns[i].altertbl })
                    }

                    let NewColumnData = TmpColumnData;
                    TmpColumnData = [];

                    let toDBColumn = [];

                    /* toDBColumn['PGWork'] = [];
                    toDBColumn['PGcolumns'] = [];
                    toDBColumn['GKcolumns'] = [];
                    toDBColumn['TYPEcolumns'] = []; */

                    toDBColumn.push(NewColumnData);
                    toDBColumn.push(this.pgcolumns);
                    toDBColumn.push(this.gkcolumns);
                    toDBColumn.push(this.columntypes);


                    //console.log(toDBColumn);
                    //console.log(JSON.stringify(toDBColumn))

                    //let data = toDBColumn
                    //console.log(data)

                    let Hours = '';
                    let Minutes = '';

                    if(this.ex00 == 1){ Hours = Hours + '0,' }
                    if(this.ex01 == 1){ Hours = Hours + '1,' }
                    if(this.ex02 == 1){ Hours = Hours + '2,' }
                    if(this.ex03 == 1){ Hours = Hours + '3,' }
                    if(this.ex04 == 1){ Hours = Hours + '4,' }
                    if(this.ex05 == 1){ Hours = Hours + '5,' }
                    if(this.ex06 == 1){ Hours = Hours + '6,' }
                    if(this.ex07 == 1){ Hours = Hours + '7,' }
                    if(this.ex08 == 1){ Hours = Hours + '8,' }
                    if(this.ex09 == 1){ Hours = Hours + '9,' }
                    if(this.ex10 == 1){ Hours = Hours + '10,' }
                    if(this.ex11 == 1){ Hours = Hours + '11,' }
                    if(this.ex12 == 1){ Hours = Hours + '12,' }
                    if(this.ex13 == 1){ Hours = Hours + '13,' }
                    if(this.ex14 == 1){ Hours = Hours + '14,' }
                    if(this.ex15 == 1){ Hours = Hours + '15,' }
                    if(this.ex16 == 1){ Hours = Hours + '16,' }
                    if(this.ex17 == 1){ Hours = Hours + '17,' }
                    if(this.ex18 == 1){ Hours = Hours + '18,' }
                    if(this.ex19 == 1){ Hours = Hours + '19,' }
                    if(this.ex20 == 1){ Hours = Hours + '20,' }
                    if(this.ex21 == 1){ Hours = Hours + '21,' }
                    if(this.ex22 == 1){ Hours = Hours + '22,' }
                    if(this.ex23 == 1){ Hours = Hours + '23,' }

                    Hours = Hours.substring(0,Hours.length-1);
                    //console.log(Hours)

                    if(this.exm00 == 1){ Minutes = '0' }
                    if(this.exm05 == 1){ Minutes = '5' }
                    if(this.exm10 == 1){ Minutes = '10' }
                    if(this.exm15 == 1){ Minutes = '15' }
                    if(this.exm20 == 1){ Minutes = '20' }
                    if(this.exm25 == 1){ Minutes = '25' }
                    if(this.exm30 == 1){ Minutes = '30' }
                    if(this.exm35 == 1){ Minutes = '35' }
                    if(this.exm40 == 1){ Minutes = '40' }
                    if(this.exm45 == 1){ Minutes = '45' }
                    if(this.exm50 == 1){ Minutes = '50' }
                    if(this.exm55 == 1){ Minutes = '55' }

                    //console.log(Minutes + ' ' + Hours + ' * * *')
                    //schedule, in 20 minutes
                    //repeatEvery, 3 minutes
                    let CronType = '';
                    let CronTime = '';
                    let CronWaitTime = 'in ' + this.waitTime + ' minutes';

                    if(this.schoolURL.trim().substring(this.schoolURL.length-1) == '/')
                    {
                        this.schoolURL = this.schoolURL.trim().substring(0,this.schoolURL.length-1);
                    }

                    if(this.schoolAmoURL.trim().substring(this.schoolAmoURL.length-1) == '/')
                    {
                        this.schoolAmoURL = this.schoolAmoURL.trim().substring(0,this.schoolAmoURL.length-1);
                    }

                    if(this.jobTypeSelect == 'Регулярная задача'){
                        CronType = 'repeatEvery';
                        CronTime = Minutes + ' ' + Hours + ' * * *';
                    }
                    else {
                        CronType = 'schedule';
                        CronTime = 'in ' + this.jobRepeatEvery + ' minutes';
                    }

                    axios({
                        method: 'POST',
                        headers: {
                            'api_key': store.state.CurrentApiKey,
                            'jobUUID': this.uuid,
                            'strGlobalProjectName': store.state.GlobalProjectName.trim(),
                            'strCronProjectUUID': store.state.CronProjectUUID.trim(),
                            'schoolColumns': Buffer.from(JSON.stringify(''), 'utf-8').toString('base64'),
                            'dbTableNameSql': 'tbl_amocrmleads_' + this.dbTableName,
                            'Content-Type': 'application/json'
                        },
                        url: store.state.urlCJ + 'pgCreateNewTableForTask',
                        data: toDBColumn

                    })
                    .then((response) => {
                        console.log(response.data)
                        if(response.data == 'OK')
                        {
                            console.log(response.data);

                            axios({
                                method: 'POST',
                                headers: {
                                    'api_key': store.state.CurrentApiKey,
                                    'jobUUID': this.uuid,
                                    'jobRepeatEvery': CronTime,
                                    'jobType': CronType,
                                    'jobName': 'AmoCrm to PG Leads Working',
                                    'sheetId': this.schoolAmoURL,
                                    'spreadsheetId': 'tbl_amocrmleads_' + this.dbTableName,
                                    'rangeAdd': this.rangeAdd,
                                    'dateStart': this.dateStart,
                                    'waitTime': CronWaitTime,
                                    'schoolURL': this.schoolURL,
                                    'schoolApiKey': this.schoolApiKey,
                                    'strJobNameAbout': Buffer.from('PG Сделки из AmoCrm в Базу Данных', 'utf-8').toString('base64'),
                                    'schoolColumns': Buffer.from(JSON.stringify(''), 'utf-8').toString('base64'),
                                    'schoolColumnsOut': Buffer.from('', 'utf-8').toString('base64'),
                                    'clearScheet': this.ClearScheet,
                                    'useFilterRules': this.selectParams,
                                    'filterRules': Buffer.from('', 'utf-8').toString('base64'),
                                    'strJobUserComment': Buffer.from(this.strJobUserComment, 'utf-8').toString('base64'),
                                    'strGlobalProjectName': store.state.GlobalProjectName.trim(),
                                    'strCronProjectUUID': store.state.CronProjectUUID.trim(),
                                    'intLitePro': 1,
                                    'Content-Type': 'application/json'
                                },
                                url: store.state.urlCJ + 'addJob',
                                data: toDBColumn
                            })
                            .then((response) => {
                                //console.log(response.data)
                                if(response.data == 'OK')
                                {
                                    this.alert1 = true;
                                    this.alert2 = false;
                                    this.alert3 = false;
                                    this.alert4 = false;
                                    this.alert5 = false;
                                    this.alert6 = false;
                                    this.$router.push('/mainScreen/cronJobList')
                                }
                            })
                            .catch((response) => {
                                //console.log(response)
                                let err = Object.assign({}, response)
                                console.log('error', err.response);
                                                
                                if(err.response.status == '488')
                                {
                                    this.$router.push('/')
                                }
                            });
                        }
                        else
                        {
                            if(response.data == 'Found System Job from Running list')
                            {
                                this.alert1 = false;
                                this.alert2 = true;
                                this.alert3 = false;
                                this.alert4 = false;
                                this.alert5 = false;
                                this.alert6 = false;
                            }
                            else
                            {
                                this.alert1 = false;
                                this.alert2 = false;
                                this.alert3 = true;
                                this.alert4 = false;
                                this.alert5 = false;
                                this.alert6 = false;
                            }
                        }
                    })
                    .catch((response) => {
                        let ErrorMessage = '';
                        this.alert1 = false;
                        this.alert2 = false;
                        this.alert3 = false;
                        this.alert4 = false;
                        this.alert5 = false;
                        this.alert6 = false;

                        ErrorMessage = ErrorMessage + 'Ошибка! Таблица не создана!'
                        this.alertMessage = ErrorMessage;
                        this.alert2 = true;
                        //console.log(response)
                        let err = Object.assign({}, response)
                        console.log('error', err.response);
                                        
                        if(err.response.status == '488')
                        {
                            this.$router.push('/')
                        }
                    });
                    //console.log('1')
                }
                else
                {
                    this.alert1 = false;
                    this.alert2 = false;
                    this.alert3 = false;
                    this.alert4 = true;
                    this.alert5 = false;
                    this.alert6 = false;
                }
            }
        },
        async getAmoCustomFields () {
            let data = []

            this.alert5 = false;
            this.alert6 = false;

            if(this.schoolAmoURL.trim() == '' && this.existingKeyData.length == 0) { 
                this.alert5 = false;
                this.alert6 = false;
                
                this.alertMessage = 'ОШИБКА! API ключ не найден!';
                this.alert5 = true;

                return
            }

            if(this.schoolAmoURL.trim().substring(this.schoolAmoURL.length-1) == '/')
            {
                this.schoolAmoURL = this.schoolAmoURL.trim().substring(0,this.schoolAmoURL.length-1);
            }
           
            axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.CurrentApiKey,
                    'schoolAmoURL': this.schoolAmoURL,
                    'strCronProjectUUID': store.state.CronProjectUUID.trim(),
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getAmoLeadsCustomFields',
                data: data

            })
            .then((response) => {
                //console.log(response.data)
                this.alert6 = false
                this.alert5 = false
                let CustomColArray = response.data
                let sSelectedColumn = ''

                for(let key=0; key < CustomColArray.length; key++) {  
                    sSelectedColumn = CustomColArray[key].name + '|' + CustomColArray[key].id + '|CF'
                    //console.log(sSelectedColumn)
                    let findIdColumn = this.gkcolumns.find(column => column.Name.trim() == sSelectedColumn.trim())
                    if(findIdColumn === undefined){
                        this.gkcolumns.push({ ID: this.gkcolumns.length, Name: sSelectedColumn });
                        
                    }
                    //this.fromGcColumns.push({ id: key, text: sSelectedColumn });
                }

                if(this.alert6 == false){
                    this.alertMessage = 'Загрузка полей выполнена!';
                    this.alert6 = true;
                }
                
            })
            .catch((response) => {
                this.alert6 = false
                this.alert5 = false
                if(this.alert5 == false){
                    this.alertMessage = 'Ошибка! Загрузки полей!';
                    this.alert5 = true;
                }
                //console.log(response)
                let err = Object.assign({}, response)
                console.log('error', err.response);
                                
                if(err.response.status == '488')
                {
                    this.$router.push('/')
                }
            });
        },
        async generateAmoApiKey () {
            let data = []

            if(this.schoolAmoURL.trim().substring(this.schoolAmoURL.length-1) == '/')
            {
                this.schoolAmoURL = this.schoolAmoURL.trim().substring(0,this.schoolAmoURL.length-1);
            }
           
            axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.CurrentApiKey,
                    'amo_secret': this.schoolAmoSecretKey,
                    'amo_id': this.schoolAmoIdIntegration,
                    'amo_code': this.schoolAmoCode,
                    'schoolAmoURL': this.schoolAmoURL,
                    'strCronProjectUUID': store.state.CronProjectUUID.trim(),
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'generateAmoCrmToken',
                data: data

            })
            .then((response) => {
                //console.log(response.data)

                if(response.data == 'OK'){
                    let ErrorMessage = '';
                    this.alertApiError = false;
                    this.alertApiSuccess = false;
                    
                    ErrorMessage = ErrorMessage + 'API ключ сгенерирован!'
                    this.alertMessage = ErrorMessage;
                    this.alertApiSuccess = true;
                }
            })
            .catch((response) => {
                let ErrorMessage = '';
                this.alertApiError = false;
                this.alertApiSuccess = false;
                
                ErrorMessage = ErrorMessage + 'ОШИБКА! API ключ не сгенерирован! Проверьте данные которые вы ввели для генерации!'
                this.alertMessage = ErrorMessage;
                this.alertApiError = true;
                //console.log(response)
                let err = Object.assign({}, response)
                console.log('error', err.response);
                                
                if(err.response.status == '488')
                {
                    this.$router.push('/')
                }
            });
        },
    },

    watch: {
        selectCondition(){
            //console.log(this.selectCondition)
            if(this.selectCondition == '='){
                this.SpecialDisabled = true;
            }
            else
            {
                this.SpecialDisabled = false;
            }
        },
        jobTypeSelect(){
            //console.log(this.jobTypeSelect);
            if(this.jobTypeSelect == 'Разовая задача')
            {
                this.jobRepeatEveryDisabled = true;
                this.jobScheduledDisabled = false;
            }
            else
            {
                this.jobRepeatEveryDisabled = false;
                this.jobScheduledDisabled = true;
            }
        },
        exm00(){
            if(this.exm00 == 1){
                this.exm05=0;
                this.exm10=0;
                this.exm15=0;
                this.exm20=0;
                this.exm25=0;
                this.exm30=0;
                this.exm35=0;
                this.exm40=0;
                this.exm45=0;
                this.exm50=0;
                this.exm55=0;
            }
        },
        exm05(){
            if(this.exm05 == 1){
            this.exm00=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm10(){
            if(this.exm10 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm15(){
            if(this.exm15 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm20(){
            if(this.exm20 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm25(){
            if(this.exm25 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm30(){
            if(this.exm30 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm35(){
            if(this.exm35 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm40(){
            if(this.exm40 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm45(){
            if(this.exm45 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm50(){
            if(this.exm50 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm55=0;
            }
        },
        exm55(){
            if(this.exm55 == 1){
            this.exm00 = 0;
            this.exm05 = 0;
            this.exm10 = 0;
            this.exm15 = 0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            }
        }
        
    },
}
</script>