import axios from 'axios'
//import date from 'date-and-time';
//import router from '../router/router.js'
import store from './store.js'
import router from '../router/router.js'
import * as sha512 from 'js-sha512'
const { Buffer } = require("buffer");

export default {
    state: {
        GenerateAuthUrl: [],
        GoogleAuthACTbyEmail: [],
        GoogleAppSettings: [],
        GoogleSheetsLists: [],
        GoogleEmailAccountByProjUUID: []
    },
    mutations: {
        getGoogleGenerateAuthUrl (state, payload) {
            state.GenerateAuthUrl = payload
        },
        getGoogleAuthACTbyEmail (state, payload) {
            state.GoogleAuthACTbyEmail = payload
        },
        getGoogleAppSettings (state, payload) {
            state.GoogleAppSettings = payload
        },
        getGoogleSheetsLists (state, payload) {
            state.GoogleSheetsLists = payload
        },
        getGoogleEmailAccountByProjUUID (state, payload) {
            state.GoogleEmailAccountByProjUUID = payload
        },
    },
    actions: {
        async getGoogleGenerateAuthUrl ({commit}, { setApi, setTaskUUID, setProjectUUID }) {
            //console.log('strCronProjectUUID');
            commit('clearError')
            commit('setLoading2', true)

            //const dtStart = date.format(dtStart, 'YYYY-MM-DD');
            //const dtEnd = date.format(dtEnd, 'YYYY-MM-DD');
            await axios({
                method: 'POST',
                headers: {
                'api_key': setApi,
                'TaskUUID': setTaskUUID,
                'strCronProjectUUID': setProjectUUID,
                'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getGoogleGenerateAuthUrl',
                data: []
            })
            .then(async (response) => {
                
                //console.log(response.data)

                if (response.data && response.data.authUrl) {
                    const authUrl = response.data.authUrl;

                    const authURLout = [
                        {
                            authURL: authUrl
                        }
                    ]
                
                    commit('getGoogleGenerateAuthUrl', authURLout)
                    commit('setLoading2', false)
                }
                else
                {
                    commit('getGoogleGenerateAuthUrl', [])
                    commit('setLoading2', false)
                }
            })
            .catch((response) => {
                //console.log(response)
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('getGoogleGenerateAuthUrl', [])
                commit('setLoading2', false)
                commit('setError', response)
                if(err.response.status == '488')
                {
                    router.push('/')
                }
                
                
            });

        },
        async getGoogleAuthACTbyEmail ({commit}, { setApi, setGmail }) {
            //console.log('strCronProjectUUID');
            commit('clearError')
            commit('setLoading2', true)

            // Текущая дата и время
            let now = new Date();

            let shacd1 = sha512.sha512(formatDateCD(now));
            let shacd2 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 1)));
            let shacd3 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 2)));
            let shacd4 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 3)));
            let shacd5 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 4)));

            let dataTr = 
                    [ { 
                        "cd1":shacd1,
                        "cd2":shacd2,
                        "cd3":shacd3,
                        "cd4":shacd4,
                        "cd5":shacd5
                    } ]

            await axios({
            method: 'POST',
            headers: {
                'api_key': setApi,
                'G_Mail': setGmail,
                'Content-Type': 'application/json'
            },
                url: store.state.urlCJ + 'getGoogleAuthByMail',
                data: dataTr
            })
            .then(async (response) => {
                
                //console.log(response.data)

                if (response.data && response.data.authenticated) {
                    const currentDate = new Date(); // Используем текущую дату

                    let decryptedACT = decrypt(response.data.act.trim(), currentDate);

                    const oauthToken = [
                        {
                            oauthToken: decryptedACT.trim()
                        }
                    ]
                
                    commit('getGoogleAuthACTbyEmail', oauthToken)
                    commit('setLoading2', false)
                }
                else
                {
                    commit('getGoogleAuthACTbyEmail', [])
                    commit('setLoading2', false)
                }
            })
            .catch((response) => {
                //console.log(response)
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('getGoogleAuthACTbyEmail', [])
                commit('setLoading2', false)
                commit('setError', response)
                if(err.response.status == '488')
                {
                    router.push('/')
                }
                
                
            });

        },
        async getGoogleAppSettings ({commit}, { setApi }) {
            //console.log('strCronProjectUUID');
            commit('clearError')
            commit('setLoading2', true)

            // Текущая дата и время
            let now = new Date();

            let shacd1 = sha512.sha512(formatDateCD(now));
            let shacd2 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 1)));
            let shacd3 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 2)));
            let shacd4 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 3)));
            let shacd5 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 4)));

            let dataTr = 
                    [ { 
                        "cd1":shacd1,
                        "cd2":shacd2,
                        "cd3":shacd3,
                        "cd4":shacd4,
                        "cd5":shacd5
                    } ]

            await axios({
                method: 'POST',
                headers: {
                'api_key': setApi,
                'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getGoogleAppSettings',
                data: dataTr
            })
            .then(async (response) => {
                
                //console.log(response.data)

                if(response.data.id !== undefined && response.data.gApi !== undefined)
                {
                    const currentDate = new Date(); // Используем текущую дату
    
                    let decryptedid = decrypt(response.data.id.trim(), currentDate);
                    let decryptedgApi = decrypt(response.data.gApi.trim(), currentDate);
        

                    const AppSettings = [
                        {
                            id: decryptedid.trim(),
                            gApi: decryptedgApi.trim()
                        }
                    ]

                    //console.log(AppSettings)
                
                    commit('getGoogleAppSettings', AppSettings)
                    commit('setLoading2', false)
                }
                else
                {
                    commit('getGoogleAppSettings', [])
                    commit('setLoading2', false)
                }
            })
            .catch((response) => {
                console.log(response)
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('getGoogleAppSettings', [])
                commit('setLoading2', false)
                commit('setError', response)
                if(err.response.status == '488')
                {
                    router.push('/')
                }
                
                
            });

        },
        async getGoogleSheetsLists ({commit}, { setApi, setSpreadsheetId, setGmail }) {
            //console.log('strCronProjectUUID');
            commit('clearError')
            commit('setLoading2', true)

            await axios({
                method: 'POST',
                headers: {
                    'api_key': setApi,
                    'spreadsheetId': setSpreadsheetId,
                    'gmail': setGmail,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getGoogleSheetsLists',
                data: []
            })
            .then(async (response) => {
                
                //console.log(response.data)

                if (response.data && response.data.sheets && Array.isArray(response.data.sheets)) {
                    //console.log(response.data.sheets)
                    let sheetsLists = response.data.sheets;
                
                    commit('getGoogleSheetsLists', sheetsLists)
                    commit('setLoading2', false)
                } else {
                    commit('getGoogleSheetsLists', [])
                    commit('setLoading2', false)
                }
            })
            .catch((response) => {
                //console.log(response)
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('getGoogleSheetsLists', [])
                commit('setLoading2', false)
                commit('setError', response)
                if(err.response.status == '488')
                {
                    router.push('/')
                }
                
                
            });

        },
        async getGoogleEmailAccountByProjUUID ({commit}, { setApi, setProjectUUID }) {
            //console.log('strCronProjectUUID');
            commit('clearError')
            commit('setLoading2', true)

            await axios({
                method: 'POST',
                headers: {
                    'api_key': setApi,
                    'strCronProjectUUID': setProjectUUID,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getGoogleEmailAccountByProjUUID',
                data: []
            })
            .then(async (response) => {
                
                //console.log(response.data)

                if (response.data) {
                    //console.log(response.data.sheets)
                    let GoogleAcc = response.data;
                
                    commit('getGoogleEmailAccountByProjUUID', GoogleAcc)
                    commit('setLoading2', false)
                } else {
                    commit('getGoogleEmailAccountByProjUUID', [])
                    commit('setLoading2', false)
                }
            })
            .catch((response) => {
                //console.log(response)
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('getGoogleEmailAccountByProjUUID', [])
                commit('setLoading2', false)
                commit('setError', response)
                if(err.response.status == '488')
                {
                    router.push('/')
                }
                
                
            });

        }
        
        
    },
    
    getters: {
        GenerateAuthUrl (state) {
            return state.GenerateAuthUrl
        },
        GoogleAuthACTbyEmail (state) {
            return state.GoogleAuthACTbyEmail
        },
        GoogleAppSettings (state) {
            return state.GoogleAppSettings
        },
        GoogleSheetsLists (state) {
            return state.GoogleSheetsLists
        },
        GoogleEmailAccountByProjUUID (state) {
            return state.GoogleEmailAccountByProjUUID
        },
    }
}

function formatDateCD(date) {
    return date.getUTCFullYear() + '-' + 
    String(date.getUTCMonth() + 1).padStart(2, '0') + '-' +
    String(date.getUTCDate()).padStart(2, '0') + ' ' +
    String(date.getUTCHours()).padStart(2, '0') + ':' +
    String(date.getUTCMinutes()).padStart(2, '0');
}

function getDateMinusMinutes(date, minutes) {
    return new Date(date.getTime() - minutes * 60000);
}

function generateKey(date = new Date()) {
    const months = ['ЯНВАРЯ', 'ФЕВРАЛЯ', 'МАРТА', 'АПРЕЛЯ', 'МАЯ', 'ИЮНЯ', 
            'ИЮЛЯ', 'АВГУСТА', 'СЕНТЯБРЯ', 'ОКТЯБРЯ', 'НОЯБРЯ', 'ДЕКАБРЯ'];
    const days = ['ВОСКРЕСЕНЬЕ', 'ПОНЕДЕЛЬНИК', 'ВТОРНИК', 'СРЕДА', 'ЧЕТВЕРГ', 'ПЯТНИЦА', 'СУББОТА'];
    
    // Преобразуем входные данные в UTC Date объект
    let utcDate;
    if (typeof date === 'string') {
        utcDate = new Date(date);
    } else if (date instanceof Date) {
        utcDate = new Date(date.getTime());
    } else {
        utcDate = new Date();
    }
    
    // Устанавливаем время в UTC
    utcDate.setTime(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
    
    const day = String(utcDate.getUTCDate()).padStart(2, '0');
    const month = months[utcDate.getUTCMonth()];
    const year = utcDate.getUTCFullYear();
    const weekday = days[utcDate.getUTCDay()];
    
    return `${day}-${month}-${year}-${weekday}`;
}

/* function encrypt(message, date = new Date()) {
    const key = this.generateKey(date);
    let encryptedMessage = '';
    let keyHash = sha512(key);
    
    for (let i = 0; i < message.length; i++) {
        let charCode = message.charCodeAt(i) ^ keyHash.charCodeAt(i % keyHash.length);
        encryptedMessage += String.fromCharCode(charCode);
    }
    
    return Buffer.from(encryptedMessage).toString('base64');
} */

function decrypt(encryptedMessage, date = new Date()) {
    const key = generateKey(date);
    let message = Buffer.from(encryptedMessage, 'base64').toString();
    let decryptedMessage = '';
    let keyHash = sha512(key);
    
    for (let i = 0; i < message.length; i++) {
        let charCode = message.charCodeAt(i) ^ keyHash.charCodeAt(i % keyHash.length);
        decryptedMessage += String.fromCharCode(charCode);
    }
    
    return decryptedMessage;
}