<template>
  <v-card class="mx-auto px-6 py-8" max-width="90%" flat>
    <v-card>
      <v-card-title class="bg-amber-lighten-1">
        <h2 class="me-4 font-weight-light">
          Входящие WebHook запросы
        </h2>
      </v-card-title>
      <v-card-text>
        Управление запросами из других подсистем
      </v-card-text>
    </v-card>
    <br>
    <v-card class="mx-auto px-4 py-4" outlined>
      <v-tabs v-model="tab">
          <v-tab>WebHook запросы</v-tab>
          <v-tab>Создать таблицу для WebHook</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
            <v-container class="mt-4">
              <v-row justify="space-between" align="center">
                <v-col cols="auto">
                  <v-tooltip bottom :disabled="!isButtonDisabledPro">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-on="on">
                          <v-btn
                            color="primary"
                            @click="openAddDialog"
                            class="mr-2"
                            v-bind="attrs"
                            :disabled="isButtonDisabledPro"
                          >
                            Добавить
                          </v-btn>
                        </span>
                      </template>
                      {{ tooltipTextPro }}
                    </v-tooltip>
                    <v-tooltip bottom :disabled="!isButtonDisabledPro">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-on="on">
                          <v-btn
                            color="warning"
                            @click="editWebHook"
                            class="mr-2"
                            :disabled="!webHookId||isButtonDisabledPro"
                            v-bind="attrs"
                          >
                            Изменить
                          </v-btn>
                      </span>
                    </template>
                    {{ tooltipTextPro }}
                  </v-tooltip>
                  <v-tooltip bottom :disabled="!isButtonDisabledPro">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-on="on">
                        <v-btn
                          color="error"
                          @click="confirmDelete"
                          class="mr-2"
                          :disabled="!webHookId||isButtonDisabledPro"
                          v-bind="attrs"
                        >
                          Удалить
                        </v-btn>
                      </span>
                    </template>
                    {{ tooltipTextPro }}
                  </v-tooltip>
                  <v-tooltip bottom :disabled="!isButtonDisabledPro">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-on="on">
                        <v-btn
                          color="info"
                          @click="copySelectedWebHookLink"
                          class="mr-2"
                          :disabled="!webHookId||isButtonDisabledPro"
                          v-bind="attrs"
                        >
                          Скопировать ссылку
                        </v-btn>
                      </span>
                    </template>
                    {{ tooltipTextPro }}
                  </v-tooltip>
                  <v-tooltip bottom :disabled="!isButtonDisabledPro">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-on="on">
                        <v-btn
                          color="secondary"
                          @click="refreshWebHookList"
                          :disabled="isButtonDisabledPro"
                          v-bind="attrs"
                        >
                          Обновить список
                        </v-btn>
                      </span>
                    </template>
                    {{ tooltipTextPro }}
                  </v-tooltip>
                </v-col>
              </v-row>
            
              <v-text-field
                v-model="search"
                label="Поиск"
                single-line
                hide-details
              ></v-text-field>
              
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">UUID</th>
                      <th class="text-left">Таблица</th>
                      <th class="text-left">Комментарий</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr 
                      v-for="item in paginatedItems" 
                      :key="item.uuid"
                      @click="selectWebhookItem(item.uuid)"
                      :class="{ 'selected-row': webHookId === item.uuid }"
                      style="cursor: pointer;"
                    >
                      <td>{{ item.uuid }}</td>
                      <td>{{ item.strTableName }}</td>
                      <td>{{ item.strComment }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              
              <div class="text-center pt-2">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  @input="updatePage"
                ></v-pagination>
              </div>
            </v-container>
          </v-tab-item>

          <v-tab-item>
            <v-container class="mt-4">
              <v-text-field
                  class="mb-4"
                  v-model="dbTableName"
                  prepend-icon="mdi-database-check-outline"
                  label="Название таблицы в БД PostgreSQL (в название будет автоматически добавлено tbl_wh_)"
                  :error-messages="dbTableNameErrors"
                  required
                  @input="$v.dbTableName.$touch()"
                  @blur="$v.dbTableName.$touch()"
              ></v-text-field>
              <v-card class="px-6 py-8" max-width="70%" flat>
              <DxDataGrid
                  id="gridContainer"
                  :data-source="pgcolumns"
                  :show-borders="true"
                  :selected-row-keys="selectedItemKeys"
                  @selection-changed="selectionChanged"
                  @row-updating="onRowUpdating"
                  :column-auto-width="true"
                  >
                  <DxEditing
                      :allow-updating="true"
                      :allow-adding="false"
                      :allow-deleting="true"
                      mode="cell"
                  />
                  <DxPaging :enabled="false"/>
                  
                  <DxColumn
                      data-field="columnName"
                      caption="Наименование колонки"
                      :allow-sorting="false"
                  />
                  <DxColumn
                      data-field="columnType"
                      caption="Тип колонки"
                      :allow-sorting="false"
                  >
                      <DxLookup
                          :data-source="columntypes"
                          value-expr="ID"
                          display-expr="Name"
                      />
                  </DxColumn>
                  <DxColumn
                      data-field="columnLeng"
                      caption="Длина"
                      :allow-sorting="false"
                  />
                  <!-- <DxColumn
                      data-field="columnGKName"
                      caption="Имя в ГК"
                      :allow-sorting="false"
                  >
                      <DxLookup
                          :data-source="gkcolumns"
                          value-expr="ID"
                          display-expr="Name"
                      />
                  </DxColumn> -->
                  <DxToolbar>
                      <!-- <DxItem location="after">
                          <template #default>
                              <DxButton
                                  @click="addRecords()"
                                  icon="add"
                                  text="Добавить новую запись"
                              />
                          </template>
                      </DxItem> -->
                      
                  </DxToolbar>
              </DxDataGrid>
              </v-card>
              <v-btn
                  color="blue-grey"
                  class="ma-2 white--text mt-4"
                  @click="addRecords()"
                  >
                  Добавить колонку
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  class="ma-2 white--text mt-4"
                  @click="createNewTable()"
                  >
                  Создать таблицу
              </v-btn>
              <div>
                  <v-alert dense  text   type="error"  dismissible v-model="alert5" class="pa-1 ma-1">
                              {{ alertMessage }}
                  </v-alert>
                  <v-alert dense  text   type="success"  dismissible v-model="alert6" class="pa-1 ma-1">
                              {{ alertMessage }}
                  </v-alert>
              </div>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
    </v-card>

    <v-dialog v-model="showErrorColumnDialog" max-width="290">
        <v-card>
            <v-card-title class="headline">Внимание</v-card-title>
            <v-card-text>{{ errorColumnMessage }}</v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeErrorColumnDialog">
                Закрыть
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- Диалог подтверждения удаления -->
    <v-dialog v-model="deleteDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">Подтверждение удаления</v-card-title>
        <v-card-text>
          Желаете удалить запись?<br>
          ИД: {{ selectedWebHook ? selectedWebHook.uuid : '' }}<br>
          Таблица: {{ selectedWebHook ? selectedWebHook.strTableName : '' }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteDialog = false">Нет</v-btn>
          <v-btn color="red darken-1" text @click="deleteWebHook">Да</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Диалоговое окно для добавления WebHook -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.uuid"
                  label="UUID"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                    :items="availableTables"
                    v-model="selectedTableName"
                    label="Выберите таблицу"
                    :filter="customFilter"
                    clearable
                    dense
                    hide-details
                    auto-select-first
                    :menu-props="{ maxHeight: '400px' }"
                    @change="onTableSelect"
                    class="mt-4"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>{{ item }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.comment"
                  label="Комментарий"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.generatedLink"
                  label="Сгенерированная ссылка"
                  readonly
                  append-icon="mdi-content-copy"
                  @click:append="copyToClipboard"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Отмена</v-btn>
          <v-btn color="blue darken-1" text @click="saveWebHook">{{ saveButtonText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Snackbar для отображения уведомлений -->
    <v-snackbar v-model="snackbar" :timeout="2000" :color="snackbarColor">
      {{ snackbarText }}
    </v-snackbar>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { v4 as uuidv4 } from 'uuid';
import store from '../store/store.js';
import * as sha512 from 'js-sha512'
import { required } from 'vuelidate/lib/validators'
import { Buffer } from 'buffer'; 

import axios from 'axios'

import {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        //DxSelection,
        DxLookup,
        DxToolbar,
        //DxItem,
    } from 'devextreme-vue/data-grid';

export default {
  mixins: [validationMixin],

  validations: {
      dbTableName: { required }
  },
  components: {
      DxDataGrid,
      DxColumn,
      DxPaging,
      DxEditing,
      //DxSelection,
      DxLookup,
      //DxButton,
      DxToolbar,
      //DxItem,
  },


  data() {
    return {
      availableTables: [],
      webhookItems: [],
      webHookId: '',
      search: '',
      dialog: false,
      editedItem: {
        uuid: '',
        table: '',
        comment: '',
        generatedLink: '',
        apiKey: ''
      },
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
      selectedTableName: null,
      selectetTableColumns: null,
      editMode: false,
      dialogTitle: 'Добавить WebHook',
      saveButtonText: 'Сохранить',
      deleteDialog: false,
      selectedWebHook: null,
      page: 1,
      itemsPerPage: 25,
      tab: null,
      pgcolumns: [
          { ID: 0, columnName: 'created_at',  columnType: 6, columnLeng: 0, columnGKName: 1, altertbl: 0 }
      ],
      gkcolumns: [
          { ID: 0, Name: 'NULL' },
          { ID: 1, Name: 'created_at' }
      ],
      columntypes: [
          { ID: 0, Name: 'Строка' },
          { ID: 1, Name: 'Целое число' },
          { ID: 2, Name: 'Сумма' },
          { ID: 3, Name: 'Дата' },
          { ID: 4, Name: 'Время' },
          { ID: 5, Name: 'Текст' },
          { ID: 6, Name: 'АвтоДата' }
      ],
      selectedItemKeys: [],
      alert5: false,
      alert6: false,
      alertMessage: '',
      dbTableName: '',
      showErrorColumnDialog: false,
      errorColumnMessage: ''
    };
  },
  computed: {
    dbTableNameErrors () {
      const errors = []
      if (!this.$v.dbTableName.$dirty) return errors
          !this.$v.dbTableName.required && errors.push('Обязательное поле. Название таблицы для отнрузки данных в PostgreSQL.')
      return errors
    },
    filteredItems() {
      return this.webhookItems.filter(item => {
        return Object.values(item).some(
          value => value.toString().toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    pageCount() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage)
    },
    paginatedItems() {
      const start = (this.page - 1) * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.filteredItems.slice(start, end)
    },
    isButtonDisabledPro() {
      const hasProAccess  = store.getters.ProAccess !== 0;
      const hasUnlimitedAccess  = store.getters.UnlimitedAccess === 1;
      const dateAccess  = store.getters.DateAccess;
      //console.log(dateAccess)
      
      let isDateValid = false;
      if (dateAccess !== null) {
        const currentDate = new Date();
        const accessDate = new Date(dateAccess);
        
        // Сравниваем только год, месяц и день
        isDateValid = 
          accessDate.getFullYear() > currentDate.getFullYear() || 
          (accessDate.getFullYear() === currentDate.getFullYear() && 
          (accessDate.getMonth() > currentDate.getMonth() || 
            (accessDate.getMonth() === currentDate.getMonth() && 
            accessDate.getDate() >= currentDate.getDate())));
        
        //console.log(isDateValid)

        //console.log(`${accessDate.getFullYear()}-${String(accessDate.getMonth() + 1).padStart(2, '0')}-${String(accessDate.getDate()).padStart(2, '0')}`);
        //console.log(`${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`);
      }

      // Доступ открыт, если есть Lite доступ И (есть безлимитный доступ ИЛИ дата действительна)
      const hasAccess = hasProAccess && (hasUnlimitedAccess || isDateValid);

      //console.log('hasLiteAccess:', hasLiteAccess);
      //console.log('hasUnlimitedAccess:', hasUnlimitedAccess);
      //console.log('isDateValid:', isDateValid);
      //console.log('hasAccess:', hasAccess);

      return !hasAccess;
    },
    tooltipTextPro() {
      if (store.getters.ProAccess === 0) {
        return 'Функционал доступен на Pro подписке';
      } else if (store.getters.UnlimitedAccess !== 1) {
        const dateAccess = store.getters.DateAccess.toString().substr(0,10);
        if (dateAccess === null || new Date(dateAccess) < new Date()) {
          return 'Срок доступа истек';
        }
      }
      return '';
    }
  },
  mounted() {
    this.loadWebHooksSettings();
    this.$store.dispatch('loadpgTables');
  },
  methods: {
    async createNewTable () {
      let CanGoTableCreate = true;
      if(this.dbTableName == ''){ 
          this.alert6 = false;
          this.alertMessage = 'Не введено Название таблицы; ';
          this.alert5 = true;

          CanGoTableCreate = false
      }

      const validColumnNameRegex = /^[a-zA-Z0-9_]+$/;
      if (!validColumnNameRegex.test(this.dbTableName)) {
          this.alert6 = false;
          this.alertMessage = 'Название таблицы может содержать только латинские буквы, цифры и знак подчеркивания; ';
          this.alert5 = true;

          CanGoTableCreate = false
      }

      if(CanGoTableCreate === true){
        await axios({
          method: 'POST',
          headers: {
              'api_key': store.state.CurrentApiKey,
              'jobUUID': this.uuid,
              'strGlobalProjectName': store.state.GlobalProjectName.trim(),
              'dbTableNameSql': 'tbl_wh_' + this.dbTableName,
              'Content-Type': 'application/json'
          },
          url: store.state.urlCJ + 'pgCheckTableExists',
          data: []
        })
        .then((response) => {
          //console.log(response.data)
          if(response.data == 'YES')
          {
            this.alert6 = false;
            this.alertMessage = 'Такая таблица уже существует; ';
            this.alert5 = true;

            CanGoTableCreate = false
          }

          if(CanGoTableCreate === true){
            let TmpColumnData = [];
            for(let i=0;i<this.pgcolumns.length;i++){
                //console.log(this.columntypes[this.pgcolumns[i].columnType].Name);
                TmpColumnData.push({ ID: i, columnName: this.pgcolumns[i].columnName,  columnType: this.columntypes[this.pgcolumns[i].columnType].Name, columnLeng: this.pgcolumns[i].columnLeng, columnGKName: this.gkcolumns[this.pgcolumns[i].columnGKName].Name, altertbl: this.pgcolumns[i].altertbl })
            }

            let NewColumnData = TmpColumnData;
            TmpColumnData = [];

            let toDBColumn = [];

            /* toDBColumn['PGWork'] = [];
            toDBColumn['PGcolumns'] = [];
            toDBColumn['GKcolumns'] = [];
            toDBColumn['TYPEcolumns'] = []; */

            toDBColumn.push(NewColumnData);
            toDBColumn.push(this.pgcolumns);
            toDBColumn.push(this.gkcolumns);
            toDBColumn.push(this.columntypes);

            axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.CurrentApiKey,
                    'jobUUID': this.uuid,
                    'strGlobalProjectName': store.state.GlobalProjectName.trim(),
                    'strCronProjectUUID': store.state.CronProjectUUID.trim(),
                    'schoolColumns': Buffer.from(JSON.stringify(''), 'utf-8').toString('base64'),
                    'dbTableNameSql': 'tbl_wh_' + this.dbTableName,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'pgCreateNewTableForTask',
                data: toDBColumn

            })
            .then(async (response) => {
              console.log(response.data)
              if(response.data == 'OK')
              {
                  this.alert5 = false;
                  this.alertMessage = 'Создана таблица: ' + 'tbl_wh_' + this.dbTableName + ', теперь ее можно выбрать в разделе WebHook запросы!';
                  this.alert6 = true;

                  await this.$store.dispatch('loadpgTables');
                  this.availableTables = await this.$store.getters.pgTables.map(table => table.table_name);
              }
            })
            .catch((response) => {
                this.alert5 = false;
                this.alert6 = false;

                this.alertMessage = 'Ошибка! Таблица не создана!';
                this.alert5 = true;
                //console.log(response)
                let err = Object.assign({}, response)
                console.log('error', err.response);
                                
                if(err.response.status == '488')
                {
                    this.$router.push('/')
                }
            });
          }
        })
      }
    },
    addRecords () {
        //console.log(this.pgcolumns.length);
        this.pgcolumns.push({ ID: this.pgcolumns.length, columnName: '',  columnType: 0, columnLeng: 50, columnGKName: 0, altertbl: 0 })
        //console.log(this.pgcolumns);
    },
    selectionChanged (data) {
        this.selectedItemKeys = data.selectedRowKeys;
        //console.log(this.selectedItemKeys)
    },
    closeErrorColumnDialog() {
        this.showErrorColumnDialog = false;
        this.errorColumnMessage = '';
    },
    onRowUpdating(e) {
        if (e.newData.columnType !== undefined && e.newData.columnType !== e.oldData.columnType) {
            // Немедленно обновляем данные
            this.updateColumnLength(e.newData, e.key);
            
            // Обновляем newData, чтобы изменения отразились в гриде
            const updatedRow = this.pgcolumns.find(item => item.ID === e.key);
            if (updatedRow) {
                e.newData.columnLeng = updatedRow.columnLeng;
            }
        }
        if(e.newData.columnName !== undefined && e.newData.columnName !== e.oldData.columnName){
            //console.log(e.oldData.columnName)
            //console.log(e.oldData.altertbl)
            
            // Проверяем формат нового названия колонки
            const validColumnNameRegex = /^[a-zA-Z0-9_]+$/;
            if (!validColumnNameRegex.test(e.newData.columnName)) {
                e.cancel = true;
                
                // Показываем сообщение об ошибке
                this.errorColumnMessage = 'Название колонки может содержать только латинские буквы, цифры и знак подчеркивания';
                this.showErrorColumnDialog = true;
                
                // Восстанавливаем старое значение
                this.$nextTick(() => {
                if (e.component) {
                    e.component.cancelEditData();
                }
                });
            }
            if(e.oldData.columnName == 'created_at')
            {
                // Показываем сообщение об ошибке
                this.errorColumnMessage = 'Изменить название этой колонки нельзя!';
                this.showErrorColumnDialog = true;
                
                // Восстанавливаем старое значение
                this.$nextTick(() => {
                    const rowIndex = this.pgcolumns.findIndex(row => row.ID === e.key.ID);
                    if (rowIndex !== -1) {
                        //console.log(1)
                        this.$set(this.pgcolumns[rowIndex], 'columnName', 'created_at');
                    }
                });
            }
            
        }
    },
    updateColumnLength(newData, key) {
        let columnLeng;
        switch(newData.columnType) {
            case 0: // Строка
                columnLeng = 250;
                break;
            case 1: // Целое число
            case 5: // Текст
                columnLeng = 0;
                break;
            case 2: // Сумма
                columnLeng = '18.2';
                break;
            case 3: // Дата
            case 4: // Время
                columnLeng = 0;
                break;
            default:
                // Для других типов оставляем текущее значение
                return;
        }
        
        // Найдем обновляемую строку
        const rowToUpdate = this.pgcolumns.find(item => 
            item.ID === key.ID &&
            item.columnName === key.columnName &&
            item.columnGKName === key.columnGKName
        );

        if (rowToUpdate) {
            // Обновляем значение columnLeng
            this.$set(rowToUpdate, 'columnLeng', columnLeng);
            this.$set(rowToUpdate, 'columnType', newData.columnType);
                            
            // Вызываем $forceUpdate для обновления представления
            this.$forceUpdate();
        }
        
        return columnLeng;
    },
    updatePage(page) {
      this.page = page
    },
    customFilter(item, queryText, itemText) {
      const searchText = queryText.toLowerCase();
      let textToSearch;
      
      if (typeof item === 'string') {
        textToSearch = item.toLowerCase();
      } else if (item && item.selectedTableName) {
        textToSearch = item.selectedTableName.toLowerCase();
      } else if (itemText) {
        textToSearch = itemText.toLowerCase();
      } else {
        textToSearch = '';
      }
      
      return textToSearch.includes(searchText);
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.editedItem.generatedLink)
        .then(() => {
          this.showSnackbar('Ссылка скопирована в буфер обмена', 'success');
        })
        .catch(err => {
          console.error('Не удалось скопировать текст: ', err);
          this.showSnackbar('Не удалось скопировать ссылку', 'error');
        });
    },
    showSnackbar(text, color = 'success') {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },
    async loadWebHooksSettings() {
      this.availableTables = await this.$store.getters.pgTables.map(table => table.table_name);
      //console.log(this.availableTables)
      const settingWebhook = {
        setApi: store.state.CurrentApiKey,
        setProjectUUID: store.state.CronProjectUUID.trim()
      };

      this.$store.dispatch('loadwebHooks', settingWebhook)
        .then(() => {
          this.webhookItems = this.$store.getters.webHooks;
        })
        .catch((error) => {
          console.log(error);
          console.log('No App Configuration!');
        });
    },
    selectWebhookItem(UUID) {
      this.webHookId = UUID;
    },
    openAddDialog() {
      this.editMode = false;
      this.dialogTitle = 'Добавить WebHook';
      this.saveButtonText = 'Сохранить';
      this.editedItem = {
        uuid: uuidv4(),
        table: '',
        comment: '',
        generatedLink: '',
        apiKey: ''
      };
      this.selectedTableName = null;
      this.dialog = true;
    },

    editWebHook() {
      if (!this.webHookId) return;
      
      this.editMode = true;
      this.dialogTitle = 'Редактировать WebHook';
      this.saveButtonText = 'Обновить';
      
      const selectedWebHook = this.webhookItems.find(item => item.uuid === this.webHookId);
      if (selectedWebHook) {
        this.editedItem = { 
          uuid: selectedWebHook.uuid,
          table: selectedWebHook.strTableName,
          comment: selectedWebHook.strComment,
          generatedLink: '',
          apiKey: selectedWebHook.strApiKey
        };
        this.selectedTableName = selectedWebHook.strTableName;
        this.regenerateLink();
      }
      
      this.dialog = true;
    },

    regenerateLink() {
      if (this.editedItem.table && this.editedItem.uuid) {
        const settingTableName = {
          strTableName: this.editedItem.table,
        };

        this.$store.dispatch('loadpgColumnsTables', settingTableName)
          .then(() => {
            const pgColumnsTables = this.$store.getters.pgColumnsTables;
            this.selectetTableColumns = pgColumnsTables.map(column => ({
              name: `${column.column_name}`,
              fullName: `${this.editedItem.table}.${column.column_name}`
            }));

            let KeyGen = this.editedItem.apiKey || sha512.sha512_224(this.editedItem.table + this.editedItem.uuid);
            this.editedItem.apiKey = KeyGen;

            let generatedLink = store.state.urlCJ + `incominghook?apiKey=${KeyGen}`;

            const params = this.selectetTableColumns
              .filter(column => column.name !== 'created_at')
              .map(column => `${column.name}={параметр}`)
              .join('&');
            if (params) {
              generatedLink += `&${params}`;
            }

            this.editedItem.generatedLink = generatedLink;
          })
          .catch(error => {
            console.error('Error loading columns for subquery table:', error);
            this.showSnackbar('Ошибка при загрузке колонок таблицы', 'error');
          });
      }
    },

    onTableSelect(selectedTable) {
      if (selectedTable) {
        this.editedItem.table = selectedTable;
        this.regenerateLink();
      } else {
        this.editedItem.table = '';
        this.editedItem.generatedLink = '';
      }
    },

    async saveWebHook() {
      if (this.editMode) {
        await this.updateWebHook();
      } else {
        await this.addWebHook();
      }
    },

    async addWebHook() {
      const settingHookNew = {
        setApi: store.state.CurrentApiKey,
        uuid: this.editedItem.uuid.trim(),
        strTableName: this.editedItem.table.trim(),
        strApiKey: this.editedItem.apiKey.trim(),
        strProjectUUID: store.state.CronProjectUUID.trim(),
        strGlobalProjectName: store.state.GlobalProjectName.trim(),
        strComment: this.editedItem.comment.trim(),
      };

      try {
        await this.$store.dispatch('addwebHooks', settingHookNew);
        this.showSnackbar('WebHook успешно добавлен', 'success');
        this.loadWebHooksSettings();
        this.closeDialog();
      } catch (error) {
        console.error('Ошибка при добавлении WebHook:', error);
        this.showSnackbar('Ошибка при добавлении WebHook', 'error');
      }
    },

    async updateWebHook() {
      const settingHookUpdate = {
        setApi: store.state.CurrentApiKey,
        uuid: this.editedItem.uuid.trim(),
        strApiKey: this.editedItem.apiKey.trim(),
        strTableName: this.editedItem.table.trim(),
        strComment: this.editedItem.comment.trim(),
      };

      try {
        await this.$store.dispatch('updatewebHooks', settingHookUpdate);
        this.showSnackbar('WebHook успешно обновлен', 'success');
        this.loadWebHooksSettings();
        this.closeDialog();
      } catch (error) {
        console.error('Ошибка при обновлении WebHook:', error);
        this.showSnackbar('Ошибка при обновлении WebHook', 'error');
      }
    },

    closeDialog() {
      this.dialog = false;
      this.editMode = false;
      this.$nextTick(() => {
        this.editedItem = {
          uuid: '',
          table: '',
          comment: '',
          generatedLink: '',
          apiKey: ''
        };
        this.selectedTableName = null;
      });
    },
    confirmDelete() {
      this.selectedWebHook = this.webhookItems.find(item => item.uuid === this.webHookId);
      if (this.selectedWebHook) {
        this.deleteDialog = true;
      }
    },

    async deleteWebHook() {
      if (!this.selectedWebHook) return;

      const settingHookDelete = {
        setApi: store.state.CurrentApiKey,
        uuid: this.selectedWebHook.uuid.trim(),
      };

      try {
        await this.$store.dispatch('deletewebHooks', settingHookDelete);
        this.showSnackbar('WebHook успешно удален', 'success');
        this.loadWebHooksSettings();
        this.deleteDialog = false;
        this.webHookId = '';
        this.selectedWebHook = null;
      } catch (error) {
        console.error('Ошибка при удалении WebHook:', error);
        this.showSnackbar('Ошибка при удалении WebHook', 'error');
      }
    },

    copySelectedWebHookLink() {
      const selectedWebHook = this.webhookItems.find(item => item.uuid === this.webHookId);
      if (selectedWebHook) {
        this.regenerateLinkForCopy(selectedWebHook);
      }
    },

    regenerateLinkForCopy(webHook) {
      const settingTableName = {
        strTableName: webHook.strTableName,
      };

      this.$store.dispatch('loadpgColumnsTables', settingTableName)
        .then(() => {
          const pgColumnsTables = this.$store.getters.pgColumnsTables;
          const tableColumns = pgColumnsTables.map(column => ({
            name: `${column.column_name}`,
            fullName: `${webHook.strTableName}.${column.column_name}`
          }));

          let KeyGen = webHook.apiKey || sha512.sha512_224(webHook.strTableName + webHook.uuid);
          webHook.strApiKey = KeyGen;

          let generatedLink = store.state.urlCJ + `incominghook?apiKey=${webHook.strApiKey}`;

          //const params = tableColumns.map(column => `${column.name}={параметр}`).join('&');
          const params = tableColumns
              .filter(column => column.name !== 'created_at')
              .map(column => `${column.name}={параметр}`)
              .join('&');
          if (params) {
            generatedLink += `&${params}`;
          }
          
          navigator.clipboard.writeText(generatedLink)
            .then(() => {
              this.showSnackbar('Ссылка скопирована в буфер обмена', 'success');
            })
            .catch(err => {
              console.error('Не удалось скопировать ссылку: ', err);
              this.showSnackbar('Не удалось скопировать ссылку', 'error');
            });
        })
        .catch(error => {
          console.error('Error loading columns for subquery table:', error);
          this.showSnackbar('Ошибка при загрузке колонок таблицы', 'error');
        });
    },
    refreshWebHookList() {
      this.loadWebHooksSettings();
    },
    
  }
};
</script>

<style scoped>
.selected-row {
  background-color: #e0e0e0;
}
</style>