<template>
    <v-card class="mx-auto px-6 py-8" max-width="80%" flat>
        <v-card>
            <v-card-title class="bg-amber-lighten-1">
            <h2 class="me-4 font-weight-light">
                Системная задача
            </h2>
            </v-card-title>
            <v-card-text>
                Проверка Свободного места на диске БД<br>
                Внимание! Задача может существовать только в одном экземпляре!
            </v-card-text>
        </v-card>
        <br>
        <v-card flat class="mx-auto px-6 py-1">
            
                <form>
                    <v-text-field
                        v-model="uuid"
                        prepend-icon="mdi-identifier"
                        label="ID Задачи"
                        readonly
                    ></v-text-field>
                    <v-text-field
                        v-model="jobRepeatEvery"
                        prepend-icon="mdi-checkbox-marked-circle-plus-outline"
                        :error-messages="nameErrors"
                        label="Частота повтора в минутах"
                        type="number"
                        required
                        @input="$v.jobRepeatEvery.$touch()"
                        @blur="$v.jobRepeatEvery.$touch()"
                    ></v-text-field>
                    <v-text-field
                        v-model="jobPercentDiskSpace"
                        prepend-icon="mdi mdi-percent-outline"
                        label="Процент порога (Например 80%)"
                        type="number"
                        required
                    ></v-text-field>

                    <v-btn
                        class="primary"
                        @click="submit"
                        >
                        запустить задачу
                    </v-btn>
                    
                </form>
           
        </v-card>
        <br>
        <div>
            <v-alert dense  text   type="success"  dismissible v-model="alert1" class="pa-1 ma-1">
                        Системная задача запущена!
            </v-alert>
            <v-alert dense  text   type="error"  dismissible v-model="alert2" class="pa-1 ma-1">
                        У вас уже есть запущенная Системная задача!
            </v-alert>
            <v-alert dense  text   type="error"  dismissible v-model="alert3" class="pa-1 ma-1">
                        Ошибка создания задачи - обратитесь к разработчику!
            </v-alert>
            <v-alert dense  text   type="error"  dismissible v-model="alert4" class="pa-1 ma-1">
                        Не указан параметр - Частота повтора в минутах!
            </v-alert>
        </div>
        
    </v-card>
    
    
  </template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid'; 
import axios from 'axios'
import store from '../store/store.js'

export default {
    mixins: [validationMixin],

    validations: {
        jobRepeatEvery: { required }
    },

    data: () => ({
        show: false,
        uuid: uuidv4(),
        jobRepeatEvery: '',
        jobPercentDiskSpace: 80,
        alert1: false,
        alert2: false,
        alert3: false,
        alert4: false
    }),

    computed: {
      nameErrors () {
        const errors = []
        if (!this.$v.jobRepeatEvery.$dirty) return errors
            !this.$v.jobRepeatEvery.required && errors.push('Обязательное поле. Укажите количество минут для повтора задачи.')
        return errors
      },
    },

    methods: {
      submit () {
        if(this.jobRepeatEvery != '')
        {
            let data = []
            axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.CurrentApiKey,
                    'jobUUID': this.uuid,
                    'jobRepeatEvery': this.jobRepeatEvery + ' minutes',
                    'jobPercentDiskSpace': this.jobPercentDiskSpace,
                    'jobType': 'repeatEvery',
                    'jobName': 'SystemCheckDisk Job',
                    'strCronProjectUUID': store.state.CronProjectUUID.trim(),
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'addSysCheckDiskSpaceJob',
                data: data

            })
            .then((response) => {
                console.log(response.data)
                if(response.data == 'OK')
                {
                    this.alert1 = true;
                    this.alert2 = false;
                    this.alert3 = false;
                    this.alert4 = false;
                }
                else
                {
                    if(response.data == 'Found SystemCheckDisk Job from Running list')
                    {
                        this.alert1 = false;
                        this.alert2 = true;
                        this.alert3 = false;
                        this.alert4 = false;
                    }
                    else
                    {
                        this.alert1 = false;
                        this.alert2 = false;
                        this.alert3 = true;
                        this.alert4 = false;
                    }
                }
            })
            .catch((response) => {
                //console.log(response)
                let err = Object.assign({}, response)
                console.log('error', err.response);
                                
                if(err.response.status == '488')
                {
                    this.$router.push('/')
                }
            });
        }
        else
        {
            this.alert1 = false;
            this.alert2 = false;
            this.alert3 = false;
            this.alert4 = true;
        }
      },
    },
}
</script>