import axios from 'axios'
import User from './user_help'
//import date from 'date-and-time';
import store from './store.js'
import * as sha512 from 'js-sha512'

export default {
    state: {
        user: null,
        userPID: null
    },
    mutations: {
        setUser (state, payload) {
            state.user = payload
        }
    },
    actions: {
        async loginUserYandexSmart ({commit}, {email, password, recaptchaToken}){
            commit('clearError')
            commit('setLoading', true)
            //console.log(store.state.urlAN + 'recaptcha')

            // Текущая дата и время
            let now = new Date();

            let shacd1 = sha512.sha512(formatDateCD(now));
            let shacd2 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 1)));
            let shacd3 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 2)));
            let shacd4 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 3)));
            let shacd5 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 4)));

            //console.log(dtDateLastPay)
            
            let dataTrC = 
                [ { 
                    "cd1":shacd1,
                    "cd2":shacd2,
                    "cd3":shacd3,
                    "cd4":shacd4,
                    "cd5":shacd5
                } ]

            await axios({
                method: 'POST',
                headers: {
                    'key': process.env.API_RECA,
                    'recaptchaToken': recaptchaToken,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'verifyYaSmartCaptcha',
                data: dataTrC

            })
            .then(async (response) => {
                //console.log(response.status)
                if(response.status === 200){
                    try {
                        //console.log(response)

                        // Текущая дата и время
                        now = new Date();

                        shacd1 = sha512.sha512(formatDateCD(now));
                        shacd2 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 1)));
                        shacd3 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 2)));
                        shacd4 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 3)));
                        shacd5 = sha512.sha512(formatDateCD(getDateMinusMinutes(now, 4)));

                        //console.log(dtDateLastPay)
                        
                        let dataTr = 
                            [ { 
                                "cd1":shacd1,
                                "cd2":shacd2,
                                "cd3":shacd3,
                                "cd4":shacd4,
                                "cd5":shacd5,
                                "u":email, 
                                "p":password
                            } ]

                        // Logic for using
                        await axios({
                            method: 'POST',
                            headers: {
                                'api_key': process.env.API_START,
                                'Content-Type': 'application/json'
                            },
                            url: store.state.urlCJ + 'checkUserNodeCronSide',
                            data: dataTr
        
                        })
                        .then(async (response) => {
                            //console.log(response)

                            var body = response.data
                            var s
                            for(let key=0; key < body.length; key++) {  
                                s = body[key] 
                            } 
                            
                            commit('setUser', new User(s.strMail))
                            store.commit('SetUserMail', s.strMail)
                            store.commit('SetAdmin', s.intAdmin)
                            store.commit('SetSchoolAdmin', s.intSchoolAdmin)
                            store.commit('SetUserProjID', s.idProject)
                            store.commit('SetUserID', s.id)
                            store.commit('SetUserName', s.strSchoolName)
                            store.commit('SetLiteAccess', s.intLiteAccess)
                            store.commit('SetProAccess', s.intProAccess)
                            store.commit('SetUnlimitedAccess', s.intUnlimitedAccess)
                            store.commit('SetDateLastPay', s.dtDateLastPay)
                            store.commit('SetDateAccess', s.dtDateAccess)
                            store.commit('SetGlobalProjectName', s.strProjectName)
                            //store.commit('SetGlobalProjectName', 'test_5a79b82a1b')
                            store.commit('SetCurrentApiKey', s.strcurrentapi)
                            store.commit('SetCronProjectUUID', s.strProjectUUID)
                            //store.commit('SetCronProjectUUID', '6691d87e-fc89-46fc-a22c-5eb1cbd7387c')
                            commit('setLoading', false)

                        })
                        .catch(function(error) {
                            commit('setLoading', false)
                            commit('setError', 'Ошибка подключения, проверьте введенные данные!')
                            
                            throw error
                        });
                        
                    } catch (error) {
                        // error logic here
                
                        commit('setLoading', false)
                        commit('setError', error.message)
                        
                        throw error
                    } 
                } else {
                    throw 'Capcha check ERROR'
                }
                
            })
            
            //console.log(user)
            
        },

        // Logout
        async logoutUser ({commit}) {
            //console.log('LOG OUT!')
            commit('setUser', null)
            
  
        }
    },
    getters: {
        user (state) {
            return state.user
        },
        checkUser (state) {
            //console.log('Check user:' + state.user)
            return state.user !== null
        }
    }
}


function formatDateCD(date) {
    return date.getUTCFullYear() + '-' + 
      String(date.getUTCMonth() + 1).padStart(2, '0') + '-' +
      String(date.getUTCDate()).padStart(2, '0') + ' ' +
      String(date.getUTCHours()).padStart(2, '0') + ':' +
      String(date.getUTCMinutes()).padStart(2, '0');
  }

function getDateMinusMinutes(date, minutes) {
    return new Date(date.getTime() - minutes * 60000);
  }