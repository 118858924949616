<template>
    <v-container>
      <!-- Диалоговое окно для замены таблицы -->
      <v-dialog v-model="showReplaceTableDialog" max-width="500px">
        <v-card>
          <v-card-title>Заменить таблицу</v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="tableToReplace"
              :items="tables"
              item-text="selectedTableName"
              item-value="selectedTableName"
              label="Выберите таблицу для замены"
              :filter="customFilter"
              clearable
              dense
              hide-details
              auto-select-first
              :menu-props="{ maxHeight: '400px' }"
              class="mt-6"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.selectedTableName }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-autocomplete
              v-model="newTableName"
              :items="availableTables"
              label="Выберите новую таблицу"
              :filter="customFilter"
              clearable
              dense
              hide-details
              auto-select-first
              :menu-props="{ maxHeight: '400px' }"
              class="mt-6"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showReplaceTableDialog = false">Отмена</v-btn>
            <v-btn color="blue darken-1" text @click="replaceTable">Заменить</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showGenerateQueryDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">Подтверждение</v-card-title>
          <v-card-text>
            Ранее сгенерированный или введенный вручную SQL Запрос будет заменен на новый SQL запрос согласно обновленным данным в Конструкторе! Продолжить генерацию запроса?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="confirmGenerateQuery">Да</v-btn>
            <v-btn color="red darken-1" text @click="cancelGenerateQuery">Нет</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
        <v-card class="mr-4 ml-4 mb-2">
            <v-card-title class="bg-amber-lighten-1">
            <h2 class="me-4 font-weight-light">
                Postgresql Конструктор запросов
            </h2>
            </v-card-title>
            <v-card-text>
                Экспортировать данные из PostgreSql в Гугл таблицы.
                <br><br>
                Внимание! Конструктор используется для построения базовых запросов, вы всегда можете воспользоваться <b><a target="_blank" href="https://pg.analyticsnode.ru:8989/pgadmin4/login">полноценным инструментом</a></b> для создания сложных запросов, просто оформите ваш запрос в формате PostgreSQL в соответствующее поле в разделе SQL Запрос, далее выполните тестирование запроса по кнопке ПРОТЕСТИРОВАТЬ ЗАПРОС.
            </v-card-text>
        </v-card>

        <v-switch
            v-model="useOnlySqlQuery"
            label="Использовать только SQL запрос"
            v-show="false"
        ></v-switch>

      <v-tabs v-model="activeTab">
        <v-tab
            v-for="item in activeTabItems"
            :key="item"
            class="mt-2"
          >
            {{ item }}
          </v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
                <v-expansion-panels class="px-2">
                    <!-- Блок выбора таблицы -->
                    <v-expansion-panel>
                    <v-expansion-panel-header><h4>Выбор Таблиц из БД</h4></v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-layout row align-center>
                        <v-btn class="mr-2" color="primary" @click="addTable">Добавить таблицу</v-btn>
                        <v-btn color="secondary" @click="openReplaceTableDialog">Заменить таблицу</v-btn>
                        </v-layout>
                        <v-tabs v-model="selectedTableIndex" class="mt-4">
                        <v-tab v-for="(table, tableIndex) in tables" :key="tableIndex">
                            {{ table.name }}
                            <v-btn icon small @click.stop="removeTable(tableIndex)">
                            <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-tab>
                        <v-tab-item v-for="(table, tableIndex) in tables" :key="tableIndex">
                            <v-card flat>
                            <v-card-text>
                                <v-layout row align-center>
                                <v-flex xs4 class="mr-2">
                                  <v-autocomplete
                                  :items="availableTables"
                                  v-model="table.selectedTableName"
                                  label="Выберите таблицу"
                                  @change="onTableChange(table)"
                                  :filter="customFilter"
                                  clearable
                                  dense
                                  hide-details
                                  auto-select-first
                                  :menu-props="{ maxHeight: '400px' }"
                                  class="mt-4"
                                >
                                  <template v-slot:item="{ item }">
                                    <v-list-item-content>
                                      <v-list-item-title>{{ item }}</v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </v-autocomplete>
                                </v-flex>
                                </v-layout>
                            </v-card-text>
                            </v-card>
                        </v-tab-item>
                        </v-tabs>
                    </v-expansion-panel-content>
                    </v-expansion-panel>
            
                    <!-- Новый блок выбора полей -->
                    <v-expansion-panel>
                      <v-expansion-panel-header><h4>Выбор полей из набора таблиц</h4></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        
                        <v-card 
                          v-for="(fieldSet, setIndex) in fieldSets" 
                          :key="setIndex" 
                          class="mb-4" 
                          outlined
                        >
                          <v-card-text>
                            <v-layout column>
                              <v-layout row align-center class="mb-2">
                                <v-btn icon small @click="moveFieldUp(setIndex)" :disabled="setIndex === 0">
                                  <v-icon>mdi-arrow-up</v-icon>
                                </v-btn>
                                <v-btn icon small @click="moveFieldDown(setIndex)" :disabled="setIndex === fieldSets.length - 1">
                                  <v-icon>mdi-arrow-down</v-icon>
                                </v-btn>
                                <v-btn icon color="error" class="mr-2" @click="removeFieldSet(setIndex)">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                <v-btn-toggle
                                  v-model="fieldSet.type"
                                  mandatory
                                  class="mr-2 mt-2"
                                  @change="onFieldSetTypeChange(fieldSet)"
                                >
                                  <v-btn value="field">Поле</v-btn>
                                  <v-btn value="condition">Условие</v-btn>
                                  <v-btn value="subquery">Подзапрос</v-btn>
                                </v-btn-toggle>
                              </v-layout>

                              <!-- Поле выбора колонки перемещено сюда -->
                              <v-layout row v-if="fieldSet.type === 'field'" class="ml-2">
                                <v-flex>
                                  <v-autocomplete
                                    :items="getAllColumns()"
                                    item-text="fullName"
                                    item-value="name"
                                    v-model="fieldSet.column"
                                    label="Выберите колонку"
                                    @change="onColumnChange(fieldSet)"
                                    :filter="customFilterColumn"
                                    clearable
                                    dense
                                    hide-details
                                    auto-select-first
                                    :menu-props="{ maxHeight: '400px' }"
                                    class="mb-4 mt-2"
                                  >
                                    <template v-slot:item="{ item }">
                                      <v-list-item-content>
                                        <v-list-item-title>{{ item.fullName }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                                      </v-list-item-content>
                                    </template>
                                  </v-autocomplete>
                                </v-flex>
                              </v-layout>

                              <!-- Существующий код для типа "field" -->
                              <v-layout row class="mb-2 ml-2" align-center v-if="fieldSet.type === 'field'">
                                <v-flex xs3>
                                  <v-select
                                    :items="getFunctionsForColumn(fieldSet)"
                                    v-model="fieldSet.function"
                                    label="Выберите функцию"
                                    @change="onFunctionChange(fieldSet)"
                                  ></v-select>
                                </v-flex>
                                <v-flex xs3 class="ml-2" v-if="fieldSet.showFormatField">
                                  <v-combobox
                                    :items="dateFormats"
                                    v-model="fieldSet.dateFormat"
                                    label="Формат даты"
                                    :hint="fieldSet.dateFormat === 'Свой формат' ? 'Введите свой формат' : ''"
                                    persistent-hint
                                  ></v-combobox>
                                </v-flex>
                                <v-flex xs3 class="ml-2">
                                  <v-text-field
                                    v-model="fieldSet.alias"
                                    label="Выводить как"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>

                              <!-- Новый код для типа "condition" -->
                              <v-layout column v-if="fieldSet.type === 'condition'">
                                <v-card outlined class="mb-2">
                                  <!-- Блок Агрегатная функция -->
                                  <v-card-text>
                                    <v-layout row align-center class="mr-4 ml-2">
                                      <v-btn class="mr-2" depressed active>АГРЕГАТНАЯ ФУНКЦИЯ</v-btn>
                                      <v-flex xs3>
                                        <v-select
                                          v-model="fieldSet.caseAggregateFunction"
                                          :items="['Без функции', 'Количество', 'Сумма']"
                                          label="Агрегатная функция"
                                        ></v-select>
                                      </v-flex>
                                    </v-layout>
                                  </v-card-text>
                                </v-card>
                                <v-card outlined class="mb-2" v-for="(caseCondition, caseIndex) in fieldSet.caseConditions" :key="caseIndex">
                                  
                                  <v-card-text>
                                    <!-- Блок ЕСЛИ -->
                                    <v-layout row align-center class="mr-2 ml-2">
                                      <v-btn class="mr-2" depressed active>ЕСЛИ</v-btn>
                                      <v-flex>
                                        <v-autocomplete
                                          :items="getAllColumns()"
                                          item-text="fullName"
                                          item-value="name"
                                          v-model="caseCondition.ifColumn"
                                          label="Выберите колонку"
                                          @change="onConditionColumnChange(caseCondition, 'if')"
                                          :filter="customFilterColumn"
                                          clearable
                                          dense
                                          hide-details
                                          auto-select-first
                                          :menu-props="{ maxHeight: '400px' }"
                                          class="mb-4 mt-4"
                                        >
                                          <template v-slot:item="{ item }">
                                            <v-list-item-content>
                                              <v-list-item-title>{{ item.fullName }}</v-list-item-title>
                                              <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                          </template>
                                        </v-autocomplete>
                                      </v-flex>
                                    </v-layout>
                                    <v-layout row align-center class="ml-2">
                                      <v-flex xs3 v-if="showConditionFunction(caseCondition, 'if')">
                                        <v-select
                                          :items="getConditionFunctions(caseCondition, 'if')"
                                          v-model="caseCondition.ifFunction"
                                          label="Выберите функцию"
                                        ></v-select>
                                      </v-flex>
                                      <v-flex xs3 class="ml-2">
                                        <v-select
                                          :items="options"
                                          v-model="caseCondition.ifOption"
                                          label="Выберите опцию"
                                          @change="onOptionChange(caseCondition)"
                                        ></v-select>
                                      </v-flex>
                                      <v-flex xs3 class="ml-2" v-if="caseCondition.showIfValueField">
                                        <v-menu
                                          v-if="caseCondition.ifColumnType === 'date'"
                                          v-model="caseCondition.ifDateMenu"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="auto"
                                        >
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                              v-model="caseCondition.ifDateValue"
                                              label="Выберите дату"
                                              prepend-icon="mdi-calendar"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker
                                            v-model="caseCondition.ifDateValue"
                                            @input="caseCondition.ifDateMenu = false"
                                          ></v-date-picker>
                                        </v-menu>
                                        <v-text-field
                                          v-else
                                          v-model="caseCondition.ifValue"
                                          label="Значение"
                                        ></v-text-field>
                                      </v-flex>
                                      <v-flex xs3 class="ml-2" v-if="caseCondition.showIfField2">
                                        <v-menu
                                          v-if="caseCondition.ifColumnType === 'date'"
                                          v-model="caseCondition.ifDateMenu2"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="auto"
                                        >
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                              v-model="caseCondition.ifDateValue2"
                                              label="Выберите дату"
                                              prepend-icon="mdi-calendar"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker
                                            v-model="caseCondition.ifDateValue2"
                                            @input="caseCondition.ifDateMenu2 = false"
                                          ></v-date-picker>
                                        </v-menu>
                                        <v-text-field
                                          v-else
                                          v-model="caseCondition.ifValue2"
                                          label="Значение 2"
                                        ></v-text-field>
                                      </v-flex>
                                    </v-layout>

                                    <!-- Блок ТО -->
                                    <v-layout row align-center class="mr-2 ml-2">
                                      <v-btn class="mr-2" depressed active>ТО</v-btn>
                                      <v-flex>
                                        <v-select
                                          v-model="caseCondition.thenType"
                                          :items="[
                                            { text: 'Значение', value: 'value' },
                                            { text: 'Колонка', value: 'column' }
                                          ]"
                                          label="Тип"
                                        ></v-select>
                                      </v-flex>
                                    </v-layout>

                                    <v-layout column class="mr-2 ml-2">
                                      <v-flex xs12 v-if="caseCondition.thenType === 'value'">
                                        <v-text-field
                                          v-model="caseCondition.thenValue"
                                          label="Значение"
                                        ></v-text-field>
                                      </v-flex>
                                      <template v-else>
                                        <v-flex xs12>
                                          <v-autocomplete
                                            :items="getAllColumns()"
                                            item-text="fullName"
                                            item-value="name"
                                            v-model="caseCondition.thenColumn"
                                            label="Выберите колонку"
                                            @change="onThenColumnChange(caseCondition)"
                                            :filter="customFilterColumn"
                                            clearable
                                            dense
                                            hide-details
                                            auto-select-first
                                            :menu-props="{ maxHeight: '400px' }"
                                            class="mb-6 mt-4"
                                          >
                                            <template v-slot:item="{ item }">
                                              <v-list-item-content>
                                                <v-list-item-title>{{ item.fullName }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                                              </v-list-item-content>
                                            </template>
                                          </v-autocomplete>
                                        </v-flex>
                                        <v-layout row>
                                          <v-flex xs3>
                                            <v-select
                                              :items="getFunctionsForColumn(caseCondition, 'then')"
                                              v-model="caseCondition.thenFunction"
                                              label="Выберите функцию"
                                              @change="onThenFunctionChange(caseCondition)"
                                              class="mr-2 ml-2"
                                            ></v-select>
                                          </v-flex>
                                          <v-flex xs3 v-if="caseCondition.showThenFormatField">
                                            <v-combobox
                                              :items="dateFormats"
                                              v-model="caseCondition.thenDateFormat"
                                              label="Формат даты"
                                              :hint="caseCondition.thenDateFormat === 'Свой формат' ? 'Введите свой формат' : ''"
                                              persistent-hint
                                              class="ml-2"
                                            ></v-combobox>
                                          </v-flex>
                                          <v-flex xs3>
                                            <v-text-field v-model="caseCondition.thenAlias" label="Выводить как"></v-text-field>
                                          </v-flex>
                                        </v-layout>
                                      </template>
                                    </v-layout>

                                    <!-- Обновленные кнопки управления для ЕСЛИ-ТО блока -->
                                    <v-layout justify-end>
                                      <v-btn icon @click="addCaseCondition(fieldSet)" class="mr-2">
                                        <v-icon>mdi-plus</v-icon>
                                      </v-btn>
                                      <v-btn icon @click="removeCaseCondition(fieldSet, caseIndex)">
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </v-layout>
                                  </v-card-text>
                                </v-card>

                                <!-- Блок ИНАЧЕ -->
                                <v-card outlined class="mb-2">
                                  <v-card-text>
                                    <v-layout row align-center class="mr-2 ml-2">
                                      <v-btn class="mr-2" depressed active>ИНАЧЕ</v-btn>
                                      <v-flex>
                                        <v-select
                                          v-model="fieldSet.elseType"
                                          :items="[
                                            { text: 'Значение', value: 'value' },
                                            { text: 'Колонка', value: 'column' }
                                          ]"
                                          label="Тип"
                                        ></v-select>
                                      </v-flex>
                                    </v-layout>

                                    <v-layout column class="mr-2 ml-2">
                                      <v-flex xs12 v-if="fieldSet.elseType === 'value'">
                                        <v-text-field
                                          v-model="fieldSet.elseValue"
                                          label="Значение"
                                        ></v-text-field>
                                      </v-flex>
                                      <template v-else>
                                        <v-flex xs12>
                                          <v-autocomplete
                                            :items="getAllColumns()"
                                            item-text="fullName"
                                            item-value="name"
                                            v-model="fieldSet.elseColumn"
                                            label="Выберите колонку"
                                            @change="onElseColumnChange(fieldSet)"
                                            :filter="customFilterColumn"
                                            clearable
                                            dense
                                            hide-details
                                            auto-select-first
                                            :menu-props="{ maxHeight: '400px' }"
                                            class="mb-6 mt-4"
                                          >
                                            <template v-slot:item="{ item }">
                                              <v-list-item-content>
                                                <v-list-item-title>{{ item.fullName }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                                              </v-list-item-content>
                                            </template>
                                          </v-autocomplete>
                                        </v-flex>
                                        <v-layout row>
                                          <v-flex xs3>
                                            <v-select
                                              :items="getFunctionsForColumn(fieldSet, 'else')"
                                              v-model="fieldSet.elseFunction"
                                              label="Выберите функцию"
                                              @change="onElseFunctionChange(fieldSet)"
                                              class="mr-2 ml-2"
                                            ></v-select>
                                          </v-flex>
                                          <v-flex xs3 v-if="fieldSet.showElseFormatField">
                                            <v-combobox
                                              :items="dateFormats"
                                              v-model="fieldSet.elseDateFormat"
                                              label="Формат даты"
                                              :hint="fieldSet.elseDateFormat === 'Свой формат' ? 'Введите свой формат' : ''"
                                              persistent-hint
                                              class="ml-2"
                                            ></v-combobox>
                                          </v-flex>
                                          <v-flex xs3>
                                            <v-text-field v-model="fieldSet.elseAlias" label="Выводить как"></v-text-field>
                                          </v-flex>
                                        </v-layout>
                                      </template>
                                    </v-layout>
                                  </v-card-text>
                                </v-card>

                                <!-- Блок ВЫВОДИТЬ УСЛОВИЕ КАК -->
                                <v-card outlined class="mb-2">
                                  <v-card-text>
                                    <v-layout row align-center class="mr-2 ml-2">
                                      <v-btn class="mr-2 mt-2" depressed active>ВЫВОДИТЬ УСЛОВИЕ КАК</v-btn>
                                      <v-flex xs4 class="ml-2">
                                        <v-text-field
                                          v-model="fieldSet.caseAlias"
                                          label="Выводить как"
                                          hint="Введите название для всего условия"
                                          persistent-hint
                                        ></v-text-field>
                                      </v-flex>
                                    </v-layout>
                                  </v-card-text>
                                </v-card>
                              </v-layout>

                              <!-- Блок ПОДЗАПРОС -->
                              <v-layout v-if="fieldSet.type === 'subquery'" column>
                                <v-card outlined class="mb-4 pa-4">
                                  <h3>Подзапрос</h3>
                                  <br>
                                  <v-expansion-panels v-model="fieldSet.subquery.activePanels">
                                    <!-- 1. Блок Выбор таблиц из БД -->
                                    <v-expansion-panel>
                                      <v-expansion-panel-header><h4>Выбор Таблиц из БД</h4></v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <v-layout row align-center>
                                          <v-btn 
                                            color="primary" 
                                            @click="addSubqueryTable(fieldSet)"
                                            :disabled="fieldSet.subquery.tables.length >= 1"
                                          >
                                            Добавить таблицу
                                          </v-btn>
                                        </v-layout>
                                        <v-tabs v-model="fieldSet.subquery.selectedTableIndex" class="mt-4">
                                          <v-tab v-for="(table, tableIndex) in fieldSet.subquery.tables" :key="tableIndex">
                                            {{ table.name }}
                                            <v-btn icon small @click.stop="removeSubqueryTable(fieldSet, tableIndex)">
                                              <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                          </v-tab>
                                          <v-tab-item v-for="(table, tableIndex) in fieldSet.subquery.tables" :key="tableIndex">
                                            <v-card flat>
                                              <v-card-text>
                                                <v-layout row align-center>
                                                  <v-flex xs6 class="mt-4">
                                                    <v-autocomplete
                                                      :items="availableTables"
                                                      v-model="table.selectedTableName"
                                                      label="Выберите таблицу"
                                                      @change="onSubqueryTableChange(fieldSet, table)"
                                                      :filter="customFilter"
                                                      clearable
                                                      dense
                                                      hide-details
                                                      auto-select-first
                                                      :menu-props="{ maxHeight: '400px' }"
                                                      class="mt-4"
                                                    >
                                                      <template v-slot:item="{ item }">
                                                        <v-list-item-content>
                                                          <v-list-item-title>{{ item }}</v-list-item-title>
                                                        </v-list-item-content>
                                                      </template>
                                                    </v-autocomplete>
                                                  </v-flex>
                                              </v-layout>
                                              </v-card-text>
                                            </v-card>
                                          </v-tab-item>
                                        </v-tabs>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <!-- 2. Блок Выбор полей из набора таблиц -->
                                    <v-expansion-panel>
                                      <v-expansion-panel-header><h4>Выбор полей из набора таблиц</h4></v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        
                                        <v-card 
                                          v-for="(subFieldSet, subFieldSetIndex) in fieldSet.subquery.fieldSets" 
                                          :key="subFieldSetIndex" 
                                          class="mb-4" 
                                          outlined
                                        >
                                          <v-card-text>
                                            <v-layout column>
                                              <v-layout row align-center>
                                                <v-btn icon color="error" class="mr-2" @click="removeSubqueryFieldSet(fieldSet, subFieldSetIndex)">
                                                  <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                                <v-autocomplete
                                                  :items="getSubqueryColumns(fieldSet)"
                                                  item-text="fullName"
                                                  item-value="name"
                                                  v-model="subFieldSet.column"
                                                  label="Выберите колонку"
                                                  @change="onSubqueryColumnChange(fieldSet, subFieldSet)"
                                                  :filter="customFilterColumn"
                                                  clearable
                                                  dense
                                                  hide-details
                                                  auto-select-first
                                                  :menu-props="{ maxHeight: '400px' }"
                                                  class="mb-4 mt-4 mr-2"
                                                >
                                                  <template v-slot:item="{ item }">
                                                    <v-list-item-content>
                                                      <v-list-item-title>{{ item.fullName }}</v-list-item-title>
                                                      <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                  </template>
                                                </v-autocomplete>
                                              </v-layout>
                                              <v-layout row align-center>
                                                <v-select
                                                  :items="getFunctionsForColumn(subFieldSet)"
                                                  v-model="subFieldSet.function"
                                                  label="Выберите функцию"
                                                  @change="onSubqueryFunctionChange(fieldSet, subFieldSet)"
                                                  class="ml-2"
                                                ></v-select>
                                                <v-combobox
                                                  v-if="subFieldSet.showFormatField"
                                                  :items="dateFormats"
                                                  v-model="subFieldSet.dateFormat"
                                                  label="Формат даты"
                                                  :hint="subFieldSet.dateFormat === 'Свой формат' ? 'Введите свой формат' : ''"
                                                  persistent-hint
                                                  class="ml-2"
                                                ></v-combobox>
                                                <v-text-field
                                                  v-model="subFieldSet.alias"
                                                  label="Выводить как"
                                                  class="ml-2 mr-2"
                                                ></v-text-field>
                                              </v-layout>
                                            </v-layout>
                                          </v-card-text>
                                        </v-card>
                                        <v-btn color="primary" @click="addSubqueryFieldSet(fieldSet)" class="mb-4">Добавить поле</v-btn>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <!-- 3. Блок Условие WHERE -->
                                    <!-- 3. Блок Условие WHERE в ПОДЗАПРОСЕ -->
                                    <v-expansion-panel>
                                      <v-expansion-panel-header><h4>Условия WHERE</h4></v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <v-btn color="primary" @click="addSubqueryWhereGroup(fieldSet)" class="mb-4">Добавить группу условий</v-btn>
                                        <v-card 
                                          v-for="(group, groupIndex) in fieldSet.subquery.whereGroups" 
                                          :key="groupIndex" 
                                          class="mb-4" 
                                          outlined
                                        >
                                          <v-card-text>
                                            <v-layout column>
                                              <v-layout row align-center class="mb-2">
                                                <h4 class="ml-3">Группа условий {{ groupIndex + 1 }}</h4>
                                                <v-btn icon color="error" @click="removeSubqueryWhereGroup(fieldSet, groupIndex)">
                                                  <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                              </v-layout>
                                              <v-layout row align-center class="mb-2">
                                                <v-btn-toggle v-model="group.operator" mandatory class="mr-2 ml-3">
                                                  <v-btn value="И">И</v-btn>
                                                  <v-btn value="ИЛИ">ИЛИ</v-btn>
                                                </v-btn-toggle>
                                                <v-btn color="secondary" @click="addSubqueryWhereSet(fieldSet, groupIndex)">Добавить набор условий</v-btn>
                                              </v-layout>
                                              <v-card 
                                                v-for="(set, setIndex) in group.sets" 
                                                :key="setIndex"  
                                                outlined
                                                class="mb-2"
                                              >
                                                <v-card-text>
                                                  <v-layout column>
                                                    <v-layout row align-center>
                                                      <v-btn icon color="error" class="mr-2" @click="removeSubqueryWhereSet(fieldSet, groupIndex, setIndex)">
                                                        <v-icon>mdi-delete</v-icon>
                                                      </v-btn>
                                                      <v-autocomplete
                                                        :items="getSubqueryColumns(fieldSet)"
                                                        item-text="fullName"
                                                        item-value="name"
                                                        v-model="set.column"
                                                        label="Выберите колонку"
                                                        @change="onSubqueryWhereColumnChange(fieldSet, groupIndex, setIndex, set)"
                                                        :filter="customFilterColumn"
                                                        clearable
                                                        dense
                                                        hide-details
                                                        auto-select-first
                                                        :menu-props="{ maxHeight: '400px' }"
                                                        class="mb-4 mt-4 mr-2"
                                                      >
                                                        <template v-slot:item="{ item }">
                                                          <v-list-item-content>
                                                            <v-list-item-title>{{ item.fullName }}</v-list-item-title>
                                                            <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                                                          </v-list-item-content>
                                                        </template>
                                                      </v-autocomplete>
                                                    </v-layout>
                                                    <v-layout row align-center>
                                                      <v-checkbox
                                                        v-model="set.useMainTableField"
                                                        label="Использовать поле из основной таблицы"
                                                        class="mt-1 ml-2"
                                                        @change="onUseMainTableFieldChange(set)"
                                                      ></v-checkbox>
                                                    </v-layout>
                                                    <v-layout row align-center>
                                                    <v-flex xs3 class="ml-2 mt-1">
                                                      <v-select
                                                      :items="options"
                                                      v-model="set.option"
                                                      label="Выберите опцию"
                                                      @change="onSubqueryWhereOptionChange(fieldSet, groupIndex, setIndex, set)"
                                                      ></v-select>
                                                    </v-flex>
                                                    <v-flex xs3 class="ml-2" v-if="showSubqueryWhereValueField(set)">
                                                      <v-menu
                                                        v-if="set.columnType === 'date'"
                                                        v-model="set.dateMenu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                      >
                                                        <template v-slot:activator="{ on, attrs }">
                                                          <v-text-field
                                                            v-model="set.dateValue"
                                                            label="Выберите дату"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="mt-2"
                                                          ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                          v-model="set.dateValue"
                                                          @input="set.dateMenu = false"
                                                        ></v-date-picker>
                                                      </v-menu>
                                                      <v-text-field
                                                        v-else
                                                        v-model="set.value"
                                                        :label="set.option === 'Между' ? 'Значение 1' : 'Значение'"
                                                        class="mt-2"
                                                      ></v-text-field>
                                                    </v-flex>
                                                    
                                                    <v-flex xs3 class="ml-2"  v-if="set.showField2">
                                                      <v-menu
                                                        v-if="set.columnType === 'date'"
                                                        v-model="set.dateMenu2"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                      >
                                                        <template v-slot:activator="{ on, attrs }">
                                                          <v-text-field
                                                            v-model="set.dateValue2"
                                                            label="Выберите дату"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="mt-2"
                                                          ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                          v-model="set.dateValue2"
                                                          @input="set.dateMenu2 = false"
                                                        ></v-date-picker>
                                                      </v-menu>
                                                      <v-text-field
                                                        v-else
                                                        v-model="set.value2"
                                                        label="Значение 2"
                                                        class="mt-2"
                                                      ></v-text-field>
                                                    </v-flex>
                                                    <v-flex xs6 class="ml-2" v-if="set.useMainTableField">
                                                      <v-autocomplete
                                                        :items="getMainTableColumns()"
                                                        item-text="fullName"
                                                        item-value="name"
                                                        v-model="set.mainTableColumn"
                                                        label="Выберите колонку из основной таблицы"
                                                        :filter="customFilterColumn"
                                                        clearable
                                                        dense
                                                        hide-details
                                                        auto-select-first
                                                        :menu-props="{ maxHeight: '400px' }"
                                                      >
                                                        <template v-slot:item="{ item }">
                                                          <v-list-item-content>
                                                            <v-list-item-title>{{ item.fullName }}</v-list-item-title>
                                                            <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                                                          </v-list-item-content>
                                                        </template>
                                                      </v-autocomplete>
                                                    </v-flex>
                                                    </v-layout>
                                                  </v-layout>
                                                </v-card-text>
                                              </v-card>
                                            </v-layout>
                                          </v-card-text>
                                        </v-card>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <!-- Новый блок "Выводить как" для подзапроса -->
                                    <v-expansion-panel>
                                      <v-expansion-panel-header><h4>Выводить подзапрос как</h4></v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <v-flex xs3 class="ml-2">
                                          <v-text-field
                                            v-model="fieldSet.subquery.alias"
                                            label="Выводить как"
                                            hint="Введите название столбца для вывода"
                                            persistent-hint
                                          ></v-text-field>
                                        </v-flex>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>


                                  </v-expansion-panels>
                                </v-card>
                              </v-layout>
                            </v-layout>
                          </v-card-text>
                        </v-card>
                        <v-btn color="primary" @click="addFieldSet" class="mb-4">Добавить поле</v-btn>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="tables.length > 1">
                        <v-expansion-panel-header><h4>Условие JOIN</h4></v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-btn color="primary" @click="addJoinCondition" class="mb-4">Добавить условие JOIN</v-btn>
                            <v-card 
                            v-for="(condition, index) in joinConditions" 
                            :key="index" 
                            class="mb-4" 
                            outlined
                            >
                            <v-card-text>
                                <v-flex xs3 class="mb-2">
                                  <v-select
                                    :items="joinTypes"
                                    v-model="condition.joinType"
                                    label="Тип JOIN"
                                  ></v-select>
                                </v-flex>
                                <v-layout column>
                                <v-layout row align-center class="mb-2">
                                    <v-btn icon color="error" class="mr-2" @click="removeJoinCondition(index)">
                                    <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                    <v-flex>
                                      <v-autocomplete
                                          :items="getAllColumns()"
                                          item-text="fullName"
                                          item-value="fullName"
                                          v-model="condition.column1"
                                          label="Выберите колонку 1"
                                          @change="onJoinColumnChange(condition, 'column1')"
                                          :filter="customFilterColumn"
                                          clearable
                                          dense
                                          hide-details
                                          auto-select-first
                                          :menu-props="{ maxHeight: '400px' }"
                                          class="mr-2"
                                      >
                                          <template v-slot:item="{ item }">
                                          <v-list-item-content>
                                              <v-list-item-title>{{ item.fullName }}</v-list-item-title>
                                              <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                                          </v-list-item-content>
                                          </template>
                                      </v-autocomplete>
                                    </v-flex>
                                </v-layout>
                                <v-flex xs3 class="mb-2">
                                    <v-select
                                    :items="joinOptions"
                                    v-model="condition.option"
                                    label="Выберите опцию"
                                    ></v-select>
                                </v-flex>
                                
                                <v-flex>
                                  <v-autocomplete
                                    :items="getAllColumns()"
                                    item-text="fullName"
                                    item-value="fullName"
                                    v-model="condition.column2"
                                    label="Выберите колонку 2"
                                    @change="onJoinColumnChange(condition, 'column2')"
                                    :filter="customFilterColumn"
                                    clearable
                                    dense
                                    hide-details
                                    auto-select-first
                                    :menu-props="{ maxHeight: '400px' }"
                                    >
                                    <template v-slot:item="{ item }">
                                        <v-list-item-content>
                                        <v-list-item-title>{{ item.fullName }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                  </v-autocomplete>
                                </v-flex>
                                </v-layout>
                            </v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header><h4>Условия WHERE</h4></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-btn color="primary" @click="addGroup" class="mb-4">Добавить группу условий</v-btn>
                        <v-card 
                          v-for="(group, index) in groups" 
                          :key="index" 
                          class="mb-4" 
                          outlined
                        >
                          <v-card-text>
                            <v-layout column>
                              <v-layout row align-center class="mb-2">
                                <v-flex xs2>
                                  <h4 class="ml-3">Группа условий {{ index + 1 }}</h4>
                                </v-flex>
                                <v-btn icon color="error" @click="removeGroupsSet(groups, index)">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </v-layout>
                              <v-layout row align-center class="mb-2">
                                <v-btn-toggle v-model="group.operator" mandatory class="mr-2 ml-3">
                                  <v-btn>И</v-btn>
                                  <v-btn>ИЛИ</v-btn>
                                </v-btn-toggle>
                                <v-btn color="secondary" @click="addSet(group)">Добавить набор условий</v-btn>
                              </v-layout>
                              <v-card 
                                v-for="(set, setIndex) in group.sets" 
                                :key="setIndex"  
                                outlined
                                class="mb-2"
                              >
                                <v-card-text>
                                  <v-layout column>
                                    <v-layout row align-center>
                                      <v-btn icon color="error" class="mr-2" @click="removeSet(group, setIndex)">
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                      <v-flex>
                                        <v-autocomplete
                                          :items="getAllColumns()"
                                          item-text="fullName"
                                          item-value="fullName"
                                          v-model="set.column"
                                          label="Выберите колонку"
                                          @change="onColumnChange(set)"
                                          :filter="customFilterColumn"
                                          clearable
                                          dense
                                          hide-details
                                          auto-select-first
                                          :menu-props="{ maxHeight: '400px' }"
                                          class="mb-2 mt-4"
                                        >
                                          <template v-slot:item="{ item }">
                                            <v-list-item-content>
                                              <v-list-item-title>{{ item.fullName }}</v-list-item-title>
                                              <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                          </template>
                                        </v-autocomplete>
                                      </v-flex>
                                    </v-layout>
                                    <v-layout row align-center>
                                      <v-flex xs3 class="ml-2">
                                        <v-select
                                          :items="options"
                                          v-model="set.option"
                                          label="Выберите опцию"
                                          @change="onOptionChange(set)"
                                        ></v-select>
                                      </v-flex>
                                      <v-flex xs3 class="ml-2" v-if="showValueField(set)">
                                        <v-menu
                                          v-if="set.columnType === 'date'"
                                          v-model="set.dateMenu"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="auto"
                                        >
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                              v-model="set.field1"
                                              label="Выберите дату"
                                              prepend-icon="mdi-calendar"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker
                                            v-model="set.field1"
                                            @input="set.dateMenu = false"
                                          ></v-date-picker>
                                        </v-menu>
                                        <v-text-field
                                          v-else
                                          v-model="set.field1"
                                          :label="set.option === 'Между' ? 'Значение 1' : 'Значение'"
                                        ></v-text-field>
                                      </v-flex>
                                      <v-flex xs3 class="ml-2" v-if="set.showField2">
                                        <v-menu
                                          v-if="set.columnType === 'date'"
                                          v-model="set.dateMenu2"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="auto"
                                        >
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                              v-model="set.field2"
                                              label="Выберите дату"
                                              prepend-icon="mdi-calendar"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker
                                            v-model="set.field2"
                                            @input="set.dateMenu2 = false"
                                          ></v-date-picker>
                                        </v-menu>
                                        <v-text-field
                                          v-else
                                          v-model="set.field2"
                                          label="Значение 2"
                                        ></v-text-field>
                                      </v-flex>
                                    </v-layout>
                                  </v-layout>
                                </v-card-text>
                              </v-card>
                            </v-layout>
                          </v-card-text>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    
                    <!-- Новый блок Сортировка (ORDER BY) -->
                    <v-expansion-panel>
                      <v-expansion-panel-header><h4>Сортировка (ORDER BY)</h4></v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-layout row align-center class="mb-2">
                          <v-flex xs4>
                            <v-autocomplete
                              :items="getAllColumns()"
                              item-text="fullName"
                              item-value="name"
                              v-model="orderByField"
                              label="Выберите поле для сортировки"
                              :filter="customFilterColumn"
                              clearable
                              dense
                              hide-details
                              auto-select-first
                              :menu-props="{ maxHeight: '400px' }"
                              class="mr-2"
                            >
                              <template v-slot:item="{ item }">
                                <v-list-item-content>
                                  <v-list-item-title>{{ item.fullName }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                          </v-flex>
                          <v-flex xs3>
                            <v-radio-group v-model="orderByDirection" row>
                              <v-radio label="По возрастанию" value="ASC"></v-radio>
                              <v-radio label="По убыванию" value="DESC"></v-radio>
                            </v-radio-group>
                          </v-flex>
                          <v-btn color="primary" @click="addOrderByField" class="ml-2">Добавить сортировку</v-btn>
                        </v-layout>

                        <v-layout column>
                          <v-flex>
                            <h4>По возрастанию:</h4>
                            <v-chip-group column>
                              <v-chip
                                v-for="(field, index) in orderByFieldsAsc"
                                :key="'asc-' + index"
                                close
                                @click:close="removeOrderByField('ASC', index)"
                              >
                                {{ field }}
                              </v-chip>
                            </v-chip-group>
                          </v-flex>
                          <v-flex>
                            <h4>По убыванию:</h4>
                            <v-chip-group column>
                              <v-chip
                                v-for="(field, index) in orderByFieldsDesc"
                                :key="'desc-' + index"
                                close
                                @click:close="removeOrderByField('DESC', index)"
                              >
                                {{ field }}
                              </v-chip>
                            </v-chip-group>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
            <v-btn 
              color="primary" 
              class="mt-1 mr-2 ml-4" 
              @click="generateQuery"
            >
              Сгенерировать запрос
            </v-btn>
          </v-card>
          
        </v-tab-item>

        <v-tab-item eager>
          <v-card flat>
            <v-card-text>
              <div ref="editor" style="width: 100%; min-height: 300px; border: 1px solid #9ca2a7;"></div>
            </v-card-text>
            <v-btn 
              color="primary" 
              class="mr-2 ml-4" 
              @click="testQuery"
            >
              Протестировать запрос
            </v-btn>
            <v-alert
              outlined
              type="success"
              shaped
              v-show="vAlertOk"
              class="mt-2"
            >
            {{ vAlertOkText }}
            </v-alert>

            <v-alert
              outlined
              type="error"
              shaped
              v-show="vAlertWarning"
              class="mt-2"
            >
              {{ vAlertWarningText }}
            </v-alert>

            <v-data-table
              :headers="dynamicHeaders"
              :items="resultItems"
              class="mt-4"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td v-for="(value, key) in item" :key="key">
                    {{ value }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat class="mx-auto px-6 py-1">
            
            <form>
              <v-text-field
                  v-model="uuid"
                  prepend-icon="mdi-identifier"
                  label="ID Задачи"
                  readonly
              ></v-text-field>
              <v-select
                  :items="jobType"
                  prepend-icon="mdi-checkbox-marked-circle-plus-outline"
                  label="Выберите тип задачи"
                  v-model="jobTypeSelect"
              ></v-select>
              <v-text-field
                  v-model="jobRepeatEvery"
                  prepend-icon="mdi-timer-alert-outline"
                  :error-messages="nameErrors"
                  label="Запускать через N минут"
                  type="number"
                  required
                  v-show="jobRepeatEveryDisabled"
                  @input="$v.jobRepeatEvery.$touch()"
                  @blur="$v.jobRepeatEvery.$touch()"
              ></v-text-field>

              <v-checkbox readonly class="px-3" v-model="ClearScheet" label="Очищать лист перед загрузкой данных?" color="primary" hide-details></v-checkbox>
              <br>

              <p v-show="jobScheduledDisabled">Выберите Часы и минуты для регулярного запуска (Например запускать в 4,6,8,9 часов 25 минут)</p>
              <p v-show="jobScheduledDisabled"><b>Часы для запуска (МСК):</b></p>
              
              <v-card class="d-flex flex-wrap" flat tile v-show="jobScheduledDisabled">
                  <!-- <v-checkbox class="px-3" v-model="ex00" label="00" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex01" label="01" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox> -->
                  <v-checkbox class="px-3" v-model="ex02" label="02" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex03" label="03" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex04" label="04" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex05" label="05" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex06" label="06" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex07" label="07" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex08" label="08" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex09" label="09" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex10" label="10" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex11" label="11" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex12" label="12" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex13" label="13" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex14" label="14" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex15" label="15" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex16" label="16" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex17" label="17" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex18" label="18" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex19" label="19" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex20" label="20" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex21" label="21" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex22" label="22" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="ex23" label="23" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
              </v-card>
              <br v-show="jobScheduledDisabled">
              <p v-show="jobScheduledDisabled"><b>Минуты для запуска:</b></p>
              <v-card class="d-flex flex-wrap" flat tile v-show="jobScheduledDisabled">
                  <v-checkbox class="px-3" v-model="exm00" label="00" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="exm05" label="05" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="exm10" label="10" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="exm15" label="15" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="exm20" label="20" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="exm25" label="25" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="exm30" label="30" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="exm35" label="35" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="exm40" label="40" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="exm45" label="45" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3" v-model="exm50" label="50" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
                  <v-checkbox class="px-3 pb-6" v-model="exm55" label="55" color="primary" v-show="jobScheduledDisabled" hide-details></v-checkbox>
              </v-card>

              <v-text-field
                  v-model="fullGoogleUrl"
                  prepend-icon="mdi-link"
                  label="Полная ссылка на Google Таблицу"
                  @input="parseGoogleUrl"
              ></v-text-field>
              
              <v-text-field
                  v-model="sheetId"
                  prepend-icon="mdi-google-spreadsheet"
                  label="Уникальный идентификатор листа Google"
                  :error-messages="sheetIdErrors"
                  required
                  @input="$v.sheetId.$touch()"
                  @blur="$v.sheetId.$touch()"
              ></v-text-field>
              <v-text-field
                  v-model="spreadsheetId"
                  prepend-icon="mdi-google-spreadsheet"
                  label="Уникальный идентификатор книги Google"
                  :error-messages="spreadsheetIdErrors"
                  required
                  @input="$v.spreadsheetId.$touch()"
                  @blur="$v.spreadsheetId.$touch()"
              ></v-text-field>
              <v-text-field
                  v-model="rangeAdd"
                  prepend-icon="mdi-google-spreadsheet"
                  label="Наименование листа Google для отгрузки данных"
                  :error-messages="rangeAddErrors"
                  required
                  @input="$v.rangeAdd.$touch()"
                  @blur="$v.rangeAdd.$touch()"
              ></v-text-field>
              
              <v-select
                  v-model="selectedServiceEmail"
                  :items="ServiceEmailList"
                  label="Выберите учетную запись Google для записи данных в Таблицу"
                  prepend-icon="mdi-email"
              >
                  <template v-slot:append-outer>
                      <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="copyEmailToClipboard"
                                  :disabled="!selectedServiceEmail"
                              >
                                  <v-icon>mdi-content-copy</v-icon>
                              </v-btn>
                          </template>
                          <span>Копировать учетную запись</span>
                      </v-tooltip>
                  </template>
              </v-select>

              <v-text-field
                  v-model="schoolURL"
                  prepend-icon="mdi-web"
                  label="URL адрес школы (Например: https://yanavegana.ru)"
                  :error-messages="schoolURLErrors"
                  required
                  @input="$v.schoolURL.$touch()"
                  @blur="$v.schoolURL.$touch()"
              ></v-text-field>
              <v-text-field
                  v-model="strJobUserComment"
                  prepend-icon="mdi-comment-edit-outline"
                  label="Комментарий пользователя"
                  :error-messages="strJobUserCommentErrors"
                  required
                  @input="$v.strJobUserComment.$touch()"
                  @blur="$v.strJobUserComment.$touch()"
              ></v-text-field>
              
              
            </form>
            <v-card
                    class="d-flex justify-space-between mb-6"
                    flat
                    tile
                    >
                        <v-card
                            class="pa-2"
                            flat
                            v-show="showStartButton"
                        >
                            <v-btn
                                class="primary"
                                @click="submit"
                                >
                                запустить задачу
                            </v-btn>
                        </v-card>
                        <v-card
                            class="pa-2"
                            flat
                            v-show="showSaveCopyButtons"
                        >
                            <v-btn
                                class="success"
                                @click="submit"
                                >
                                сохранить задачу
                            </v-btn>
                        </v-card>
                        <v-card
                            class="pa-2"
                            flat
                            v-show="showSaveCopyButtons"
                        >
                            <v-btn
                                class="warning"
                                @click="CopyJobtoNew"
                                >
                                скопировать задачу
                            </v-btn>
                        </v-card>
                        <v-card
                            class="pa-2"
                            flat
                        >
                            <v-btn
                                class="primary"
                                @click="CancelOper"
                                >
                                Отмена
                            </v-btn>
                        </v-card>
                    </v-card>
          </v-card>
          <br>
          <div>
              <v-alert dense  text   type="success"  dismissible v-model="alert1" class="pa-1 ma-1">
                          Задача запущена!
              </v-alert>
              <v-alert dense  text   type="error"  dismissible v-model="alert2" class="pa-1 ma-1">
                          {{ alertMessage }}
              </v-alert>
          </div>
        </v-tab-item>
      </v-tabs-items>
      
      
      
      <v-btn 
        color="success" 
        class="mt-1 mr-2" 
        @click="saveConstructor"
        v-show="false"
      >
        Сохранить конструктор
      </v-btn>
      <v-btn 
        color="info" 
        class="mt-1 mr-2" 
        @click="loadConstructor"
        v-show="false"
      >
        Загрузить конструктор
      </v-btn>      
    </v-container>
 
</template>
  
<script>
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  import { v4 as uuidv4 } from 'uuid'; 
  import { Buffer } from 'buffer';
  import axios from 'axios'
  import store from '../store/store.js'
  import date from 'date-and-time';
  
  import { EditorState, Compartment } from '@codemirror/state'
  import { EditorView, keymap } from '@codemirror/view'
  import { defaultKeymap, history, historyKeymap, indentMore, indentLess } from '@codemirror/commands'
  import { sql } from '@codemirror/lang-sql'
  import { search, searchKeymap } from '@codemirror/search'
  import { basicSetup } from 'codemirror'
  

export default {
  mixins: [validationMixin],

  validations: {
      jobRepeatEvery: { required },
      sheetId: { required },
      spreadsheetId: { required },
      rangeAdd: { required },
      waitTime: { required },
      schoolURL: { required },
      strJobUserComment: { required },
      schoolApiKey: { required }
  },

  data() {
    return {
      showGenerateQueryDialog: false,
      showSaveCopyButtons: false,
      showStartButton: true,
      vAlertWarning: false,
      vAlertOk: false,
      vAlertWarningText: '',
      vAlertOkText: '',
      groups: [],
      options: ["Больше", "Меньше", "Не равно", "Равно", "Между", "Содержит", "NULL", "IS NOT NULL"],
      showField2: false,
      tables: [],
      selectedTableIndex: null,
      functions: ["Функция 1", "Функция 2", "Функция 3"],
      availableTables: [],
      joinConditions: [],
      joinOptions: ["Равно", "Не равно"],
      dateFormats: ['YYYY-MM-DD', 'DD.MM.YYYY', 'DD/MM/YYYY', 'Свой формат'],
      functionNames: {
        noFunction: 'Без функции',
        trim: 'Убр. пробелы',
        toChar: 'Изм. формат',
        count: 'Количество',
        sum: 'Сумма'
      },
      fieldSets: [],
      useOnlySqlQuery: false,
      activeTab: null,
      activeTabItems: ['Конструктор', 'SQL Запрос', 'Параметры задачи'],
      sqlQuery: '',
      resultHeaders: [],
      resultItems: [],
      alias: '',
      joinTypes: ['LEFT JOIN', 'INNER JOIN', 'RIGHT JOIN', 'FULL OUTER JOIN'],
      orderByField: null,
      orderByDirection: 'ASC',
      orderByFieldsAsc: [],
      orderByFieldsDesc: [],
      showReplaceTableDialog: false,
      tableToReplace: null,
      newTableName: null,//--Конец билдера
        selectParams: false,
        rules: [ ],
        show: false,
        uuid: '',
        jobRepeatEvery: '',
        jobRepeatEveryDisabled: false,
        jobScheduled: '',
        jobScheduledDisabled: false,
        jobType: ['Разовая задача', 'Регулярная задача'],
        jobTypeSelect: '',
        alert1: false,
        alert2: false,
        alert3: false,
        alertMessage: '',
        canRunJobCheck: false,
        ex00: 0,
        ex01: 0,
        ex02: 0,
        ex03: 0,
        ex04: 1,
        ex05: 0,
        ex06: 1,
        ex07: 0,
        ex08: 1,
        ex09: 1,
        ex10: 0,
        ex11: 0,
        ex12: 0,
        ex13: 0,
        ex14: 0,
        ex15: 0,
        ex16: 0,
        ex17: 0,
        ex18: 0,
        ex19: 0,
        ex20: 0,
        ex21: 0,
        ex22: 0,
        ex23: 0,
        exm00: 0,
        exm05: 0,
        exm10: 0,
        exm15: 0,
        exm20: 0,
        exm25: 1,
        exm30: 0,
        exm35: 0,
        exm40: 0,
        exm45: 0,
        exm50: 0,
        exm55: 0,
        sheetId: '',
        spreadsheetId: '',
        rangeAdd: '',
        dateStart: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        modal: false,
        waitTime: '1',
        schoolURL: '',
        schoolApiKey: '',
        ExportID: '',
        ShowExportID: false,
        selectedColumns:  [],
        fromGcColumns: [],
        showTimer: false,
        timerEnabled: true,
        timerCount: 100,
        getGKColumnsExpDisabled: false,
        dateCreateAddColumn: false,
        datePayAddColumn: false,
        ClearScheet: true,
        dbTableName: '',
        selectedItemKeys: [],
        strJobUserComment: '',
        editor: null,
        fullGoogleUrl: '',
        selectedServiceEmail: 'analyticsnode-service-account@analyticsnodeapi.iam.gserviceaccount.com',
        ServiceEmailList: [],
        schoolURLRD: false,
    };
  },

  /* async created() {
    this.$store.dispatch('loadpgTables')
    this.availableTables = this.$store.getters.pgTables.map(table => table.table_name);
    //console.log(this.availableTables)            
  }, */

  methods: {
    copyEmailToClipboard() {
        if (this.selectedServiceEmail) {
            navigator.clipboard.writeText(this.selectedServiceEmail).catch(err => {
                console.error('Ошибка при копировании: ', err);
            });
        }
    },
    parseGoogleUrl() {
        if (this.fullGoogleUrl) {
            // Извлекаем ID таблицы
            const spreadsheetIdMatch = this.fullGoogleUrl.match(/\/d\/([a-zA-Z0-9-_]+)/);
            if (spreadsheetIdMatch && spreadsheetIdMatch[1]) {
            this.spreadsheetId = spreadsheetIdMatch[1];
            }
            
            // Извлекаем ID листа
            const sheetIdMatch = this.fullGoogleUrl.match(/[#&]gid=(\d+)/);
            if (sheetIdMatch && sheetIdMatch[1]) {
            this.sheetId = sheetIdMatch[1];
            } else {
            // Если gid не найден, устанавливаем значение по умолчанию "0"
            this.sheetId = "0";
            }
        }
    },

    initCodeMirror() {
      const language = new Compartment()
      
      const russianSearchPlugin = EditorView.theme({
          ".cm-panel.cm-search": {
              padding: "5px",
              backgroundColor: "#f5f5f5",
              borderBottom: "1px solid #ddd"
          },
          ".cm-panel.cm-search input[name=search]": {
              fontSize: "14px",
              marginRight: "5px"
          },
          ".cm-panel.cm-search input[name=replace]": {
              fontSize: "14px",
              marginRight: "5px"
          },
          ".cm-panel.cm-search button": {
              fontSize: "14px",
              marginRight: "5px"
          },
          ".cm-panel.cm-search label": {
              fontSize: "14px"
          }
      })

      const startState = EditorState.create({
        doc: this.sqlQuery,
        extensions: [
            basicSetup,
            language.of(sql()),
            keymap.of([
                ...defaultKeymap,
                ...historyKeymap,
                ...searchKeymap,
                {
                key: "Tab",
                run: (view) => {
                  if (view.state.selection.ranges.some(r => !r.empty)) {
                    return indentMore(view);
                  }
                  view.dispatch(view.state.replaceSelection("  ")); // 4 пробела вместо табуляции
                  return true;
                },
                shift: (view) => {
                  return indentLess(view);
                }
              }
            ]),
            history(),
            EditorView.lineWrapping,
            search({
                top: true,
                caseSensitive: false,
            }),
            russianSearchPlugin,
            EditorView.updateListener.of(update => {
                if (update.docChanged) {
                    this.sqlQuery = update.state.doc.toString()
                }
            }),
            EditorView.theme({
                    "&": {backgroundColor: "white"},
                    ".cm-scroller": {overflow: "auto"},
                    ".cm-content": {
                        fontFamily: "monospace",
                        fontSize: "16px",
                        lineHeight: 1.5
                    },
                    ".cm-line": {
                        padding: "0 3px",
                        lineHeight: 1.6,
                        fontSize: "16px",
                    },
                    // Светлая тема для подсветки синтаксиса
                    ".cm-keyword": {color: "#0000FF"},
                    ".cm-operator": {color: "#000000"},
                    ".cm-number": {color: "#098658"},
                    ".cm-string": {color: "#A31515"},
                    ".cm-comment": {color: "#008000"},
                    ".cm-function": {color: "#795E26"},
                    ".cm-punctuation": {color: "#000000"},
                    ".cm-variableName": {color: "#1a1a1a"},
                    ".cm-type": {color: "#267f99"},
                    ".cm-definition": {color: "#0000FF"},
                    ".cm-property": {color: "#001080"},
                    ".cm-textfield": {color: "#000000", fontSize: "14px"},
                    ".cm-button": {color: "#000000", fontSize: "14px"}
                })
            ]
        })

        this.editor = new EditorView({
            state: startState,
            parent: this.$refs.editor
        })

        // Функция для локализации панели поиска
        let searchPanelLocalized = false;

        const localizeSearchPanel = (searchPanel) => {
          //console.log(1);  // Для отладки
          if (searchPanelLocalized) return;
          //console.log(1);
          
          const inputs = searchPanel.querySelectorAll('input[type="text"]')
          const buttons = searchPanel.querySelectorAll('button')
          const labels = searchPanel.querySelectorAll('label')

          if (inputs[0]) inputs[0].placeholder = 'Найти'
          if (inputs[1]) inputs[1].placeholder = 'Заменить'
          if (buttons[0]) buttons[0].textContent = 'Следующий'
          if (buttons[1]) buttons[1].textContent = 'Предыдущий'
          if (buttons[2]) buttons[2].textContent = 'Все'
          if (buttons[3]) buttons[3].textContent = 'Заменить'
          if (buttons[4]) buttons[4].textContent = 'Заменить все'

          labels.forEach(label => {
            const input = label.querySelector('input[type="checkbox"]')
            if (input) {
              // Удаляем существующий текст
              label.childNodes.forEach(node => {
                if (node.nodeType === Node.TEXT_NODE) {
                  node.remove();
                }
              });
              
              const span = document.createElement('span')
              span.textContent = ' ' // добавляем пробел перед текстом
              
              switch(input.name) {
                case 'case':
                  span.textContent += 'Учит. регистр'
                  break
                case 're':
                  span.textContent += 'Регул. выражение'
                  break
                case 'word':
                  span.textContent += 'Слово целиком'
                  break
              }
              
              label.appendChild(span)
            }
          })

          searchPanelLocalized = true;

          // Добавляем обработчик для кнопки закрытия
          const closeButton = searchPanel.querySelector('button[name="close"]');
          if (closeButton) {
            closeButton.addEventListener('click', () => {
              searchPanelLocalized = false;
            });
          }
        }

        const checkAndLocalizeSearchPanel = () => {
          const searchPanel = this.$refs.editor.querySelector('.cm-panel.cm-search')
          if (searchPanel) {
            localizeSearchPanel(searchPanel)
          }
        }

        // Наблюдатель за изменениями в DOM
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type === 'childList') {
              const searchPanel = this.$refs.editor.querySelector('.cm-panel.cm-search')
              if (searchPanel) {
                localizeSearchPanel(searchPanel)
              } else {
                // Если панель поиска была удалена, сбрасываем флаг
                searchPanelLocalized = false;
              }
            }
          })
        })

        // Начинаем наблюдение за DOM
        observer.observe(this.$refs.editor, { childList: true, subtree: true })

        // Добавляем слушатель события для проверки локализации при каждом открытии панели поиска
        this.editor.dom.addEventListener('keydown', (event) => {
          if ((event.ctrlKey || event.metaKey) && (event.key === 'f' || event.key === 'r')) {
            setTimeout(() => {
              searchPanelLocalized = false;  // Сбрасываем флаг перед новой локализацией
              checkAndLocalizeSearchPanel();
            }, 0)
          }
        })

        window.addEventListener('resize', () => {
          this.editor.requestMeasure()
        })
      },

    moveFieldUp(index) {
      if (index > 0) {
        const temp = this.fieldSets[index];
        this.$set(this.fieldSets, index, this.fieldSets[index - 1]);
        this.$set(this.fieldSets, index - 1, temp);
      }
    },

    moveFieldDown(index) {
      if (index < this.fieldSets.length - 1) {
        const temp = this.fieldSets[index];
        this.$set(this.fieldSets, index, this.fieldSets[index + 1]);
        this.$set(this.fieldSets, index + 1, temp);
      }
    },

    customFilter(item, queryText, itemText) {
      const searchText = queryText.toLowerCase();
      let textToSearch;
      
      if (typeof item === 'string') {
        textToSearch = item.toLowerCase();
      } else if (item && item.selectedTableName) {
        textToSearch = item.selectedTableName.toLowerCase();
      } else if (itemText) {
        textToSearch = itemText.toLowerCase();
      } else {
        textToSearch = '';
      }
      
      return textToSearch.includes(searchText);
    },

    customFilterColumn(item, queryText) {
        const searchText = queryText.toLowerCase();
        const columnName = item.fullName.toLowerCase();
        const columnType = item.type.toLowerCase();
        return columnName.includes(searchText) || columnType.includes(searchText);
      },

    generateQuery() {
      this.showGenerateQueryDialog = true;
      // Обновляем содержимое CodeMirror после генерации запроса
      if (this.editor) {
        this.editor.dispatch({
          changes: {from: 0, to: this.editor.state.doc.length, insert: this.sqlQuery}
        })
      }
    },

    confirmGenerateQuery() {
      // Здесь разместим текущую логику generateQuery
      this.vAlertOk = false;
      this.vAlertOkText = '';
      this.vAlertWarning = false;
      this.vAlertWarningText = '';

      this.resultItems = [];

      let query = 'SELECT\n';

      // Генерация списка выбираемых полей
      const selectedFields = this.generateSelectedFields();
      query += selectedFields.join(',\n');

      // Генерация FROM части
      query += this.generateFromClause();

      // Генерация JOIN части
      query += this.generateJoinClause();

      // Генерация WHERE части
      query += this.generateWhereClause();

      // Генерация GROUP BY части
      query += this.generateGroupByClause();

      // Генерация ORDER BY части
      query += this.generateOrderByClause();

      // Обновляем значение sqlQuery
      this.sqlQuery = query;

      // Переключаемся на вкладку SQL запроса
      setTimeout(() => {
        this.activeTab = 1;
      }, 100);

      // Закрываем диалоговое окно
      this.showGenerateQueryDialog = false;
    },

    cancelGenerateQuery() {
      this.showGenerateQueryDialog = false;
    },

    generateSelectedFields() {
      return this.fieldSets.map(fieldSet => {
        let field = this.generateField(fieldSet);
        return '  ' + field; // Добавляем отступ в начале каждой строки
      });
    },

    generateField(fieldSet) {
      if (fieldSet.type === 'field') {
        let field = this.quoteColumnName(fieldSet.column);
        if (fieldSet.function !== this.functionNames.noFunction) {
          if (fieldSet.function === this.functionNames.toChar) {
            field = `TO_CHAR(${field}, '${fieldSet.dateFormat}')`;
          } else if (fieldSet.function === this.functionNames.trim) {
            if (this.isStringType(fieldSet.columnType)) {
              field = `TRIM(${field})`;
            } else {
              console.warn(`TRIM не может быть применен к колонке ${fieldSet.column} типа ${fieldSet.columnType}`);
            }
          } else if (fieldSet.function === this.functionNames.count) {
            field = `COUNT(${field})`;
          } else if (fieldSet.function === this.functionNames.sum) {
            field = `SUM(${field})`;
          } else {
            field = `${fieldSet.function}(${field})`;
          }
        }
        return fieldSet.alias ? `${field} AS "${fieldSet.alias}"` : field;
      } else if (fieldSet.type === 'condition') {
        return this.generateCaseWhen(fieldSet);
      } else if (fieldSet.type === 'subquery') {
        const subquery = this.generateSubquery(fieldSet);
        return `(${subquery}) AS "${fieldSet.subquery.alias || 'subquery'}"`;
      }
    },

    generateGroupByClause() {
      const hasAggregateFunction = this.fieldSets.some(fieldSet => 
        fieldSet.function === this.functionNames.count || 
        fieldSet.function === this.functionNames.sum ||
        (fieldSet.type === 'condition' && fieldSet.caseAggregateFunction !== 'Без функции')
      );

      if (!hasAggregateFunction) {
        return '';
      }

      const groupByColumns = this.fieldSets
        .filter(fieldSet => 
          (fieldSet.type === 'field' && 
          fieldSet.function !== this.functionNames.count && 
          fieldSet.function !== this.functionNames.sum) ||
          (fieldSet.type === 'condition' && fieldSet.caseAggregateFunction === 'Без функции')
        )
        .map(fieldSet => {
          if (fieldSet.type === 'field') {
            return this.quoteColumnName(fieldSet.column);
          } else if (fieldSet.type === 'condition') {
            // Для CASE без агрегатной функции добавляем всё выражение в GROUP BY
            return this.generateCaseWhen(fieldSet);
          }
        });

      if (groupByColumns.length > 0) {
        return '\nGROUP BY ' + groupByColumns.join(', ');
      }
      return '';
    },

    // Новый метод для проверки, является ли тип строковым
    isStringType(columnType) {
      const stringTypes = ['character varying', 'varchar', 'character', 'char', 'text', 'string'];
      return stringTypes.includes(columnType.toLowerCase());
    },
    
    generateFromClause() {
      if (this.tables.length === 0) return '';
      return `\nFROM ${this.quoteTableName(this.tables[0].selectedTableName)}`;
    },
    

    generateJoinClause() {
      if (this.tables.length <= 1) return '';
      
      return this.joinConditions.map((join, index) => {
        const joinTable = this.quoteTableName(this.tables[index + 1].selectedTableName);
        const joinType = join.joinType || 'LEFT JOIN'; // По умолчанию используем LEFT JOIN
        
        let column1 = this.quoteColumnName(join.column1);
        let column2 = this.quoteColumnName(join.column2);

        // Применяем LOWER и TRIM для строковых колонок
        if (join.column1Type === 'string') {
          column1 = `LOWER(TRIM(${column1}))`;
        }
        if (join.column2Type === 'string') {
          column2 = `LOWER(TRIM(${column2}))`;
        }

        return `\n${joinType} ${joinTable} ON ${column1} ${this.convertOperator(join.option)} ${column2}`;
      }).join('');
    },

    onJoinColumnChange(condition, columnKey) {
      const selectedColumn = this.getAllColumns().find(col => col.fullName === condition[columnKey]);
      if (selectedColumn) {
        condition[columnKey + 'Type'] = selectedColumn.type;
      }
    },

    quoteColumnName(columnName) {
      if (columnName.includes('.')) {
        const [tableName, colName] = columnName.split('.');
        return `"${tableName}"."${colName}"`;
      }
      return `"${columnName}"`;
    },

    // Новый метод для оборачивания имен таблиц в кавычки
    quoteTableName(tableName) {
      return `"${tableName}"`;
    },

    generateWhereClause() {
      if (this.groups.length === 0) return '';

      const whereClauses = this.groups.map((group, index) => {
        console.log(`Group ${index} operator:`, group.operator);
        const conditions = group.sets.map(set => {
          let operator = this.convertOperator(set.option);
          let column = this.quoteColumnName(set.column);
          let columnType = this.getColumnType(set.column);
          
          // Применяем TRIM и LOWER к строковым полям для оператора 'Содержит'
          if (columnType === 'string') {
            if (operator === 'LIKE') {
              column = `LOWER(TRIM(${column}))`;
            } else {
              column = `TRIM(${column})`;
            }
          }

          let condition = `${column} ${operator}`;

          if (operator === 'BETWEEN') {
            condition += ` ${this.formatValue(set.column, set.field1, columnType)} AND ${this.formatValue(set.column, set.field2, columnType)}`;
          } else if (operator !== 'IS NULL' && operator !== 'IS NOT NULL') {
            condition += ` ${this.formatValue(set.column, set.field1, columnType, set.option)}`;
          }
          return condition;
        });
        const groupOperator = group.operator === 0 ? 'AND' : 'OR';
        return `(${conditions.join(` ${groupOperator} `)})`;
      });

      return `\nWHERE ${whereClauses.join(' AND ')}`;
    },

    formatValue(column, value, columnType, option) {
      if (value === null || value === undefined) return 'NULL';
      
      switch (columnType) {
        case 'number':
          return value;
        case 'boolean':
          return value ? 'TRUE' : 'FALSE';
        case 'date':
          return `'${value}'::date`;
        case 'string':
        default:
          if (option === 'Содержит') {
            // Для оператора 'Содержит' приводим значение к нижнему регистру и добавляем '%'
            return `'%${value.toLowerCase().replace(/'/g, "''")}%'`;
          } else {
            // Для других операторов просто экранируем одинарные кавычки
            return `'${value.replace(/'/g, "''")}'`;
          }
      }
    },

    convertOperator(option) {
      const operatorMap = {
        'Больше': '>',
        'Меньше': '<',
        'Не равно': '!=',
        'Равно': '=',
        'Между': 'BETWEEN',
        'Содержит': 'LIKE',
        'NULL': 'IS NULL',
        'IS NOT NULL': 'IS NOT NULL'
      };
      return operatorMap[option] || option;
    },

    generateCaseWhen(fieldSet) {
      let caseStatement = 'CASE';
      fieldSet.caseConditions.forEach(condition => {
        let ifColumn = this.quoteColumnName(condition.ifColumn);
        if (condition.ifFunction !== this.functionNames.noFunction) {
          if (condition.ifFunction === this.functionNames.trim) {
            ifColumn = `TRIM(${ifColumn})`;
          } else if (condition.ifFunction === this.functionNames.toChar) {
            ifColumn = `TO_CHAR(${ifColumn}, '${condition.ifDateFormat}')`;
          }
        }
        let operator = this.convertOperator(condition.ifOption);
        caseStatement += ` WHEN ${ifColumn} ${operator} `;
        if (operator !== 'IS NULL' && operator !== 'IS NOT NULL') {
          if (condition.ifColumnType === 'date') {
            caseStatement += `'${condition.ifDateValue}'::date `;
          } else {
            caseStatement += `'${condition.ifValue}' `;
          }
        }
        caseStatement += 'THEN ';
        if (condition.thenType === 'value') {
          caseStatement += `'${condition.thenValue}' `;
        } else {
          let thenColumn = this.quoteColumnName(condition.thenColumn);
          if (condition.thenFunction !== this.functionNames.noFunction) {
            if (condition.thenFunction === this.functionNames.toChar) {
              thenColumn = `TO_CHAR(${thenColumn}, '${condition.thenDateFormat}')`;
            } else if (condition.thenFunction === this.functionNames.trim) {
              if (this.isStringType(condition.thenColumnType)) {
                thenColumn = `TRIM(${thenColumn})`;
              } else {
                console.warn(`TRIM не может быть применен к колонке ${condition.thenColumn} типа ${condition.thenColumnType}`);
              }
            } else {
              thenColumn = `${condition.thenFunction}(${thenColumn})`;
            }
          }
          caseStatement += thenColumn;
        }
      });
      
      caseStatement += ' ELSE ';
      if (fieldSet.elseType === 'value') {
        caseStatement += `'${fieldSet.elseValue}' `;
      } else {
        let elseColumn = this.quoteColumnName(fieldSet.elseColumn);
        if (fieldSet.elseFunction !== this.functionNames.noFunction) {
          if (fieldSet.elseFunction === this.functionNames.toChar) {
            elseColumn = `TO_CHAR(${elseColumn}, '${fieldSet.elseDateFormat}')`;
          } else if (fieldSet.elseFunction === this.functionNames.trim) {
            elseColumn = `TRIM(${elseColumn})`;
          } else {
            elseColumn = `${fieldSet.elseFunction}(${elseColumn})`;
          }
        }
        caseStatement += elseColumn;
      }
      
      caseStatement += ' END';
      
      // Применяем агрегатную функцию, если она выбрана
      if (fieldSet.caseAggregateFunction === 'Количество') {
        caseStatement = `COUNT(${caseStatement})`;
      } else if (fieldSet.caseAggregateFunction === 'Сумма') {
        caseStatement = `SUM(${caseStatement})`;
      }
      
      // Используем новый алиас для всего CASE выражения, если он задан
      if (fieldSet.caseAlias) {
        return `${caseStatement} AS "${fieldSet.caseAlias}"`;
      } else {
        return caseStatement;
      }
    },

    generateSubquery(fieldSet) {
      let subquery = 'SELECT ';

      // Генерация списка выбираемых полей подзапроса
      const subqueryFields = fieldSet.subquery.fieldSets.map(subFieldSet => {
        let field = this.quoteColumnName(subFieldSet.column);
        if (subFieldSet.function !== this.functionNames.noFunction) {
          if (subFieldSet.function === this.functionNames.toChar) {
            field = `TO_CHAR(${field}, '${subFieldSet.dateFormat}')`;
          } else if (subFieldSet.function === this.functionNames.trim) {
            field = `TRIM(${field})`;
          } else if (subFieldSet.function === this.functionNames.count) {
            field = `COUNT(${field})`;
          } else if (subFieldSet.function === this.functionNames.sum) {
            field = `SUM(${field})`;
          } else {
            field = `${subFieldSet.function}(${field})`;
          }
        }
        return subFieldSet.alias ? `${field} AS "${subFieldSet.alias}"` : field;
      });
      subquery += subqueryFields.join(', ');

      // Генерация FROM части подзапроса
      subquery += ` FROM ${this.quoteTableName(fieldSet.subquery.tables[0].selectedTableName)}`;

      // Генерация WHERE части подзапроса
      const whereClause = this.generateSubqueryWhereClause(fieldSet.subquery);
      if (whereClause) {
        subquery += whereClause;
      }

      return subquery;
    },

    generateSubqueryWhereClause(fieldSet) {
      //console.log('Generating subquery where clause for fieldSet:', JSON.stringify(fieldSet, null, 2));

      if (!fieldSet || !fieldSet.whereGroups) {
        console.warn('Invalid fieldSet structure for subquery');
        return '';
      }

      if (fieldSet.whereGroups.length === 0) {
        console.log('No where groups in subquery');
        return '';
      }

      const whereClauses = fieldSet.whereGroups.map(group => {
        if (!group || !group.sets) {
          console.warn('Invalid group structure in subquery');
          return '';
        }

        const conditions = group.sets.map(set => {
          if (!set || !set.column) {
            console.warn('Invalid set structure in subquery');
            return '';
          }

          let condition = '';
          let operator = this.convertOperator(set.option);
          let column = this.quoteColumnName(set.column);
          
          // Получаем тип колонки
          const columnType = set.columnType || this.getColumnType(set.column, fieldSet);
          console.log(`Generating condition for column ${set.column}, type: ${columnType}`);
          
          // Применяем TRIM и LOWER к строковым полям для оператора 'Содержит'
          if (columnType === 'string' && operator !== 'IS NULL' && operator !== 'IS NOT NULL') {
            if (operator === 'LIKE') {
              column = `LOWER(TRIM(${column}))`;
            } else {
              column = `TRIM(${column})`;
            }
          }
          
          condition = `${column} ${operator}`;
          
          if (set.useMainTableField && set.mainTableColumn) {
            // Если используется поле из основной таблицы
            let mainTableColumn = this.quoteColumnName(set.mainTableColumn);
            const mainColumnType = this.getColumnType(set.mainTableColumn);
            
            if (mainColumnType === 'string' && operator !== 'IS NULL' && operator !== 'IS NOT NULL') {
              if (operator === 'LIKE') {
                mainTableColumn = `LOWER(TRIM(${mainTableColumn}))`;
              } else {
                mainTableColumn = `TRIM(${mainTableColumn})`;
              }
            }
            
            condition += ` ${mainTableColumn}`;
          } else if (operator === 'IS NULL' || operator === 'IS NOT NULL') {
            // Для IS NULL и IS NOT NULL не нужно добавлять значение
          } else if (operator === 'BETWEEN') {
            if (columnType === 'date') {
              condition += ` '${set.dateValue || ''}' AND '${set.dateValue2 || ''}'`;
            } else {
              condition += ` ${this.formatValue(set.column, set.value, columnType)} AND ${this.formatValue(set.column, set.value2, columnType)}`;
            }
          } else {
            if (columnType === 'date') {
              condition += ` '${set.dateValue || ''}'`;
            } else {
              condition += ` ${this.formatValue(set.column, set.value, columnType, set.option)}`;
            }
          }
          
          return condition;
        }).filter(condition => condition !== ''); // Удаляем пустые условия

        // Используем 'AND' или 'OR' в зависимости от выбора пользователя
        const groupOperator = group.operator === 'И' ? 'AND' : 'OR';
        return conditions.length > 0 ? `(${conditions.join(` ${groupOperator} `)})` : '';
      }).filter(clause => clause !== ''); // Удаляем пустые группы условий

      return whereClauses.length > 0 ? ` WHERE ${whereClauses.join(' AND ')}` : '';
    },

      // Методы для работы с таблицами подзапроса
      addSubqueryTable(fieldSet) {
        fieldSet.subquery.tables.push({
          name: `Таблица ${fieldSet.subquery.tables.length + 1}`,
          selectedTableName: null,
          columns: []
        });
      },

      removeSubqueryTable(fieldSet, index) {
        fieldSet.subquery.tables.splice(index, 1);
      },

      onSubqueryTableChange(fieldSet, table) {
        table.name = table.selectedTableName;
        if(table.name !== null){
          const settingTableName = {
            strTableName: table.name,
          };

          // Очищаем существующие колонки таблицы
          table.columns = [];

          // Загружаем колонки для выбранной таблицы
          this.$store.dispatch('loadpgColumnsTables', settingTableName)
            .then(() => {
              const pgColumnsTables = this.$store.getters.pgColumnsTables;

              table.columns = pgColumnsTables.map(column => ({
                name: `${table.name}.${column.column_name}`,
                fullName: `${table.name}.${column.column_name}`,
                type: this.mapColumnType(column.data_type)
              }));

              // Обновляем поля и условия WHERE подзапроса, которые могли ссылаться на удаленные колонки
              this.updateSubqueryFieldsAndConditions(fieldSet);

              //console.log('Columns loaded for subquery table:', table.name, table.columns);
            })
            .catch(error => {
              console.error('Error loading columns for subquery table:', error);
              // Здесь можно добавить обработку ошибок, например, показать уведомление пользователю
            });
          }
      },

      updateSubqueryFieldsAndConditions(fieldSet) {
        // Обновляем поля подзапроса
        fieldSet.subquery.fieldSets = fieldSet.subquery.fieldSets.filter(subFieldSet => {
          const [tableName, columnName] = subFieldSet.column ? subFieldSet.column.split('.') : [];
          return fieldSet.subquery.tables.some(table => 
            table.name === tableName && table.columns.some(col => col.name.endsWith(columnName))
          );
        });

        // Обновляем условия WHERE подзапроса
        fieldSet.subquery.whereGroups.forEach(group => {
          group.sets = group.sets.filter(set => {
            const [tableName, columnName] = set.column ? set.column.split('.') : [];
            return fieldSet.subquery.tables.some(table => 
              table.name === tableName && table.columns.some(col => col.name.endsWith(columnName))
            );
          });
        });

        // Удаляем пустые группы WHERE
        fieldSet.subquery.whereGroups = fieldSet.subquery.whereGroups.filter(group => group.sets.length > 0);
      },

      // Методы для работы с полями подзапроса
      addSubqueryFieldSet(fieldSet) {
        fieldSet.subquery.fieldSets.push({
          column: null,
          function: this.functionNames.noFunction,
          alias: '',
          dateFormat: null,
          showFormatField: false,
          columnType: null
        });
      },
      removeSubqueryFieldSet(fieldSet, index) {
        fieldSet.subquery.fieldSets.splice(index, 1);
      },
      onSubqueryColumnChange(fieldSet, subFieldSet) {
        const selectedColumn = this.getSubqueryColumns(fieldSet).find(col => col.name === subFieldSet.column);
        if (selectedColumn) {
          subFieldSet.columnType = selectedColumn.type;
          
          // Устанавливаем функцию по умолчанию в зависимости от типа колонки
          if (selectedColumn.type === 'string') {
            subFieldSet.function = this.functionNames.trim;
          } else {
            subFieldSet.function = this.functionNames.noFunction;
          }
          
          // Устанавливаем alias по умолчанию
          subFieldSet.alias = selectedColumn.name.split('.').pop();
          
          // Сбрасываем поле формата даты
          subFieldSet.dateFormat = null;
          subFieldSet.showFormatField = false;

          // Вызываем обработчик изменения функции для обновления связанных полей
          this.onSubqueryFunctionChange(fieldSet, subFieldSet);

          //console.log('Subquery column changed:', subFieldSet);
        }
      },

      onSubqueryFunctionChange(fieldSet, subFieldSet) {
        // Определяем, нужно ли показывать поле для формата даты
        subFieldSet.showFormatField = subFieldSet.function === this.functionNames.toChar;

        if (!subFieldSet.showFormatField) {
          // Если формат даты не нужен, сбрасываем его
          subFieldSet.dateFormat = null;
        } else if (!subFieldSet.dateFormat) {
          // Если формат даты нужен, но не установлен, устанавливаем значение по умолчанию
          subFieldSet.dateFormat = this.dateFormats[0];
        }

        // Обновляем alias только если он еще не был установлен или соответствует стандартному формату
        const defaultAlias = subFieldSet.column ? subFieldSet.column.split('.').pop() : '';
        let functionAlias = defaultAlias;

        if (subFieldSet.function === this.functionNames.trim) {
          functionAlias = `${defaultAlias}`;
        } else if (subFieldSet.function === this.functionNames.toChar) {
          functionAlias = `${defaultAlias}`;
        } else if (subFieldSet.function === this.functionNames.count) {
          functionAlias = `${defaultAlias}`;
        } else if (subFieldSet.function === this.functionNames.sum) {
          functionAlias = `${defaultAlias}`;
        }

        if (!subFieldSet.alias || subFieldSet.alias === defaultAlias || subFieldSet.alias.startsWith('TRIM_') || subFieldSet.alias.startsWith('FORMATTED_') || subFieldSet.alias.startsWith('COUNT_') || subFieldSet.alias.startsWith('SUM_')) {
          subFieldSet.alias = functionAlias;
        }

        console.log('Subquery function changed:', subFieldSet);
      },

      // Методы для работы с условиями WHERE подзапроса
      addSubqueryWhereGroup(fieldSet) {
        fieldSet.subquery.whereGroups.push({ sets: [], operator: "И" });
      },
      removeSubqueryWhereGroup(fieldSet, index) {
        fieldSet.subquery.whereGroups.splice(index, 1);
      },

      addSubqueryWhereSet(fieldSet, groupIndex) {
        fieldSet.subquery.whereGroups[groupIndex].sets.push({
          column: null,
          columnType: null,
          option: null,
          value: null,
          dateValue: null,
          dateMenu: false,
          showField2: false,
          value2: null,
          dateValue2: null,
          dateMenu2: false,
          useMainTableField: false,
          mainTableColumn: null
        });
      },

      getMainTableColumns() {
        if (this.tables.length > 0 && this.tables[0].columns) {
          return this.tables[0].columns;
        }
        return [];
      },

      removeSubqueryWhereSet(fieldSet, groupIndex, setIndex) {
        fieldSet.subquery.whereGroups[groupIndex].sets.splice(setIndex, 1);
        
        // Если это был последний набор в группе, удаляем всю группу
        if (fieldSet.subquery.whereGroups[groupIndex].sets.length === 0) {
          fieldSet.subquery.whereGroups.splice(groupIndex, 1);
        }
      },

      onSubqueryWhereOptionChange(fieldSet, groupIndex, setIndex, set) {
        switch (set.option) {
          case 'NULL':
          case 'IS NOT NULL':
            set.value = null;
            set.value2 = null;
            set.dateValue = null;
            set.dateValue2 = null;
            set.showField2 = false;
            break;
          case 'Между':
            set.showField2 = true;
            if (set.columnType === 'date') {
              if (!set.dateValue) set.dateValue = new Date().toISOString().substr(0, 10);
              if (!set.dateValue2) set.dateValue2 = new Date().toISOString().substr(0, 10);
            } else {
              if (set.value === null) set.value = '';
              if (set.value2 === null) set.value2 = '';
            }
            break;
          default:
            set.showField2 = false;
            if (set.columnType === 'date' && !set.dateValue) {
              set.dateValue = new Date().toISOString().substr(0, 10);
            }
            if (set.value === null) set.value = '';
        }
      },

      onSubqueryWhereColumnChange(fieldSet, groupIndex, setIndex, set) {
        const selectedColumn = this.getSubqueryColumns(fieldSet).find(col => col.name === set.column);
        if (selectedColumn) {
          set.columnType = selectedColumn.type;
          set.option = null;
          set.value = null;
          set.value2 = null;
          set.dateValue = null;
          set.dateValue2 = null;
          set.showField2 = false;

          if (selectedColumn.type === 'date') {
            set.dateMenu = false;
            set.dateMenu2 = false;
            set.dateValue = new Date().toISOString().substr(0, 10);
          }
        }
      },

      showSubqueryWhereValueField(set) {
        return !set.useMainTableField && set.option !== 'NULL' && set.option !== 'IS NOT NULL';
      },

      onUseMainTableFieldChange(set) {
        if (set.useMainTableField) {
          set.value = null;
          set.value2 = null;
          set.dateValue = null;
          set.dateValue2 = null;
        } else {
          set.mainTableColumn = null;
        }
      },

      // Вспомогательные методы
      getSubqueryColumns(fieldSet) {
        // Возвращает все колонки из таблиц подзапроса
        let allColumns = [];
        fieldSet.subquery.tables.forEach(table => {
          if (table.selectedTableName && table.columns) {
            allColumns = allColumns.concat(table.columns);
          }
        });
        return allColumns;
      },

      

      onFieldSetTypeChange(fieldSet) {
        if (fieldSet.type === 'subquery') {
          if (!fieldSet.subquery) {
            fieldSet.subquery = {
              activePanels: [],
              selectedTableIndex: null,
              tables: [],
              fieldSets: [],
              whereGroups: [{
                sets: [],
                operator: 'И'
              }],
              alias: ''
            };
          }
        } else if (fieldSet.type === 'condition') {
          if (!fieldSet.caseConditions || fieldSet.caseConditions.length === 0) {
            this.addCaseCondition(fieldSet);
          }
        } else {
          // Если тип изменился с 'condition' или 'subquery' на что-то другое, очищаем соответствующие свойства
          fieldSet.caseConditions = [];
          fieldSet.subquery = null;
        }
      },

      addGroup() {
        this.groups.push({ sets: [], operator: "И" });
      },

      addSet(group) {
        group.sets.push({ 
          column: null, 
          columnType: null,
          option: null, 
          field1: "", 
          field2: "", 
          showField2: false,
          value: null,
          dateMenu: false,
          dateMenu2: false
        });
      },

    removeSet(group, index) {
      group.sets.splice(index, 1);
    },

    removeGroupsSet(group, index) {
      group.splice(index, 1);
    },

    onOptionChange(set) {
      //console.log(set);
      
      const optionProperty = 'ifOption' in set ? 'ifOption' : 'option';
      const valueProperty = 'ifValue' in set ? 'ifValue' : 'field1';
      const value2Property = 'ifValue2' in set ? 'ifValue2' : 'field2';
      const dateValueProperty = 'ifDateValue' in set ? 'ifDateValue' : 'field1';
      const dateValue2Property = 'ifDateValue2' in set ? 'ifDateValue2' : 'field2';

      const today = new Date().toISOString().substr(0, 10);

      if (set[optionProperty] === "NULL" || set[optionProperty] === "IS NOT NULL") {
        set[valueProperty] = "";
        set[value2Property] = "";
        set.showField2 = false;
      } else if (set[optionProperty] === "Между") {
        set.showField2 = true;
        if (set.columnType === 'date' || set.ifColumnType === 'date') {
          if (!set[dateValueProperty]) set[dateValueProperty] = today;
          set[dateValue2Property] = today;  // Устанавливаем вторую дату на сегодня
        }
      } else {
        set.showField2 = false;
        if ((set.columnType === 'date' || set.ifColumnType === 'date') && !set[dateValueProperty]) {
          set[dateValueProperty] = today;
        }
      }

      if ('ifOption' in set) {
        set.showIfValueField = set[optionProperty] !== "NULL" && set[optionProperty] !== "IS NOT NULL";
        set.showIfField2 = set[optionProperty] === "Между";
      }
    },

    showValueField(set) {
      const optionProperty = 'ifOption' in set ? 'ifOption' : 'option';
      return set[optionProperty] !== "NULL" && set[optionProperty] !== "IS NOT NULL";
    },

    removeTable(index) {
      const removedTable = this.tables[index];
      this.tables.splice(index, 1);
      this.cleanupAfterTableRemoval(removedTable);
      
      if (this.selectedTableIndex >= this.tables.length) {
        this.selectedTableIndex = this.tables.length - 1;
      }
    },

    cleanupAfterTableRemoval(removedTable) {
        // Очистка выбранных полей
        this.fieldSets = this.fieldSets.filter(fieldSet => 
            fieldSet.column && !fieldSet.column.startsWith(removedTable.selectedTableName + '.')
        );

        // Очистка условий JOIN
        this.joinConditions = this.joinConditions.filter(condition => 
            (condition.column1 && !condition.column1.startsWith(removedTable.selectedTableName + '.')) &&
            (condition.column2 && !condition.column2.startsWith(removedTable.selectedTableName + '.'))
        );

        // Очистка условий WHERE
        this.groups.forEach(group => {
            group.sets = group.sets.filter(set => 
            set.column && !set.column.startsWith(removedTable.selectedTableName + '.')
            );
        });

        // Удаление пустых групп в условиях WHERE
        this.groups = this.groups.filter(group => group.sets.length > 0);

        // Скрытие блока JOIN, если осталась одна таблица
        if (this.tables.length <= 1) {
            this.joinConditions = [];
        }
    },

    // Обновим существующий метод addTable
    addTable() {
      this.tables.push({
        name: `Таблица ${this.tables.length + 1}`,
        selectedTableName: null,
        tableColumnSets: [],
        columns: []
      });
      this.selectedTableIndex = this.tables.length - 1;
    },

    addTableColumnSet(table) {
      table.tableColumnSets.push({
        column: null,
        function: this.functionNames.noFunction,
        alias: '',
        dateFormat: null,
        showFormatField: false,
        columnType: null
      });
    },

    onColumnChange(fieldSet) {
      const selectedColumn = this.getAllColumns().find(col => col.name === fieldSet.column);
      if (selectedColumn) {
        fieldSet.columnType = selectedColumn.type;
        
        // Устанавливаем функцию по умолчанию в зависимости от типа колонки
        if (selectedColumn.type === 'string') {
          fieldSet.function = this.functionNames.trim;
        } else if (selectedColumn.type === 'date') {
          fieldSet.function = this.functionNames.toChar;
        } else if (selectedColumn.type === 'number') {
          fieldSet.function = this.functionNames.noFunction; // Оставляем 'Без функции' по умолчанию для числовых полей
        } else {
          fieldSet.function = this.functionNames.noFunction;
        }
        
        // Устанавливаем alias по умолчанию
        fieldSet.alias = selectedColumn.name.split('.').pop();
        
        // Сбрасываем поле формата даты
        fieldSet.dateFormat = null;
        fieldSet.showFormatField = false;

        // Если тип колонки - дата, устанавливаем формат даты по умолчанию
        if (selectedColumn.type === 'date') {
          fieldSet.dateFormat = this.dateFormats[0]; // Предполагается, что у вас есть массив dateFormats
          fieldSet.showFormatField = true;
        }

        // Инициализируем поля для выбора даты, если это поле типа date
        if (selectedColumn.type === 'date') {
          fieldSet.dateMenu = false;
          fieldSet.dateMenu2 = false;
          fieldSet.field1 = new Date().toISOString().substr(0, 10); // Текущая дата как значение по умолчанию
          fieldSet.field2 = new Date().toISOString().substr(0, 10); // Для случая "Между"
        } else {
          fieldSet.field1 = '';
          fieldSet.field2 = '';
        }

        // Вызываем обработчик изменения функции для обновления связанных полей
        this.onFunctionChange(fieldSet);

        console.log('Column changed:', fieldSet);
      }
    },

    addCaseCondition(fieldSet) {
      if (!fieldSet.caseConditions) {
        fieldSet.caseConditions = [];
      }
      const today = new Date().toISOString().substr(0, 10);
      fieldSet.caseConditions.push({
        ifColumn: null,
        ifColumnType: null,
        ifFunction: this.functionNames.noFunction,
        ifOption: null,
        ifValue: '',
        ifValue2: '',
        ifDateValue: null,
        ifDateValue2: today,  // Устанавливаем значение по умолчанию
        ifDateMenu: false,
        ifDateMenu2: false,
        showIfValueField: true,
        showIfField2: false,
        thenType: 'value',  // Устанавливаем 'value' по умолчанию
        thenValue: '',  // Добавляем пустое значение по умолчанию
        thenColumn: null,
        thenColumnType: null,
        thenFunction: this.functionNames.noFunction,
        thenDateFormat: null,
        showThenFormatField: false,
        thenAlias: ''
      });
    },

    removeCaseCondition(fieldSet, index) {
      fieldSet.caseConditions.splice(index, 1);
    },

    onElseColumnChange(fieldSet) {
      const selectedColumn = this.getAllColumns().find(col => col.name === fieldSet.elseColumn);
      if (selectedColumn) {
        fieldSet.elseColumnType = selectedColumn.type;
        if (selectedColumn.type === 'string') {
          fieldSet.elseFunction = this.functionNames.trim;
        } else {
          fieldSet.elseFunction = this.functionNames.noFunction;
        }
        // Устанавливаем значение по умолчанию для поля "Выводить как"
        fieldSet.elseAlias = selectedColumn.name.split('.').pop();
        this.onElseFunctionChange(fieldSet);
      }
    },

    onElseFunctionChange(fieldSet) {
      fieldSet.showElseFormatField = fieldSet.elseFunction === this.functionNames.toChar;
      if (!fieldSet.showElseFormatField) {
        fieldSet.elseDateFormat = null;
      } else if (!fieldSet.elseDateFormat) {
        fieldSet.elseDateFormat = this.dateFormats[0];
      }
      
      // Обновляем alias, если функция изменилась
      const columnName = fieldSet.elseColumn ? fieldSet.elseColumn.split('.').pop() : '';
      if (fieldSet.elseFunction === this.functionNames.trim) {
        fieldSet.elseAlias = `${columnName}`;
      } else if (fieldSet.elseFunction === this.functionNames.toChar) {
        fieldSet.elseAlias = `${columnName}`;
      } else {
        fieldSet.elseAlias = columnName;
      }
    },

    getFunctionsForColumn(item, prefix = '') {
      const baseFunctions = [this.functionNames.noFunction];
      const columnType = prefix ? item[prefix + 'ColumnType'] : item.columnType;
      
      if (columnType === 'string') {
        baseFunctions.push(this.functionNames.trim);
      }
      if (columnType === 'date') {
        baseFunctions.push(this.functionNames.toChar);
      }
      // Добавляем COUNT и SUM только если это не ТО или ИНАЧЕ в блоке УСЛОВИЕ
      if (columnType === 'number' && prefix !== 'then' && prefix !== 'else') {
        baseFunctions.push(this.functionNames.count, this.functionNames.sum);
      }
      return baseFunctions;
    },

    getColumnType(columnName, fieldSet = null) {
        let columns = this.getAllColumns();
        
        // Если передан fieldSet и это подзапрос, используем колонки подзапроса
        if (fieldSet && fieldSet.type === 'subquery') {
            columns = this.getSubqueryColumns(fieldSet);
        }
        
        const column = columns.find(col => col.fullName === columnName || col.name === columnName);
        if (column) {
            console.log(`Column ${columnName} has type ${column.type}`);
            return column.type;
        } else {
            console.warn(`Column ${columnName} not found, defaulting to string`);
            return 'string';
        }
    },

    onFunctionChange(tableColumnSet) {
      tableColumnSet.showFormatField = tableColumnSet.function === this.functionNames.toChar;
      if (!tableColumnSet.showFormatField) {
        tableColumnSet.dateFormat = null;
      } else if (!tableColumnSet.dateFormat) {
        tableColumnSet.dateFormat = this.dateFormats[0]; // Устанавливаем первый формат по умолчанию
      }

      // Обновляем alias для агрегатных функций
      if (tableColumnSet.function === this.functionNames.count) {
        tableColumnSet.alias = `${tableColumnSet.column.split('.').pop()}`;
      } else if (tableColumnSet.function === this.functionNames.sum) {
        tableColumnSet.alias = `${tableColumnSet.column.split('.').pop()}`;
      }
      
    },

    onConditionColumnChange(caseCondition, prefix) {
      const selectedColumn = this.getAllColumns().find(col => col.name === caseCondition[prefix + 'Column']);
      if (selectedColumn) {
        caseCondition[prefix + 'ColumnType'] = selectedColumn.type;
        if (selectedColumn.type === 'string') {
          caseCondition[prefix + 'Function'] = this.functionNames.trim;
        } else {
          caseCondition[prefix + 'Function'] = this.functionNames.noFunction;
        }
        // Устанавливаем значение по умолчанию для поля "Выводить как"
        caseCondition[prefix + 'Alias'] = selectedColumn.name.split('.').pop();
        
        // Если тип колонки - дата, инициализируем соответствующие поля
        if (selectedColumn.type === 'date') {
          caseCondition[prefix + 'DateValue'] = new Date().toISOString().substr(0, 10);
          caseCondition[prefix + 'DateMenu'] = false;
        } else {
          caseCondition[prefix + 'Value'] = '';
          caseCondition[prefix + 'DateValue'] = null;
          caseCondition[prefix + 'DateMenu'] = false;
        }
      }
    },

    showConditionFunction(condition, prefix) {
      return condition[prefix + 'ColumnType'] === 'string';
    },

    getConditionFunctions(condition, prefix) {
      if (condition[prefix + 'ColumnType'] === 'string') {
        return [this.functionNames.noFunction, this.functionNames.trim];
      }
      return [this.functionNames.noFunction];
    },

    onThenColumnChange(caseCondition) {
      const selectedColumn = this.getAllColumns().find(col => col.name === caseCondition.thenColumn);
      if (selectedColumn) {
        caseCondition.thenColumnType = selectedColumn.type;
        if (selectedColumn.type === 'string') {
          caseCondition.thenFunction = this.functionNames.trim;
        } else {
          caseCondition.thenFunction = this.functionNames.noFunction;
        }
        // Устанавливаем значение по умолчанию для поля "Выводить как"
        caseCondition.thenAlias = selectedColumn.name.split('.').pop();
        this.onThenFunctionChange(caseCondition);
      }
    },

    onThenFunctionChange(condition) {
      condition.showThenFormatField = condition.thenFunction === this.functionNames.toChar;
      if (!condition.showThenFormatField) {
        condition.thenDateFormat = null;
      } else if (!condition.thenDateFormat) {
        condition.thenDateFormat = this.dateFormats[0];
      }
      
      // Обновляем alias, если функция изменилась
      const columnName = condition.thenColumn ? condition.thenColumn.split('.').pop() : '';
      if (condition.thenFunction === this.functionNames.trim) {
        condition.thenAlias = `${columnName}`;
      } else if (condition.thenFunction === this.functionNames.toChar) {
        condition.thenAlias = `${columnName}`;
      } else {
        condition.thenAlias = columnName;
      }
    },

    showThenFunction(condition) {
      return condition.thenColumnType === 'string';
    },

    getThenFunctions(condition) {
      if (condition.thenColumnType === 'string') {
        return [this.functionNames.trim];
      }
      return [];
    },

    removeTableColumnSet(table, index) {
      table.tableColumnSets.splice(index, 1);
    },
    
    async onTableChange(table) {
        table.name = table.selectedTableName;
        //console.log(table.name)
        if(table.name !== null){
          const settingTableName = {
              strTableName: table.name,
          };

          try {
              await this.$store.dispatch('loadpgColumnsTables', settingTableName);
              const pgColumnsTables = this.$store.getters.pgColumnsTables;

              table.columns = pgColumnsTables.map(column => ({
                  name: `${table.name}.${column.column_name}`,
                  fullName: `${table.name}.${column.column_name}`,
                  type: this.mapColumnType(column.data_type)
              }));

              //console.log(table);
          } catch (error) {
              console.error('Error loading table columns:', error);
              // Здесь вы можете добавить обработку ошибок, например, показать уведомление пользователю
          }
        }
    },

    mapColumnType(pgType) {
        switch (pgType.toLowerCase()) {
            case 'integer':
            case 'bigint':
            case 'smallint':
            case 'decimal':
            case 'numeric':
            case 'real':
            case 'double precision':
                return 'number';
            case 'character varying':
            case 'varchar':
            case 'character':
            case 'char':
            case 'text':
                return 'string';
            case 'boolean':
                return 'boolean';
            case 'date':
            case 'timestamp':
              return 'date';
            case 'time':
            case 'time with time zone':
            case 'time without time zone':
                return 'time';
            default:
                return 'string'; // По умолчанию возвращаем строковый тип
        }
    },

    addFieldSet() {
      this.fieldSets.push({
        type: 'field',
        column: null,
        function: this.functionNames.noFunction,
        alias: '',
        dateFormat: null,
        showFormatField: false,
        columnType: null,
        caseConditions: [],
        elseType: 'value',
        elseValue: '',
        elseColumn: null,
        elseColumnType: null,
        elseFunction: this.functionNames.noFunction,
        elseDateFormat: null,
        showElseFormatField: false,
        elseAlias: '',
        caseAlias: '',
        caseAggregateFunction: 'Без функции',
        subquery: {
          activePanels: [],
          selectedTableIndex: null,
          tables: [],
          fieldSets: [],
          whereGroups: [{
            sets: [],
            operator: "И"
          }],
          alias: ''
        }
      });
    },
    
    removeFieldSet(index) {
      this.fieldSets.splice(index, 1);
    },

    addWhenThen(fieldSet) {
      fieldSet.caseConditions.push({ when: '', then: '' });
    },

    removeWhenThen(fieldSet, index) {
      fieldSet.caseConditions.splice(index, 1);
    },
    
    addJoinCondition() {
      this.joinConditions.push({
        column1: null,
        column1Type: null,
        option: null,
        column2: null,
        column2Type: null,
        joinType: 'LEFT JOIN'
      });
    },

    removeJoinCondition(index) {
      this.joinConditions.splice(index, 1);
    },

    getAllColumns() {
      let allColumns = [];
      this.tables.forEach(table => {
        if (table.selectedTableName && table.columns) {
          allColumns = allColumns.concat(table.columns);
        }
      });
      return allColumns;
    },

    addOrderByField() {
      if (this.orderByField) {
        if (this.orderByDirection === 'ASC') {
          this.orderByFieldsAsc.push(this.orderByField);
        } else {
          this.orderByFieldsDesc.push(this.orderByField);
        }
        this.orderByField = null;
      }
    },

    removeOrderByField(direction, index) {
      if (direction === 'ASC') {
        this.orderByFieldsAsc.splice(index, 1);
      } else {
        this.orderByFieldsDesc.splice(index, 1);
      }
    },

    generateOrderByClause() {
      const ascFields = this.orderByFieldsAsc.map(field => `${this.quoteColumnName(field)} ASC`);
      const descFields = this.orderByFieldsDesc.map(field => `${this.quoteColumnName(field)} DESC`);
      const allFields = [...ascFields, ...descFields];

      if (allFields.length > 0) {
        return '\nORDER BY ' + allFields.join(', ');
      }
      return '';
    },

    // Метод для очистки условий JOIN при удалении таблицы
    clearJoinConditionsIfNeeded() {
      if (this.tables.length <= 1) {
        this.joinConditions = [];
      }
    },
    testQuery() {
      // Тестовые данные
      /* this.resultHeaders = [
        { text: 'ID', value: 'id' },
        { text: 'Имя', value: 'name' },
        { text: 'Возраст', value: 'age' },
      ];
      this.resultItems = [
        { id: 1, name: 'Иван', age: 30 },
        { id: 2, name: 'Мария', age: 25 },
        { id: 3, name: 'Петр', age: 35 },
      ]; */

      //console.log(this.sqlQuery.trim())
      //console.log(Buffer.from(this.sqlQuery.trim(), 'utf-8').toString('base64'))

      //console.log(this.sqlQuery.trim())

      const currentQuery = this.editor ? this.editor.state.doc.toString() : this.sqlQuery

      if(currentQuery.trim() !== '' && currentQuery.trim() !== 'SELECT' ) {
        let Data = []

        Data.push(Buffer.from(this.sqlQuery.trim(), 'utf-8').toString('base64'))

        axios({
            method: 'POST',
            headers: {
                'api_key': store.state.CurrentApiKey,
                'strGlobalProjectName': store.state.GlobalProjectName.trim(),
                'Content-Type': 'application/json'
            },
            url: store.state.urlCJ + 'checkpgSqlBuilderSelect',
            data: Data

        })
        .then((response) => {
          //console.log(response.data)
          this.vAlertOk = true;
          this.vAlertOkText = 'Запрос выполнен без ошибок';
          this.vAlertWarning = false;
          this.vAlertWarningText = '';
          
          this.resultItems = response.data;
        })
        .catch((resError) => {
            //console.log(resError.response.data)
            this.vAlertOk = false;
            this.vAlertOkText = '';
            this.vAlertWarning = true;
            this.vAlertWarningText = resError.response.data.trim();

            this.resultItems = [];

            let err = Object.assign({}, resError)
            console.log('error', err.response);
                    
            if(err.response.status == '488')
            {
              this.$router.push('/')
            }
        })
      }
      else
      {
        this.vAlertOk = false;
        this.vAlertOkText = '';
        this.vAlertWarning = true;
        this.vAlertWarningText = 'SQL запрос не может быть пустым!';

        this.resultItems = [];
      }
    },

    serializeConstructor() {
      const state = {
        tables: this.tables,
        fieldSets: this.fieldSets,
        joinConditions: this.joinConditions,
        groups: this.groups,
        orderByFieldsAsc: this.orderByFieldsAsc,
        orderByFieldsDesc: this.orderByFieldsDesc,
      };
      return JSON.stringify(state);
    },

    deserializeConstructor(json) {
      try {
        const state = JSON.parse(json);
        this.tables = state.tables;
        this.fieldSets = state.fieldSets;
        this.joinConditions = state.joinConditions;
        this.groups = state.groups;
        this.orderByFieldsAsc = state.orderByFieldsAsc;
        this.orderByFieldsDesc = state.orderByFieldsDesc;

        // Обновляем выбранные таблицы
        this.tables.forEach(table => this.onTableChange(table));

        // Генерируем SQL-запрос на основе загруженного состояния
        this.generateQuery();
      } catch (error) {
        console.error('Ошибка при загрузке конструктора:', error);
        // Здесь можно добавить уведомление для пользователя об ошибке
      }
    },

    saveConstructor() {
      const json = this.serializeConstructor();
      const blob = new Blob([json], {type: 'application/json'});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'sql_constructor_state.json';
      link.click();
    },

    loadConstructor() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'application/json';
      input.onchange = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = event => {
          const json = event.target.result;
          this.deserializeConstructor(json);
        };
        reader.readAsText(file);
      };
      input.click();
    },

    openReplaceTableDialog() {
      this.showReplaceTableDialog = true;
      this.tableToReplace = null;
      this.newTableName = null;
    },

    replaceTable() {
      if (!this.tableToReplace || !this.newTableName) {
        // Показать сообщение об ошибке
        this.vAlertWarning = true;
        this.vAlertWarningText = 'Пожалуйста, выберите обе таблицы';
        return;
      }

      // Найдем индекс заменяемой таблицы
      const tableIndex = this.tables.findIndex(t => t.selectedTableName === this.tableToReplace);
      if (tableIndex === -1) {
        this.vAlertWarning = true;
        this.vAlertWarningText = 'Выбранная таблица не найдена';
        return;
      }

      // Сохраним старое имя таблицы для последующей замены
      const oldTableName = this.tableToReplace;

      // Обновим таблицу
      this.tables[tableIndex].selectedTableName = this.newTableName;
      this.tables[tableIndex].name = this.newTableName;

      // Обновим колонки для новой таблицы
      this.onTableChange(this.tables[tableIndex]);

      // Обновим все ссылки на старую таблицу в конструкторе
      this.updateReferences(oldTableName, this.newTableName);

      // Закроем диалог
      this.showReplaceTableDialog = false;

      // Покажем сообщение об успешной замене
      this.vAlertOk = true;
      this.vAlertOkText = `Таблица ${oldTableName} успешно заменена на ${this.newTableName}`;

      // Перегенерируем SQL-запрос
      this.generateQuery();
    },

    updateReferences(oldTableName, newTableName) {
      // Обновляем fieldSets
      this.fieldSets.forEach(fieldSet => {
        if (fieldSet.column && fieldSet.column.startsWith(oldTableName + '.')) {
          fieldSet.column = fieldSet.column.replace(oldTableName + '.', newTableName + '.');
        }
      });

      // Обновляем joinConditions
      this.joinConditions.forEach(condition => {
        if (condition.column1 && condition.column1.startsWith(oldTableName + '.')) {
          condition.column1 = condition.column1.replace(oldTableName + '.', newTableName + '.');
        }
        if (condition.column2 && condition.column2.startsWith(oldTableName + '.')) {
          condition.column2 = condition.column2.replace(oldTableName + '.', newTableName + '.');
        }
      });

      // Обновляем groups
      this.groups.forEach(group => {
        group.sets.forEach(set => {
          if (set.column && set.column.startsWith(oldTableName + '.')) {
            set.column = set.column.replace(oldTableName + '.', newTableName + '.');
          }
        });
      });

      // Обновляем orderByFieldsAsc и orderByFieldsDesc
      this.orderByFieldsAsc = this.orderByFieldsAsc.map(field => 
        field.startsWith(oldTableName + '.') ? field.replace(oldTableName + '.', newTableName + '.') : field
      );
      this.orderByFieldsDesc = this.orderByFieldsDesc.map(field => 
        field.startsWith(oldTableName + '.') ? field.replace(oldTableName + '.', newTableName + '.') : field
      );
    },
        CopyJobtoNew (){
            this.showSaveCopyButtons = false;
            this.showStartButton = true;
            this.uuid = uuidv4();
            this.jobTypeSelect = 'Регулярная задача';
        },
        CancelOper () {
            this.$router.push('/mainScreen/cronJobList')
            this.$emit('close');
        },
          submit () {
            this.canRunJobCheck = true;
            let ErrorMessage = '';

            if(this.ex00 == 0 && 
                this.ex02 == 0 && 
                this.ex03 == 0 && 
                this.ex04 == 0 && 
                this.ex05 == 0 && 
                this.ex06 == 0 && 
                this.ex07 == 0 && 
                this.ex08 == 0 && 
                this.ex09 == 0 && 
                this.ex10 == 0 && 
                this.ex11 == 0 && 
                this.ex12 == 0 && 
                this.ex13 == 0 && 
                this.ex14 == 0 && 
                this.ex15 == 0 && 
                this.ex16 == 0 && 
                this.ex17 == 0 && 
                this.ex18 == 0 && 
                this.ex19 == 0 && 
                this.ex20 == 0 && 
                this.ex21 == 0 && 
                this.ex22 == 0 && 
                this.ex23 == 0
            )
            { 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не указаны часы для выполнения задачи; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.exm00 == 0 && 
                this.exm05 == 0 && 
                this.exm10 == 0 && 
                this.exm15 == 0 && 
                this.exm25 == 0 && 
                this.exm30 == 0 && 
                this.exm35 == 0 && 
                this.exm40 == 0 && 
                this.exm45 == 0 && 
                this.exm50 == 0 && 
                this.exm55 == 0
            )
            { 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не указаны минуты для выполнения задачи; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.sqlQuery.trim() == '' || this.sqlQuery.trim() == 'SELECT' ) {
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'SQL запрос не может быть пустым!; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.selectedServiceEmail.trim() == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не выбран сервисный Google пользователь; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.strJobUserComment == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не введен Комментарий для задачи; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.sheetId == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не введен Уникальный идентификатор листа Google; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.spreadsheetId == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не введен Уникальный идентификатор книги Google; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.rangeAdd == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не введено Наименование листа Google для отгрузки данных; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }

            if(this.dateStart == ''){ 
                this.alert1 = false;
                ErrorMessage = ErrorMessage + 'Не введена Дата начала отгрузки данных  из Геткурса; '
                this.alertMessage = ErrorMessage;
                this.alert2 = true;

                this.canRunJobCheck = false;
            }
            if(this.jobTypeSelect == 'Регулярная задача'){
                if(this.waitTime === ''){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Не введена Задержка в минутах перед запуском загрузки из Геткурса; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }

                if(this.waitTime <= 0){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Задержка в минутах перед запуском загрузки из Геткурса, не может быть меньше или равна 0; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }
            }
            else
            {
                if(this.waitTime === ''){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Не введена Задержка в минутах перед запуском загрузки из Геткурса; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }

                if(this.waitTime <= 0){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Задержка в минутах перед запуском загрузки из Геткурса, не может быть меньше или равна 0; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }

                if(this.jobRepeatEvery == ''){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Не введен параметр Запускать через N минут; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }

                if(this.jobRepeatEvery <= 0){ 
                    this.alert1 = false;
                    ErrorMessage = ErrorMessage + 'Параметр Запускать через N минут, не может быть меньше или равен 0; '
                    this.alertMessage = ErrorMessage;
                    this.alert2 = true;

                    this.canRunJobCheck = false;
                }
            }
            if(this.canRunJobCheck == true){
                if(this.jobTypeSelect != '')
                {
                    //console.log(data)

                    let Hours = '';
                    let Minutes = '';

                    if(this.ex00 == 1){ Hours = Hours + '0,' }
                    if(this.ex01 == 1){ Hours = Hours + '1,' }
                    if(this.ex02 == 1){ Hours = Hours + '2,' }
                    if(this.ex03 == 1){ Hours = Hours + '3,' }
                    if(this.ex04 == 1){ Hours = Hours + '4,' }
                    if(this.ex05 == 1){ Hours = Hours + '5,' }
                    if(this.ex06 == 1){ Hours = Hours + '6,' }
                    if(this.ex07 == 1){ Hours = Hours + '7,' }
                    if(this.ex08 == 1){ Hours = Hours + '8,' }
                    if(this.ex09 == 1){ Hours = Hours + '9,' }
                    if(this.ex10 == 1){ Hours = Hours + '10,' }
                    if(this.ex11 == 1){ Hours = Hours + '11,' }
                    if(this.ex12 == 1){ Hours = Hours + '12,' }
                    if(this.ex13 == 1){ Hours = Hours + '13,' }
                    if(this.ex14 == 1){ Hours = Hours + '14,' }
                    if(this.ex15 == 1){ Hours = Hours + '15,' }
                    if(this.ex16 == 1){ Hours = Hours + '16,' }
                    if(this.ex17 == 1){ Hours = Hours + '17,' }
                    if(this.ex18 == 1){ Hours = Hours + '18,' }
                    if(this.ex19 == 1){ Hours = Hours + '19,' }
                    if(this.ex20 == 1){ Hours = Hours + '20,' }
                    if(this.ex21 == 1){ Hours = Hours + '21,' }
                    if(this.ex22 == 1){ Hours = Hours + '22,' }
                    if(this.ex23 == 1){ Hours = Hours + '23,' }

                    Hours = Hours.substring(0,Hours.length-1);
                    //console.log(Hours)

                    if(this.exm00 == 1){ Minutes = '0' }
                    if(this.exm05 == 1){ Minutes = '5' }
                    if(this.exm10 == 1){ Minutes = '10' }
                    if(this.exm15 == 1){ Minutes = '15' }
                    if(this.exm20 == 1){ Minutes = '20' }
                    if(this.exm25 == 1){ Minutes = '25' }
                    if(this.exm30 == 1){ Minutes = '30' }
                    if(this.exm35 == 1){ Minutes = '35' }
                    if(this.exm40 == 1){ Minutes = '40' }
                    if(this.exm45 == 1){ Minutes = '45' }
                    if(this.exm50 == 1){ Minutes = '50' }
                    if(this.exm55 == 1){ Minutes = '55' }

                    //console.log(Minutes + ' ' + Hours + ' * * *')
                    //schedule, in 20 minutes
                    //repeatEvery, 3 minutes
                    let CronType = '';
                    let CronTime = '';
                    let CronWaitTime = 'in ' + this.waitTime + ' minutes';

                    if(this.schoolURL.trim().substring(this.schoolURL.length-1) == '/')
                    {
                        this.schoolURL = this.schoolURL.trim().substring(0,this.schoolURL.length-1);
                    }

                    if(this.jobTypeSelect == 'Регулярная задача'){
                        //this.ClearScheet = false;
                        CronType = 'repeatEvery';
                        CronTime = Minutes + ' ' + Hours + ' * * *';
                    }
                    else {
                        CronType = 'schedule';
                        CronTime = 'in ' + this.jobRepeatEvery + ' minutes';
                    }
                    //console.log(CronTime)
                    //console.log(CronType)

                    //Сохраняем состояние Конструктора
                    let BuilderState = {
                      tables: this.tables,
                      fieldSets: this.fieldSets,
                      joinConditions: this.joinConditions,
                      groups: this.groups,
                      orderByFieldsAsc: this.orderByFieldsAsc,
                      orderByFieldsDesc: this.orderByFieldsDesc,
                    };

                    let DataTo = [];
                    DataTo.push(this.sqlQuery.trim()) //Пушим сам запрос
                    DataTo.push(JSON.stringify(BuilderState)) //Пушим состояние

                    //console.log(DataTo)

                    let setJobURLonButton = '';
                    if(this.showSaveCopyButtons == true){ setJobURLonButton = 'updateJobinDB' }
                    if(this.showStartButton == true){ setJobURLonButton = 'addJob' }

                    axios({
                        method: 'POST',
                        headers: {
                            'api_key': store.state.CurrentApiKey,
                            'jobUUID': this.uuid,
                            'jobRepeatEvery': CronTime,
                            'jobType': CronType,
                            'jobName': 'PG Builder to Google Scheet Working',
                            'sheetId': this.sheetId,
                            'spreadsheetId': this.spreadsheetId,
                            'rangeAdd': this.rangeAdd,
                            'dateStart': this.dateStart,
                            'waitTime': CronWaitTime,
                            'schoolURL': this.schoolURL,
                            'schoolApiKey': this.schoolApiKey,
                            'strJobNameAbout': Buffer.from('PostgreSql Builder в Гугл таблицы', 'utf-8').toString('base64'),
                            'schoolColumns': Buffer.from('', 'utf-8').toString('base64'),
                            'schoolColumnsOut': Buffer.from('', 'utf-8').toString('base64'),
                            'clearScheet': this.ClearScheet,
                            'useFilterRules': this.selectParams,
                            'useBuilderRules': '1',
                            'filterRules': Buffer.from(JSON.stringify(this.rules), 'utf-8').toString('base64'),
                            'strJobUserComment': Buffer.from(this.strJobUserComment, 'utf-8').toString('base64'),
                            'strGlobalProjectName': store.state.GlobalProjectName.trim(),
                            'strCronProjectUUID': store.state.CronProjectUUID.trim(),
                            'strServiceAccount': this.selectedServiceEmail.trim(),
                            'intLitePro': 1,
                            'Content-Type': 'application/json'
                        },
                        url: store.state.urlCJ + setJobURLonButton,
                        data: DataTo

                    })
                    .then((response) => {
                        //console.log(response.data)
                        if(response.data == 'OK')
                        {
                            this.alert1 = true;
                            this.alert2 = false;
                            //if(this.$store.getters.ProjectCOPY == 1 || this.$store.getters.ProjectEDIT == 1){
                            this.$router.push('/mainScreen/cronJobList')
                            this.$emit('close');
                            //}
                        }
                        else
                        {
                            if(response.data == 'Found System Job from Running list')
                            {
                                this.alert1 = false;
                                this.alert2 = true;
                                this.alertMessage = 'Ошибка запуска задачи';
                            }
                            else
                            {
                                this.alert1 = false;
                                this.alert2 = true;
                                this.alertMessage = 'Ошибка запуска задачи';
                            }
                        }
                    })
                    .catch((response) => {
                      //console.log(response)
                      let err = Object.assign({}, response)
                      console.log('error', err.response);
                              
                      if(err.response.status == '488')
                      {
                        this.$router.push('/')
                      }
                    });
                }
                else
                {
                    this.alert1 = false;
                    this.alertMessage = 'Не выбран тип задачи';
                    this.alert2 = true;
                }
            }
        }, 


  },
  watch: {
      sqlQuery(newValue) {
        //console.log(this.editor.state.doc.toString())
          // Обновляем содержимое CodeMirror, если sqlQuery изменилось извне
          if (this.editor && this.editor.state.doc.toString() !== newValue) {
            //console.log(1)
            this.editor.dispatch({
              changes: {from: 0, to: this.editor.state.doc.length, insert: newValue}
            })
          }
        },
        // Наблюдатель за изменением количества таблиц
        tables: {
            handler() {
            if (this.tables.length <= 1) {
                this.joinConditions = [];
            }
            // Проверяем все fieldSets и удаляем те, которые ссылаются на несуществующие таблицы
            this.fieldSets = this.fieldSets.filter(fieldSet => {
                if (!fieldSet.column) return false; // Добавляем эту проверку
                const tableName = fieldSet.column.split('.')[0];
                return this.tables.some(table => table.selectedTableName === tableName);
            });
            },
            deep: true
        },
        useOnlySqlQuery(newValue) {
          if (newValue) {
            this.activeTab = 1;  // Переключаемся на вкладку SQL запроса
          }
        },
        jobTypeSelect(){
            //console.log(this.jobTypeSelect);
            if(this.jobTypeSelect == 'Разовая задача')
            {
                this.jobRepeatEveryDisabled = true;
                this.jobScheduledDisabled = false;
                this.schoolURLRD = true;
                this.showSaveCopyButtons = false;
                this.showStartButton = true;
                this.uuid = uuidv4();
            }
            else
            {
                this.jobRepeatEveryDisabled = false;
                this.jobScheduledDisabled = true;
            }
        },
        exm00(){
            if(this.exm00 == 1){
                this.exm05=0;
                this.exm10=0;
                this.exm15=0;
                this.exm20=0;
                this.exm25=0;
                this.exm30=0;
                this.exm35=0;
                this.exm40=0;
                this.exm45=0;
                this.exm50=0;
                this.exm55=0;
            }
        },
        exm05(){
            if(this.exm05 == 1){
            this.exm00=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm10(){
            if(this.exm10 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm15(){
            if(this.exm15 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm20(){
            if(this.exm20 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm25(){
            if(this.exm25 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm30(){
            if(this.exm30 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm35(){
            if(this.exm35 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm40(){
            if(this.exm40 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm45=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm45(){
            if(this.exm45 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm50=0;
            this.exm55=0;
            }
        },
        exm50(){
            if(this.exm50 == 1){
            this.exm00=0;
            this.exm05=0;
            this.exm10=0;
            this.exm15=0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm55=0;
            }
        },
        exm55(){
            if(this.exm55 == 1){
            this.exm00 = 0;
            this.exm05 = 0;
            this.exm10 = 0;
            this.exm15 = 0;
            this.exm20=0;
            this.exm25=0;
            this.exm30=0;
            this.exm35=0;
            this.exm40=0;
            this.exm45=0;
            this.exm50=0;
            }
        },
    },

    async mounted() {

      // Инициализация CodeMirror
      setTimeout(() => {
        this.initCodeMirror()
      }, 100)

      this.$store.dispatch('loadpgGoogleServiceAccounts')
      //console.log(this.$store.getters.pgGoogleServiceAccounts)
      const accounts = this.$store.getters.pgGoogleServiceAccounts;
      this.ServiceEmailList = Object.values(accounts).map(account => account.gAccount_name);

      this.$store.dispatch('loadpgTables')
      this.availableTables = this.$store.getters.pgTables.map(table => table.table_name);
      //console.log(this.availableTables)  
      this.uuid = uuidv4();

      if(this.$store.getters.ProjectCOPY == 1 || this.$store.getters.ProjectEDIT == 1 || this.$store.getters.ProjectCOPY == 2){
        if(this.$store.getters.ProjectNAME == 'PostgreSql Builder в Гугл таблицы')
        {
          const settingJobUUID = {
              strProjectUUID: this.$store.getters.ProjectUUID,
          }
          await this.$store.dispatch('loadjobProjectsById', settingJobUUID)
          .then(async() => {
              this.ex00= 0;
              this.ex01= 0;
              this.ex02= 0;
              this.ex03= 0;
              this.ex04= 0;
              this.ex05= 0;
              this.ex06= 0;
              this.ex07= 0;
              this.ex08= 0;
              this.ex09= 0;
              this.ex10= 0;
              this.ex11= 0;
              this.ex12= 0;
              this.ex13= 0;
              this.ex14= 0;
              this.ex15= 0;
              this.ex16= 0;
              this.ex17= 0;
              this.ex18= 0;
              this.ex19= 0;
              this.ex20= 0;
              this.ex21= 0;
              this.ex22= 0;
              this.ex23= 0;
              this.exm00= 0;
              this.exm05= 0;
              this.exm10= 0;
              this.exm15= 0;
              this.exm20= 0;
              this.exm25= 0;
              this.exm30= 0;
              this.exm35= 0;
              this.exm40= 0;
              this.exm45= 0;
              this.exm50= 0;
              this.exm55= 0;

              //console.log('Chto to1')
              //console.log(this.$store.getters.jobProjectsById[0])
              this.uuid = this.$store.getters.jobProjectsById[0].UUID
              this.sheetId = this.$store.getters.jobProjectsById[0].sheetId
              this.spreadsheetId = this.$store.getters.jobProjectsById[0].spreadsheetId
              this.rangeAdd = this.$store.getters.jobProjectsById[0].rangeAdd

              this.schoolURL = this.$store.getters.jobProjectsById[0].schoolURL
              
              let GetDateStart = new Date(this.$store.getters.jobProjectsById[0].dateStart)
              this.dateStart = date.format(GetDateStart, 'YYYY-MM-DD');

              this.schoolURL = this.$store.getters.jobProjectsById[0].schoolURL

              //this.strJobUserComment = this.$store.getters.jobProjectsById[0].strJobUserComment
              this.strJobUserComment = this.$store.getters.jobProjectsById[0].strJobUserComment == null ? '' : this.$store.getters.jobProjectsById[0].strJobUserComment.toString().trim()
              
              if(this.$store.getters.jobProjectsById[0].strJobRepeat == 'repeatEvery'){
                  this.jobTypeSelect = 'Регулярная задача';
              }
              else
              {
                  this.jobTypeSelect = 'Разовая задача';
              }


              let CronTime = this.$store.getters.jobProjectsById[0].strJobTime;
              let CronTimeArr = CronTime.split(' ');

              //console.log(CronTimeArr);

              let CronTimeMinute = CronTimeArr[0];

              if(CronTimeMinute == 0){ this.exm00 = 1}
              if(CronTimeMinute == 5){ this.exm05 = 1}
              if(CronTimeMinute == 10){ this.exm10 = 1}
              if(CronTimeMinute == 15){ this.exm15 = 1}
              if(CronTimeMinute == 20){ this.exm20 = 1}
              if(CronTimeMinute == 25){ this.exm25 = 1}
              if(CronTimeMinute == 30){ this.exm30 = 1}
              if(CronTimeMinute == 35){ this.exm35 = 1}
              if(CronTimeMinute == 40){ this.exm40 = 1}
              if(CronTimeMinute == 45){ this.exm45 = 1}
              if(CronTimeMinute == 50){ this.exm50 = 1}
              if(CronTimeMinute == 55){ this.exm55 = 1}

              let CronTimeHoursArr = CronTimeArr[1].split(',');

              for(let iCron = 0; iCron<CronTimeHoursArr.length; iCron++){
                  if(CronTimeHoursArr[iCron] == 0){ this.ex00 = 1}
                  if(CronTimeHoursArr[iCron] == 1){ this.ex01 = 1}
                  if(CronTimeHoursArr[iCron] == 2){ this.ex02 = 1}
                  if(CronTimeHoursArr[iCron] == 3){ this.ex03 = 1}
                  if(CronTimeHoursArr[iCron] == 4){ this.ex04 = 1}
                  if(CronTimeHoursArr[iCron] == 5){ this.ex05 = 1}
                  if(CronTimeHoursArr[iCron] == 6){ this.ex06 = 1}
                  if(CronTimeHoursArr[iCron] == 7){ this.ex07 = 1}
                  if(CronTimeHoursArr[iCron] == 8){ this.ex08 = 1}
                  if(CronTimeHoursArr[iCron] == 9){ this.ex09 = 1}
                  if(CronTimeHoursArr[iCron] == 10){ this.ex10 = 1}
                  if(CronTimeHoursArr[iCron] == 11){ this.ex11 = 1}
                  if(CronTimeHoursArr[iCron] == 12){ this.ex12 = 1}
                  if(CronTimeHoursArr[iCron] == 13){ this.ex13 = 1}
                  if(CronTimeHoursArr[iCron] == 14){ this.ex14 = 1}
                  if(CronTimeHoursArr[iCron] == 15){ this.ex15 = 1}
                  if(CronTimeHoursArr[iCron] == 16){ this.ex16 = 1}
                  if(CronTimeHoursArr[iCron] == 17){ this.ex17 = 1}
                  if(CronTimeHoursArr[iCron] == 18){ this.ex18 = 1}
                  if(CronTimeHoursArr[iCron] == 19){ this.ex19 = 1}
                  if(CronTimeHoursArr[iCron] == 20){ this.ex20 = 1}
                  if(CronTimeHoursArr[iCron] == 21){ this.ex21 = 1}
                  if(CronTimeHoursArr[iCron] == 22){ this.ex22 = 1}
                  if(CronTimeHoursArr[iCron] == 23){ this.ex23 = 1}
              }

              
              let GetJsonFromReq = JSON.parse(Buffer.from(this.$store.getters.jobProjectsById[0].schoolColumns, 'base64').toString('utf-8'))

              //console.log(this.$store.getters.jobProjectsById[0].strServiceAccount)
              if(this.$store.getters.jobProjectsById[0].strServiceAccount == undefined || this.$store.getters.jobProjectsById[0].strServiceAccount == ''){
                  this.selectedServiceEmail = 'sheets@striking-berm-310303.iam.gserviceaccount.com'
              }
              else
              {
                  this.selectedServiceEmail = this.$store.getters.jobProjectsById[0].strServiceAccount
              }

              //console.log(GetJsonFromReq[1])
              
              this.sqlQuery = GetJsonFromReq[0]
              //this.initCodeMirror();

              try {
                let state = JSON.parse(GetJsonFromReq[1]);
                this.tables = state.tables;
                this.fieldSets = state.fieldSets;
                this.joinConditions = state.joinConditions;
                this.groups = state.groups;
                this.orderByFieldsAsc = state.orderByFieldsAsc;
                this.orderByFieldsDesc = state.orderByFieldsDesc;

                // Обновляем выбранные таблицы
                this.tables.forEach(table => this.onTableChange(table));

                // Генерируем SQL-запрос на основе загруженного состояния
                //this.generateQuery();
                this.activeTab = 2;
              } catch (error) {
                console.error('Ошибка при загрузке конструктора:', error);
                // Здесь можно добавить уведомление для пользователя об ошибке
              }

              if(this.$store.getters.ProjectCOPY == 1){
                  //console.log(1)
                  this.showSaveCopyButtons = false;
                  this.showStartButton = true;
                  this.uuid = uuidv4();
              }

              if(this.$store.getters.ProjectCOPY == 2){
                  //console.log(1)
                  this.jobTypeSelect = 'Разовая задача';
                  this.schoolURLRD = true;
                  this.showSaveCopyButtons = false;
                  this.showStartButton = true;
                  let GetDateStartOne = new Date()
                  this.dateStart = date.format(GetDateStartOne, 'YYYY-MM-DD');
                  this.uuid = uuidv4();
              }

              if(this.$store.getters.ProjectEDIT == 1){
                  //console.log(1)
                  this.showSaveCopyButtons = true;
                  this.showStartButton = false;
                  //this.uuid = uuidv4();
              }
          })
        }
      }
    },

    computed: {
      dynamicHeaders() {
        if (this.resultItems.length === 0) return []
        
        return Object.keys(this.resultItems[0]).map(key => ({
          text: key,
          value: key
        }))
      },

        nameErrors () {
            const errors = []
            if (!this.$v.jobRepeatEvery.$dirty) return errors
                !this.$v.jobRepeatEvery.required && errors.push('Обязательное поле. Укажите количество минут через которое произведем запуск разовой задачи.')
            return errors
        },
        sheetIdErrors () {
            const errors = []
            if (!this.$v.sheetId.$dirty) return errors
                !this.$v.sheetId.required && errors.push('Обязательное поле. Укажите Уникальный идентификатор листа Google.')
            return errors
        },
        spreadsheetIdErrors () {
            const errors = []
            if (!this.$v.spreadsheetId.$dirty) return errors
                !this.$v.spreadsheetId.required && errors.push('Обязательное поле. Укажите Уникальный идентификатор книги Google.')
            return errors
        },
        rangeAddErrors () {
            const errors = []
            if (!this.$v.rangeAdd.$dirty) return errors
                !this.$v.rangeAdd.required && errors.push('Обязательное поле. Наименование листа Google для отгрузки данных.')
            return errors
        },
        waitTimeErrors () {
            const errors = []
            if (!this.$v.waitTime.$dirty) return errors
                !this.$v.waitTime.required && errors.push('Обязательное поле. Укажите задержку перед завпросом информации из Геткурса (Геткурсу требуется время для обработки вашего запроса).')
            return errors
        },
        schoolURLErrors () {
            const errors = []
            if (!this.$v.schoolURL.$dirty) return errors
                !this.$v.schoolURL.required && errors.push('Обязательное поле. URL адрес Геткурса для загрузки данных. (https://yanavegana.ru)')
            return errors
        },
        strJobUserCommentErrors () {
            const errors = []
            if (!this.$v.strJobUserComment.$dirty) return errors
                !this.$v.strJobUserComment.required && errors.push('Обязательное поле. Комментарий пользовтеля')
            return errors
        },
    }
};
</script>

<style scoped>
.cm-editor {
  height: 100%;
  min-height: 300px;
  border: 1px solid #ddd;
  font-size: 14px;
  line-height: 1.5;
}

.cm-scroller {
  overflow: auto;
}
.field-set-controls {
  display: flex;
  align-items: center;
}

.field-set-controls .v-btn {
  margin-right: 4px;
}

.v-autocomplete__content {
  max-height: 400px;
  overflow-y: auto;
}

.v-list-item__title {
  white-space: normal;
  word-break: break-word;
}

.v-list-item__subtitle {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}
</style>