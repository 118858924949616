<template>
    <div id="app">
        <v-app id="inspire">
            <v-main>
                <v-container fluid fill-height>
                    <v-layout align-center justify-center>
                        <v-flex xs12 sm8 md4>
                            <v-card class="elevation-12">
                                <v-toolbar dark color="primary">
                                    <v-toolbar-title>Моя Аналитика (Планировщик)</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                                <v-card-text>
                                    <v-form>
                                        <v-text-field prepend-icon="mdi-account" name="login" label="E-mail пользователя" type="text" v-model="name"></v-text-field>
                                        <v-text-field prepend-icon="mdi-lock" name="password" label="Пароль" id="password" type="password" v-model="password"></v-text-field>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    
                                    <div ref="captchaContainer"></div>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="onSubmit" :disabled="isCaptchaComplete">{{ isCaptchaComplete ? 'Проверка...' : 'Войти' }}</v-btn>
                                </v-card-actions>
                            </v-card>
                            <div>
                                <v-alert
                                        v-model="alert"
                                        dismissible
                                        type="error"

                                >
                                    {{ errorMsg }}
                                </v-alert>
                                <v-alert
                                        v-model="info"
                                        dismissible
                                        type="info"

                                >
                                    {{ infoMsg }}
                                </v-alert>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-main>
        </v-app>
    </div>
</template>
<script>
    //import Vue from 'vue'
    //import axios from 'axios'
    import * as sha512 from 'js-sha512'
    import date from 'date-and-time';
    import Vue from 'vue'

    //import VueRecaptcha from 'vue-recaptcha'

    export default {
        name: "loginForm",
        //components: { VueRecaptcha },
        data() {
            return {
                name: null,
                password: null,
                errorMsg: "",
                infoMsg: "",
                alert: false,
                info: false,
                dt: "",
                disabled: 0,
                sitekey: 'ysc1_ijbqYu3FHbpDSt0jJGEWOKUPQs3OVndCBXOw5KI7f18d4d1e',
                widgetId: null,
                unsubscribe: null,
                clientToken: null,
                isSubmitting: false,
                isCaptchaComplete: false,
                isCaptchaVisible: false
            }
        },
        mounted() {
            const now = new Date();
            this.dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
            this.$store.commit('SetUserMail', undefined)
            this.$store.commit('SetAdmin', undefined)
            this.$store.commit('SetSchoolAdmin', undefined)
            this.$store.commit('SetUserProjID', undefined)
            this.$store.commit('SetUserID', undefined)
            this.$store.commit('SetUserName', undefined)
            this.$store.commit('SetLiteAccess', undefined)
            this.$store.commit('SetProAccess', undefined)
            this.$store.commit('SetUnlimitedAccess', undefined)
            this.$store.commit('SetDateLastPay', undefined)
            this.$store.commit('SetDateAccess', undefined)
            this.$store.commit('SetGlobalProjectName', undefined)
            this.$store.commit('SetCurrentApiKey', undefined)
            this.$store.commit('SeturlAN', process.env.URLAN)
            this.$store.commit('SeturlCJ', process.env.URLCJ)
            //console.log(process.env.URLAN)
            //console.log(process.env.URLCJ)
            this.loadSmartCaptcha();
        },
        beforeUnmount() {
            this.cleanupCaptcha();
        },
        methods: {
            validate () {
            // тут можно добавить проверку на валидацию
            // например, с помощью vee validate
            // если с валидацией наших полей все хорошо, запускаем каптчу
                this.$refs.recaptcha.execute()
            },
            loadSmartCaptcha() {
                //console.log('Загрузка SmartCaptcha...');
                const script = document.createElement('script');
                script.src = 'https://smartcaptcha.yandexcloud.net/captcha.js?render=onload&onload=onloadFunction';
                script.async = true;
                script.defer = true;
                document.body.appendChild(script);

                window.onloadFunction = () => {
                    //console.log('onloadFunction вызвана');
                    this.initSmartCaptcha();
                };
            },
            initSmartCaptcha() {
                //console.log('Инициализация SmartCaptcha...');
                if (window.smartCaptcha && !this.widgetId) {
                    this.widgetId = window.smartCaptcha.render(this.$refs.captchaContainer, {
                    sitekey: this.sitekey,
                    invisible: true,
                    callback: this.onCaptchaSuccess,
                    //testmode: true
                    });
                    //console.log('SmartCaptcha инициализирована. ID виджета:', this.widgetId);

                    this.unsubscribe = window.smartCaptcha.subscribe(
                    this.widgetId,
                    'challenge-visible',
                    () => {
                        console.log('Капча стала видимой');
                        this.isCaptchaVisible = true;
                        this.isCaptchaComplete = true;
                    }
                    );
                } else if (!window.smartCaptcha) {
                    console.error('window.smartCaptcha не определен');
                }
            },
            onSubmit() {
                //console.log('onSubmit вызван. ID виджета:', this.widgetId);
                if (this.widgetId !== null) {
                    if (!this.clientToken) {
                    //console.log('Попытка выполнить капчу...');
                    try {
                        window.smartCaptcha.execute(this.widgetId);
                    } catch (error) {
                        console.error('Ошибка при выполнении капчи:', error);
                    }
                    } else {
                    this.submitForm();
                    }
                } else {
                    console.error('ID виджета не определен');
                }
            },
            onCaptchaSuccess(token) {
                this.clientToken = token;
                this.isCaptchaComplete = false;
                //console.log('Капча успешно пройдена. Токен:', token);
                //if (!this.isCaptchaVisible) {
                    // Если капча была невидимой, сразу отправляем форму
                    this.submitForm();
                //}
                // Если капча была видимой, ждем, пока пользователь нажмет кнопку отправки
            },
            submitForm() {
                if (this.clientToken) {
                    //console.log('Отправка формы с токеном капчи:', this.clientToken);
                    this.isCaptchaComplete = true;
                    this.loginUserCheck(this.clientToken)
                } else {
                    console.error('Токен капчи отсутствует. Пожалуйста, пройдите капчу снова.');
                    this.errorMsg = 'Ошибка капчи, проверьте введенные данные!'
                    this.alert = true
                    this.info = false
                    this.disabled = 0
                    this.resetCaptcha()
                }
            },
            resetCaptcha() {
                if (this.widgetId !== null && window.smartCaptcha) {
                    window.smartCaptcha.reset(this.widgetId);
                    this.clientToken = null;
                    this.isCaptchaVisible = false;
                    this.isCaptchaComplete = false;
                    this.password = null;
                    //console.log('Капча сброшена');
                }
            },
            cleanupCaptcha() {
                if (this.unsubscribe) {
                    this.unsubscribe();
                    this.unsubscribe = null;
                }
                if (this.widgetId !== null && window.smartCaptcha) {
                    window.smartCaptcha.destroy(this.widgetId);
                    this.widgetId = null;
                }
            },
            async loginUserCheck(recaptchaToken) {
                if(this.name !== '' && this.password !== '' && this.name !== null && this.password !== null)
                {
                    this.isCaptchaComplete = true;
                    this.alert = false
                    
                    let shaPass = sha512.sha512(this.password);

                    const user = {
                        email: this.name,
                        password: shaPass,
                        recaptchaToken: recaptchaToken
                    }
                    this.info = true
                    this.infoMsg = 'Проверка пользователя...'
                    //console.log(user)
                    //site key//6LeZwZgbAAAAAHNUOQLIV365LEBp0Cx8Te5S5F98
                    //secret key//6LeZwZgbAAAAAMoiP4QLy2zk6W9-Us7Oj9hn0zg0
                    await this.$store.dispatch('loginUserYandexSmart', user)
                    .then(async () => {
                        this.$store.commit('SetUserMail', this.name)
                        this.infoMsg = 'Проверка пользователя...'
                        //console.log(this.$store.getters.UserMail)
                        //console.log(this.$store.getters.IsAdmin)
                        //console.log(this.$store.getters.IsSchoolAdmin)
                        //console.log(this.$store.getters.UserProjID)
                        //console.log(this.$store.getters.UserID)

                        //this.$store.commit('SetUserName', 'Yana-Vegana')
                       
                        //Set expire for item
                        Vue.ls.set(this.$store.getters.UserMail, 'РАБОТАЕТ', 15 * 60 * 1000); //expiry 15 minutes
                        //this.errorMsg = ok.message
                        //this.alert = true

                        this.cleanupCaptcha();
                        
                        this.$router.push('/mainScreen/cronJobList')
                        
                    }, error => {
                        console.log(error.code)
                        this.errorMsg = 'Ошибка, проверьте введенные данные!'
                        this.alert = true
                        this.info = false
                        this.disabled = 0
                        this.resetCaptcha()
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
                    
                }
                else
                {
                    this.errorMsg = 'Ошибка, проверьте введенные данные!'
                    this.alert = true
                    this.info = false
                    this.disabled = 0
                    this.resetCaptcha()
                }        
            }
            
        },
        computed: {
            loading () {
                return this.$store.getters.loading
            }
        }
    }
</script>
