import axios from 'axios'
//import router from '../router/router.js'
import store from './store.js'
import router from '../router/router.js'
//import * as sha512 from 'js-sha512'

export default {
    state: {
        dbStatistics: [],
        dbStatisticsFull: []

    },
    mutations: {
        loaddbStatistics (state, payload) {
            state.dbStatistics = payload
        },
        loaddbStatisticsFull (state, payload) {
            state.dbStatisticsFull = payload
        }
    },
    actions: {
        async loaddbStatistics ({commit}, { setApi, setProjectUUID, setProjectNAME }) {
            //console.log('strCronProjectUUID');
            commit('clearError')
            commit('setLoading2', true)

            await axios({
                method: 'POST',
                headers: {
                    'api_key': setApi,
                    'strCronProjectUUID': setProjectUUID,
                    'strGlobalProjectName': setProjectNAME,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getdbStatisticsFromDB',
                data: []

            })
            .then(async (response) => {
                
                //console.log(response.data)

                let dbStatList = response.data;
                
                commit('loaddbStatistics', dbStatList)
                commit('setLoading2', false)
            })
            .catch((response) => {
                //console.log(response)
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('loaddbStatistics', [])
                commit('setLoading2', false)
                commit('setError', response)
                if(err.response.status == '488')
                {
                    router.push('/')
                }
                
                
            });

        },
        async loaddbStatisticsFull ({commit}, { setApi, setProjectUUID, setProjectNAME }) {
            //console.log('strCronProjectUUID');
            commit('clearError')
            commit('setLoading2', true)

            await axios({
                method: 'POST',
                headers: {
                    'api_key': setApi,
                    'strCronProjectUUID': setProjectUUID,
                    'strGlobalProjectName': setProjectNAME,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlCJ + 'getdbStatisticsFromDBFull',
                data: []

            })
            .then(async (response) => {
                
                //console.log(response.data)

                let dbStatListFull = response.data;
                
                commit('loaddbStatisticsFull', dbStatListFull)
                commit('setLoading2', false)
            })
            .catch((response) => {
                //console.log(response)
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('loaddbStatisticsFull', [])
                commit('setLoading2', false)
                commit('setError', response)
                if(err.response.status == '488')
                {
                    router.push('/')
                }
                
                
            });

        },
        
    },
    
    getters: {
        dbStatistics (state) {
            return state.dbStatistics
        },
        dbStatisticsFull (state) {
            return state.dbStatisticsFull
        }
    }

    
}

