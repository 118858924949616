<template>
    <div id="app" >
        
        <v-app id="inspire" >
            <v-card class="elevation-0 pa-2" >
                
                <v-navigation-drawer
                    :mini-variant.sync="mini"
                    clipped
                    fixed
                    v-model="drawer"
                    app
                >
                
                    <v-list>
                        <v-list-item-group>
                            <!-- <v-list-item class="px-2">
                            <v-list-item-content class="d-flex justify-center">
                                <v-img src="../img/logo_2.svg" 
                                max-width="120"
                                max-height="80">
                                </v-img>
                            </v-list-item-content>
                            </v-list-item> -->
                            
                            <v-list-item-content v-if="!mini" class="d-flex justify-center pb-0">
                               <v-list  two-line  align="center" justify="center" class="pl-2 pr-2 pb-2 pt-2 ma-0">
                                {{ this.GlobalProjectName }}
                               </v-list>
                            </v-list-item-content>
                            <v-list-item-content class="d-flex justify-center">
                                <div class="d-flex justify-center">
                                    <v-btn v-if="!mini" small color="primary" @click="GoOut()">Выход</v-btn>
                                    <v-btn class="mx-2" fab dark small v-if="mini" color="primary" @click="GoOut()"><v-icon dark>mdi-exit-to-app</v-icon></v-btn>
                                </div>
                                
                            </v-list-item-content>
                        </v-list-item-group>
                        
                    </v-list>
                                
                    <v-divider></v-divider>
            
                    <v-list dense>
                    <v-list-item 
                        v-for="item in MenuItems"
                        :key="item.title"
                        @click="insideSettings=true"
                        :to="item.path"
                        
                    >
                        <v-list-item-icon >
                        <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
            
                        <v-list-item-content > 
                        <v-list-item-title >{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    
                    </v-list>
                    
                </v-navigation-drawer>
            <v-app-bar color="primary" dark app fixed clipped-left >
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                <v-toolbar-title>Планировщик заданий | {{ $store.getters.GlobalProjectName }} | {{ $store.getters.UnlimitedAccess == 1 ? 'Постоянный доступ': 'до ' + this.dateEndSupport }} | {{ GetLiteProStatus }}</v-toolbar-title>
                <p style="margin: 0 1.5em 0em 0em;"></p>
                <v-progress-circular
                                  :size="40"
                                  color="white"
                                  indeterminate
                                  v-if="loadingF"
                                ></v-progress-circular>
            </v-app-bar>
            
            <v-main class="elevation-0" >
                <auto-logout></auto-logout>
                <check-status></check-status>
                <v-alert dense  text   type="error"  dismissible v-model="alertOpti24" class="pa-1 ma-1">
                                Внимание! Ошибка подключения к сервису API Opti24. Требуется выполнить корректную настройку подключения!
                </v-alert>
                <router-view/>
            </v-main>
            
            </v-card>
        </v-app>
    </div>
</template>

<script>
import AutoLogout from '../components/AutoLogout.vue'
import date from 'date-and-time';
import Vue from 'vue'    
import CheckStatus from '../components/checkStatus.vue';
    export default {
        components: { AutoLogout, CheckStatus },
        name: "mainScreen",
        data() {
            return {
                insideSettings: false,
                drawer: true,
                mini: false,
                GlobalProjectName: '',
                GetLiteProStatus: '',
                dateEndSupport: '',
                items: [
                    { title: 'Запущенные интеграции', icon: 'mdi-clock-outline', path: "/mainScreen/cronJobList", if: true },
                    { title: 'Создать интеграцию', icon: 'mdi-vector-link', path: "/mainScreen/cronJobCreateNew", if: true },
                    { title: 'Входящие Get запросы', icon: 'mdi mdi-webhook', path: "/mainScreen/webHooksOperator", if: true },
                    { title: 'Telegram уведомления', icon: 'mdi-bell-ring-outline', path: "/mainScreen/notificationAddEdit", if: true },
                    { title: 'Статистика Базы данных', icon: 'mdi mdi-database-eye-outline', path: "/mainScreen/dbStatistics", if: true },
                    { title: 'Управление проектами', icon: 'mdi-account-cog-outline', path: "/mainScreen/otherProjectsAddEdit", if: () => this.$store.getters.IsAdmin == 1 },
                ]
            }
        },
        mounted() {
            this.GlobalProjectName = this.$store.getters.GlobalProjectName.split('_')[0];
            if(this.$store.getters.LiteAccess == 1 && this.$store.getters.ProAccess == 0){ this.GetLiteProStatus = 'Lite' }
            if(this.$store.getters.ProAccess == 1 && this.$store.getters.LiteAccess == 1){ this.GetLiteProStatus = 'Pro' }
            let GetDateEnd = new Date(this.$store.getters.DateAccess)
            this.dateEndSupport = date.format(GetDateEnd, 'DD.MM.YYYY');
        },
        methods: {
            loginUserCheck() {
                
                //console.log('CLICK')
                
            },
            GoOut() {
                this.$store.dispatch('logoutUser')
                Vue.ls.remove(this.$store.getters.UserMail)
                this.$router.push('/')
            }
        },
        computed: {
            MenuItems() {
                return this.items.filter(item => {
                    return typeof item.if === 'function' ? item.if() : item.if;
                });
            },
            alertOpti24() {
                return false
            },
            loadingF(){
                return this.$store.getters.loading2
            },
        }
    }
</script>

